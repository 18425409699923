import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { Button, Col, Radio, Row, Spin, Table, message } from "antd";
import SideBar from "../../SideBar";
import "./mainpage.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ClaimcancelRequest,
  deleteClaims,
  getCategories,
  submitClaimRequest,
  submitReceiptList,
} from "../../../services/ApiService";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Item from "antd/es/list/Item";
function MainPage() {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [groupValue, setGroupValue] = useState(null);
  const [column, setColumn] = useState();
  const [apiCall, setApiCall] = useState();
  const navigate = useNavigate();
  const [cashExp, setCashExp] = useState();
  const [corpExp, setCorpExp] = useState();
  const [totalExp, setTotalExp] = useState();
  const reduxclaimno = useSelector((state) => state.claimno);
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const[categoryData,setCategoryData]=useState()
  useEffect(() => {
    getCategories().then((res) => {
      if (res.responseCode === 200) {
        setCategoryData(res.data);

    console.log(res)
      
      } else {
        message.error(res.responseMessage);
      }
    });
    if (reduxclaimno) {
      submitReceiptList(reduxclaimno).then((res) => {
        if (res.responseCode === 200) {
          setCashExp(res.cashTotal);
          setCorpExp(res.corporateTotal);
          setTotalExp(res.totalAmount);
          if (res.data.length > 0) {
            let hasCreditCardStatement = res.data.some(
              (item) =>
                item.category ===
                "Credit Card-Import Corporate Credit Card Statement"
            );
            hasCreditCardStatement
              ? setButtonDisable(true)
              : setButtonDisable(false);
            setData(res.data);
            const columns = Object.keys(res.data[0])
              .filter((key) => key !== "id") // Exclude 'id' column
              .map((key) => ({
                title: key.charAt(0).toUpperCase() + key.slice(1),
                dataIndex: key,
                key,
              }));

            // Add additional columns for the "Edit" and "Delete" buttons
            columns.push(
              {
                title: "Edit",
                key: "edit",
                render: (text, record) => (
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => handleEditClick(record.id, record.category)}
                  >
                    Edit
                  </Button>
                ),
              }
              // {
              //   title: 'Delete',
              //   key: 'delete',
              //   render: (text, record) => (
              //     <Button type="danger" icon={<DeleteOutlined />} onClick={() => handleCheckboxChange(record)}>
              //       Delete
              //     </Button>
              //   ),
              // }
            );

            setColumn(columns);
          }
          else{
            setData([])
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    }

    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [apiCall]);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  // const handleRadioChange = (e, groupName) => {
  //   const { value } = e.target;
  //   setSelectedValue(value);
  //   setGroupValue(groupName);
  // };
  const [selectedValues, setSelectedValues] = useState({});
  const handleRadioChange = (e, category) => {
    console.log(category,e.target.value)
    const selectedOption = e.target.value;
    const originalString = selectedOption;
    const searchTerms = ['-Others', '-Entry Fees'];
    
    let resultString = originalString;
    
    // Iterate over search terms to find the first match
    searchTerms.forEach(term => {
      const index = resultString.indexOf(term);
      if (index !== -1) {
        // Extract everything after the search term, including the term itself
        resultString = resultString.substring(index + 1).trim(); // '+ 1' to remove the hyphen
      }
    });
    // If "Others" is selected, append the category name to make it unique
    let finalValue = selectedOption === "Others" ||selectedOption === "Entry Fees"  ? `${category}-${selectedOption}` : selectedOption;
    
    setSelectedValues(finalValue);
    setSelectedValue(resultString)
    setGroupValue(category)
  };
  // const [selectedIds, setSelectedIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  // const handleCheckboxChange = (row) => {
  //   const newSelectedRows = [...selectedRows];

  //   const existingIndex = newSelectedRows.findIndex(
  //     (selectedRow) => selectedRow.id === row.id
  //   );

  //   if (existingIndex !== -1) {
  //     // If already selected, remove from the list
  //     newSelectedRows.splice(existingIndex, 1);
  //   } else {
  //     // If not selected, add to the list
  //     newSelectedRows.push(row);
  //   }

  //   setSelectedRows(newSelectedRows);
  // };

  const handleEditClick = (id, category) => {
    if (category === "Credit Card-Import Corporate Credit Card Statement") {
      navigate("/creditcard", { state: { id: id, category: category } });
    } else {
      navigate("/create-expense", { state: { id: id, category: category } });
    }
  };

  const handleDeleteClick = () => {
    // Log the IDs of selected checkboxes
    const selectedIds = selectedRows.map((row) => row.id);
    let body = {
      id: selectedIds,
    };
    if (selectedIds.length > 0) {
      deleteClaims(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Deleted");
          setApiCall(body);
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.info("Select or Add record to delete");
    }
  };
  const onSubmit = () => {
    let body = {
      claimNumber: reduxclaimno,
      status: "submitted",
    };
    setSpinner(true);
    submitClaimRequest(body)
      .then((res) => {
        if (res.responseCode === 200) {
          setSpinner(false);
          message.success(res.responseMessage);
          navigate("/dashboard-expense");
        } else {
          setSpinner(false);
          message.error(res.responseMessage);
        }
      })
      .catch((err) => {
        setSpinner(false);
      });
  };
  const onCancel = () => {
    let body = {
      claimNumber: reduxclaimno,
    };
    ClaimcancelRequest(body).then((res) => {
      if (res.responseCode === 200) {
        message.success("Request Canceled Successfully");
        navigate("/dashboard-expense");
      } else {
        message.error(res.responseMessage);
      }
    });
  };
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 4 : 2}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={true}
            travel={false}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 20 : 22}
        >
          <div style={{ marginLeft: "1.5rem" }}>
            <Header expense={true} travel={false} />
            <Spin spinning={spinner} tip="Submitting..." size="large">
              <Row>
                <Col lg={9}>
                  <div
                    className="scrollable-container"
                    style={{
                      backgroundColor: "white",
                      height: "66vh",
                      marginTop: "0.5rem",
                      borderRadius: "15px",
                      padding: "0.5rem 0rem 0.5rem 0.5rem",
                    }}
                  >
        <div className="content">
  {categoryData && Object.entries(categoryData).reduce((rows, [category, options], index) => {
    // Check if current row needs to be created
    if (index % 2 === 0) rows.push([]);

    // Add the current category to the last row
    rows[rows.length - 1].push([category, options]);

    return rows;
  }, []).map((row, rowIndex) => (
    <div 
      key={rowIndex}
      style={{ 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        marginBottom: '10px' 
      }}
    >
      {row.map(([category, options], colIndex) => (
        <div 
          key={category} 
          style={{ flex: '1', marginRight: colIndex === 0 ? '10px' : '0' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: '700' }}>{category}</span>
            <Radio.Group
              value={selectedValues} // Ensure only one is selected across all categories
              onChange={(e) => handleRadioChange(e, category)}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {options.map((option) => (
                  <Radio
                    key={option}
                    value={option === "Others" || option === "Entry Fees" ? `${category}-${option}` : option}
                  >
                    {option}
                  </Radio>
                ))}
              </div>
            </Radio.Group>
          </div>
        </div>
      ))}
    </div>
  ))}
</div>

                    {/* <div className="content">
                      <Row>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              Transportations
                            </span>
                            <Radio.Group
                              value={selectedValue}
                              onChange={(e) =>
                                handleRadioChange(e, "Transportations")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Taxi">Taxi</Radio>
                                <Radio value="Client Transport">
                                  Client Transport
                                </Radio>
                                <Radio value="Car Rental">Car Rental</Radio>
                                <Radio value="Train">Train Fare</Radio>
                                <Radio value="Air Fare">Air Fare</Radio>
                                <Radio value="Bus">Bus Fare</Radio>
                                <Radio value="Parking">Parking</Radio>
                                <Radio value="Gas/Fuel">Gas/Fuel</Radio>
                                <Radio value="Vehicles">Vehicles</Radio>
                                <Radio value="Other Transportation">
                                  Others
                                </Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>Travel</span>
                            <Radio.Group
                              value={selectedValue}
                              onChange={(e) => handleRadioChange(e, "Travel")}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Hotel">Hotel</Radio>
                                <Radio value="Laundry">Laundry</Radio>
                                <Radio value="Business Meal">
                                  Business Meal
                                </Radio>
                                <Radio value="Passport/Visa Fees">
                                  Passport/Visa Fees
                                </Radio>
                                <Radio value="Travel Insurance">
                                  Travel Insurance
                                </Radio>
                                <Radio value="FX Conversion Fees">
                                  FX Conversion Fees
                                </Radio>
                                <Radio value="Travel Expense">
                                Travel Expense
                                </Radio>
                                <Radio value="Other Travel">Others</Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              Institutional
                            </span>
                            <Radio.Group
                              value={selectedValue}
                              onChange={(e) =>
                                handleRadioChange(e, "Institutional")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Books/Periodicals">
                                  Books/Periodicals
                                </Radio>
                                <Radio value="Degree Program">
                                  Degree Program
                                </Radio>
                                <Radio value="Professional Certificate">
                                  Professional Certificate
                                </Radio>
                                <Radio value="Professional Subscription">
                                  Professional Subscription
                                </Radio>
                                <Radio value="Trainings">
                                Trainings
                                </Radio>
                                <Radio value="Other Institutional">
                                  Others
                                </Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              Client/Partner Entertainment
                            </span>
                            <Radio.Group
                              value={selectedValue}
                              onChange={(e) =>
                                handleRadioChange(
                                  e,
                                  "Client/Partner Entertainment"
                                )
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Client Lunch/Dinner">
                                  Client Lunch/Dinner
                                </Radio>
                                <Radio value="Clients Refreshments">
                                  Clients Refreshments
                                </Radio>
                                <Radio value="Entry Fees Client/Partner Entertainment">Entry Fees</Radio>
                                <Radio value="Gifts">Gifts</Radio>

                                <Radio value="Other Client/Partner Entertainment">
                                  Others
                                </Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              Employee Welfare
                            </span>
                            <Radio.Group
                              value={selectedValue}
                              onChange={(e) =>
                                handleRadioChange(e, "Employee Welfare")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Team Lunch/Dinner">
                                  Team Lunch/Dinner
                                </Radio>
                                <Radio value="Entry Fees Employee Welfare">Entry Fees</Radio>
                                <Radio value="Rewards">Rewards</Radio>
                                <Radio value="Refreshments">Refreshments</Radio>
                                <Radio value="Other Employee Welfare">
                                  Others
                                </Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>Marketing</span>
                            <Radio.Group
                              value={selectedValue}
                              onChange={(e) =>
                                handleRadioChange(e, "Marketing")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Merchandise">Merchandise</Radio>
                                <Radio value="Exhibit Fees">Exhibit Fees</Radio>
                                <Radio value="Events">Events</Radio>
                                <Radio value="Subscription/Membership">
                                  Subscription/Membership
                                </Radio>

                                <Radio value="Other Marketing">Others</Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              Technology
                            </span>
                            <Radio.Group
                             value={selectedValue}
                              onChange={(e) =>
                                handleRadioChange(e, "Technology")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Peripherals">Peripherals</Radio>
                                <Radio value="Computer Repair">
                                  Computer Repair
                                </Radio>
                                <Radio value="Subscription/Licence">
                                  Subscription/Licence
                                </Radio>
                                <Radio value="Other Technology">Others</Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>Office</span>
                            <Radio.Group
                             value={selectedValue}
                              onChange={(e) => handleRadioChange(e, "Office")}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Courier Charges">
                                  Courier Charges
                                </Radio>
                                <Radio value="Stationary">Stationary</Radio>
                                <Radio value="Office Supplies">
                                  Office Supplies
                                </Radio>
                                <Radio value="Other Office">Others</Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>
                              Communication
                            </span>
                            <Radio.Group
                              value={selectedValue}
                              onChange={(e) =>
                                handleRadioChange(e, "Communication")
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Internet">Internet</Radio>
                                <Radio value="Mobile/Telephone">
                                  Mobile/Telephone
                                </Radio>
                                <Radio value="Other Communication">
                                  Others
                                </Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>Fees</span>
                            <Radio.Group
                         value={selectedValue}
                              onChange={(e) => handleRadioChange(e, "Fees")}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Professional Fees">
                                Professional Fees
                                </Radio>
                               
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                       
                      
                      </Row>
                      <Row>
                      <Col lg={24}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span style={{ fontWeight: "700" }}>Others</span>
                            <Radio.Group
                         value={selectedValue}
                              onChange={(e) => handleRadioChange(e, "Others")}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Radio value="Miscellaneous">
                                  Miscellaneous
                                </Radio>
                                <Radio value="Courier Charges Others">
                                  Courier Charges
                                </Radio>
                                <Radio value="Fitness Reimbursement">
                                  Fitness Reimbursement
                                </Radio>
                                <Radio value="Community Expenditure">
                                Community Expenditure
                                </Radio>
                                <Radio value="Maint. & Repairs">
                                Maint. & Repairs
                                </Radio>
                                <Radio value="Meeting & Workshop">
                                Meeting & Workshop
                                </Radio>
                                <Radio value="Utility Expenses">
                                Utility Expenses
                                </Radio>
                              </div>
                            </Radio.Group>
                          </div>
                        </Col>
                     
                  
                      </Row>
                    </div> */}
                  </div>
                </Col>

                <Col lg={15}>
                  <div
                    style={{
                      marginTop: "0.6rem",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "0.5rem",
                      gap: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Claim No.:</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {reduxclaimno}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Cash Expense:</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {cashExp}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Corporate Card:</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {corpExp}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Total:</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {sessionStorage.getItem("currency")} {totalExp}
                      </span>
                    </div>
                    {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>Total:</span>
                    <span style={{ color: "#3052D0", fontWeight: "500" }}>
                      testing
                    </span>
                  </div> */}
                  </div>
                  <div
                    // className="scroll-container"
                    style={{
                      backgroundColor: "white",
                      height: "67vh",
                      marginTop: "0.5rem",
                      borderRadius: "17px",
                      padding: "0.5rem 0.5rem 0.5rem 0.5rem",
                      margin: "1rem 1rem 0 1rem",
                    }}
                  >
                    <div
                      className="scrollable-container"
                      style={{ height: "60vh" }}
                    >
                      <Table
                        dataSource={data}
                        columns={column}
                        rowKey="id"
                        rowSelection={{
                          onChange: (_, selectedRows) => {
                            setSelectedRows(selectedRows);
                          },
                        }}
                        sticky
                        pagination={false}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "6rem",
                      }}
                    >
                      <Link
                        style={{
                          color: "red",
                          textDecorationLine: "underline",
                        }}
                        type="primary"
                        onClick={handleDeleteClick}
                      >
                        <DeleteOutlined /> Delete Selected
                      </Link>
                      <Button
                        disabled={data.length > 0 ? false : true}
                        style={{ backgroundColor: "green", color: "white" }}
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ backgroundColor: "red", color: "white" }}
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "8rem",
                }}
              >
                <Button
                  disabled={selectedValue && !buttonDisable ? false : true}
                  style={{
                    backgroundColor: "#E93B77",
                    color: "white",
                    // marginTop: "1rem",
                  }}
                  onClick={() => {
                    selectedValue === "Import Corporate Credit Card Statement"
                      ? navigate(
                          `/creditcard?data=${encodeURIComponent(
                            groupValue + "-" + selectedValue
                          )}`
                        )
                      : navigate(
                          `/create-expense?data=${encodeURIComponent(
                            groupValue + "-" + selectedValue
                          )}`
                        );
                  }}
                >
                  Create an Expense
                </Button>
              </div>
            </Spin>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default MainPage;
