import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { useNavigate } from "react-router-dom";
import { Upload } from "antd";
import { useSelector } from "react-redux";
import { decryptObject } from "./GlobalFunctions";

function SideBar({ collapsed, id, expense, travel }) {
  const flags = useSelector((state) => state.flags);
  const [restrictedFlag, setRestrictedFlag] = useState();

  const navigate = useNavigate();
  const [clickedItemId, setClickedItemId] = useState("1");
  const userType = sessionStorage.getItem("userType");
  const [imageUrl, setImageUrl] = useState();
  const logobase64 = sessionStorage.getItem("logobase64");
  const icon = sessionStorage.getItem("icon");
  const logo = sessionStorage.getItem("logo");

  useEffect(() => {
    const flagsObject= decryptObject(flags)
    setRestrictedFlag(flagsObject)
    setClickedItemId(id);
  }, []);
  const handleItemClick = (itemId) => {
    setClickedItemId(itemId);
  };
  const [base64String, setBase64String] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // The result property contains the base64 encoded string
        const base64String = reader.result;
        sessionStorage.setItem("logobase64", base64String);
        setBase64String(base64String);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    } else {
      setBase64String(""); // Reset base64String if the selected file is not an image
    }
  };
  return (
    <>
      <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10%",
          }}
        >
          {collapsed ? (
            <img
              alt="images"
              style={{ width: "48px", height: "auto", marginBottom: "1rem" }}
              src={icon}
            />
          ) : (
            <>
              <img
                alt="images"
                style={{
                  maxWidth: "100%", // Ensure image does not exceed container width
                  width: "160px", // Set specific width for the image
                  height: "auto", // Maintain aspect ratio
                }}
                src={logo}
              />
            </>
          )}
        </div>

        <div
          style={{ marginLeft: "10%", marginBottom: "15%", cursor: "pointer" }}
        >
          {collapsed ? (
            <div
              onClick={() => {
                navigate("/add-request");
              }}
              style={{
                width: "33px",
                height: "33px",
                backgroundColor: "#3052D0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "24px",
                marginLeft: "22%",
                marginBottom: "30%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 3C12.5523 3 13 3.44772 13 4V11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H13V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H11V4C11 3.44772 11.4477 3 12 3Z"
                  fill="white"
                />
              </svg>
            </div>
          ) : travel === true ? (
            <div
              onClick={() => {
                navigate("/add-request");
              }}
              style={{
                marginLeft: "2%",
                width: "150px",
                height: "35px",
                padding: "5px 20px 5px 7px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                backgroundColor: "#3052D0",
                borderRadius: "24px",
              }}
            >
              <div
                style={{
                  width: "33px",
                  height: "33px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "24px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 3C12.5523 3 13 3.44772 13 4V11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H13V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H11V4C11 3.44772 11.4477 3 12 3Z"
                    fill="#3052D0"
                  />
                </svg>
              </div>
              <span style={{ color: "white", marginLeft: "0.5rem" }}>
                Add Travel
              </span>
            </div>
          ) : expense === true ? (
            <div
              onClick={() => {
                navigate("/add-expense");
              }}
              style={{
                marginLeft: "2%",
                width: "150px",
                height: "35px",
                padding: "5px 20px 5px 7px",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                backgroundColor: "#3052D0",
                borderRadius: "24px",
              }}
            >
              <div
                style={{
                  width: "33px",
                  height: "33px",
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "24px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 3C12.5523 3 13 3.44772 13 4V11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H13V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H11V4C11 3.44772 11.4477 3 12 3Z"
                    fill="#3052D0"
                  />
                </svg>
              </div>
              <span style={{ color: "white", marginLeft: "0.5rem" }}>
                Add Expense
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>

        {collapsed ? (
          <ul className="menu-collapsed">
            <li
              id="1"
              onClick={() => {
                handleItemClick("1");
                userType == 2
                  ? navigate("/dashboard")
                  : navigate("/dashboard-m");
              }}
              className={"1" === clickedItemId ? "clicked" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "40%",
              }}
            >
              {clickedItemId === "1" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10 21H5C3.89543 21 3 20.1046 3 19V12.2969C3 11.7852 3.19615 11.2929 3.54809 10.9215L10.5481 3.53257C11.3369 2.69989 12.663 2.69989 13.4519 3.53257L20.4519 10.9215C20.8038 11.2929 21 11.7852 21 12.2969V19C21 20.1046 20.1046 21 19 21H14M10 21V15.5C10 15.2239 10.2239 15 10.5 15H13.5C13.7761 15 14 15.2239 14 15.5V21M10 21H14"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10 21H5C3.89543 21 3 20.1046 3 19V12.2969C3 11.7852 3.19615 11.2929 3.54809 10.9215L10.5481 3.53257C11.3369 2.69989 12.663 2.69989 13.4519 3.53257L20.4519 10.9215C20.8038 11.2929 21 11.7852 21 12.2969V19C21 20.1046 20.1046 21 19 21H14M10 21V15.5C10 15.2239 10.2239 15 10.5 15H13.5C13.7761 15 14 15.2239 14 15.5V21M10 21H14"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                  />
                </svg>
              )}
            </li>
            {
              restrictedFlag?.globalFlag?.insight === 1 && 
              <li
              id="2"
              onClick={() => handleItemClick("2")}
              className={"2" === clickedItemId ? "clicked" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "31%",
              }}
            >
              {clickedItemId === "2" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="2"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M8 16V12"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12 16V10"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16 16V8"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="2"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M8 16V12"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12 16V10"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16 16V8"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </li>
            }
            
            <li
              id="3"
              onClick={() => {
                handleItemClick("3");
                setClickedItemId("3");
                navigate("/notification");
              }}
              className={"3" === clickedItemId ? "clicked" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "30%",
              }}
            >
              {clickedItemId === "3" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.2798 10.0666C7.48379 7.58099 9.58399 5.60569 12.0779 5.59042C14.5491 5.57528 16.5667 7.53275 16.8167 9.99132C16.9708 11.5062 17.3972 12.9016 18.2536 14.387C19.1227 15.8946 18.1938 18 16.4536 18H7.70451C5.95987 18 5.05178 15.842 5.93027 14.3347C6.77156 12.8912 7.15964 11.5309 7.2798 10.0666Z"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M12 3V5"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.2798 10.0666C7.48379 7.58099 9.58399 5.60569 12.0779 5.59042C14.5491 5.57528 16.5667 7.53275 16.8167 9.99132C16.9708 11.5062 17.3972 12.9016 18.2536 14.387C19.1227 15.8946 18.1938 18 16.4536 18H7.70451C5.95987 18 5.05178 15.842 5.93027 14.3347C6.77156 12.8912 7.15964 11.5309 7.2798 10.0666Z"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M12 3V5"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                  />
                </svg>
              )}
            </li>
            <li
              id="5"
              onClick={() => {
                handleItemClick("5");
                setClickedItemId("5");
                navigate("/reports");
              }}
              className={"5" === clickedItemId ? "clicked" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "30%",
              }}
            >
              {clickedItemId === "5" ? (
                <svg
                  width="26"
                  height="29"
                  viewBox="0 0 26 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.1 24.9V3.7C22.1 1.7 20.4 0 18.4 0H17.4H11.8H3.7C1.7 0 0 1.6 0 3.7V24.9C0 26.9 1.7 28.6 3.7 28.6H11.8H17.4H18.4C20.4 28.6 22.1 26.9 22.1 24.9ZM17.4 27.1H16H13.3H11.9H3.7C2.5 27.1 1.5 26.1 1.5 24.9V3.7C1.5 2.4 2.5 1.4 3.7 1.4H11.8H13.2H16H17.4H18.4C19.6 1.4 20.6 2.4 20.6 3.6V24.8C20.6 26 19.6 27 18.4 27H17.4V27.1ZM24.5 13.4L15.7 22.2L12.6 19.1C12.3 18.8 11.7 18.8 11.4 19.1C11.1 19.4 11.1 20 11.4 20.3L15.1 24C15.3 24.2 15.5 24.2 15.7 24.2C15.9 24.2 16.1 24.1 16.3 24L25.7 14.6C26 14.3 26 13.7 25.7 13.4C25.4 13.1 24.8 13.1 24.5 13.4Z"
                    fill="#3052D0"
                  />
                  <path
                    d="M4.3998 6.30001H17.4998C17.7998 6.30001 18.0998 6.10001 18.0998 5.70001C18.0998 5.40001 17.8998 5.10001 17.4998 5.10001H4.3998C3.9998 5.20001 3.7998 5.40001 3.7998 5.70001C3.7998 6.10001 3.9998 6.30001 4.3998 6.30001Z"
                    fill="#3052D0"
                  />
                  <path
                    d="M4.3998 11.7H17.4998C17.7998 11.7 18.0998 11.5 18.0998 11.1C18.0998 10.7 17.8998 10.5 17.4998 10.5H4.3998C4.0998 10.5 3.7998 10.7 3.7998 11.1C3.7998 11.5 3.9998 11.7 4.3998 11.7Z"
                    fill="#3052D0"
                  />
                  <path
                    d="M4.3998 17H17.4998C17.7998 17 18.0998 16.8 18.0998 16.4C18.0998 16.1 17.8998 15.8 17.4998 15.8H4.3998C4.0998 15.8 3.7998 16 3.7998 16.4C3.7998 16.8 3.9998 17 4.3998 17Z"
                    fill="#3052D0"
                  />
                  <path
                    d="M4.3998 22.3H10.1998C10.4998 22.3 10.7998 22.1 10.7998 21.7C10.7998 21.4 10.5998 21.1 10.1998 21.1H4.3998C4.0998 21.1 3.7998 21.3 3.7998 21.7C3.7998 22.1 3.9998 22.3 4.3998 22.3Z"
                    fill="#3052D0"
                  />
                </svg>
              ) : (
                <svg
                  width="26"
                  height="29"
                  viewBox="0 0 26 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.1 24.9V3.7C22.1 1.7 20.4 0 18.4 0H17.4H11.8H3.7C1.7 0 0 1.6 0 3.7V24.9C0 26.9 1.7 28.6 3.7 28.6H11.8H17.4H18.4C20.4 28.6 22.1 26.9 22.1 24.9ZM17.4 27.1H16H13.3H11.9H3.7C2.5 27.1 1.5 26.1 1.5 24.9V3.7C1.5 2.4 2.5 1.4 3.7 1.4H11.8H13.2H16H17.4H18.4C19.6 1.4 20.6 2.4 20.6 3.6V24.8C20.6 26 19.6 27 18.4 27H17.4V27.1ZM24.5 13.4L15.7 22.2L12.6 19.1C12.3 18.8 11.7 18.8 11.4 19.1C11.1 19.4 11.1 20 11.4 20.3L15.1 24C15.3 24.2 15.5 24.2 15.7 24.2C15.9 24.2 16.1 24.1 16.3 24L25.7 14.6C26 14.3 26 13.7 25.7 13.4C25.4 13.1 24.8 13.1 24.5 13.4Z"
                    fill="#010101"
                  />
                  <path
                    d="M4.3998 6.30001H17.4998C17.7998 6.30001 18.0998 6.10001 18.0998 5.70001C18.0998 5.40001 17.8998 5.10001 17.4998 5.10001H4.3998C3.9998 5.20001 3.7998 5.40001 3.7998 5.70001C3.7998 6.10001 3.9998 6.30001 4.3998 6.30001Z"
                    fill="#010101"
                  />
                  <path
                    d="M4.3998 11.7H17.4998C17.7998 11.7 18.0998 11.5 18.0998 11.1C18.0998 10.7 17.8998 10.5 17.4998 10.5H4.3998C4.0998 10.5 3.7998 10.7 3.7998 11.1C3.7998 11.5 3.9998 11.7 4.3998 11.7Z"
                    fill="#010101"
                  />
                  <path
                    d="M4.3998 17H17.4998C17.7998 17 18.0998 16.8 18.0998 16.4C18.0998 16.1 17.8998 15.8 17.4998 15.8H4.3998C4.0998 15.8 3.7998 16 3.7998 16.4C3.7998 16.8 3.9998 17 4.3998 17Z"
                    fill="#010101"
                  />
                  <path
                    d="M4.3998 22.3H10.1998C10.4998 22.3 10.7998 22.1 10.7998 21.7C10.7998 21.4 10.5998 21.1 10.1998 21.1H4.3998C4.0998 21.1 3.7998 21.3 3.7998 21.7C3.7998 22.1 3.9998 22.3 4.3998 22.3Z"
                    fill="#010101"
                  />
                </svg>
              )}
            </li>
            <li
              id="6"
              onClick={() => {
                handleItemClick("6");
                setClickedItemId("6");
                navigate("/costen-drive");
              }}
              className={"6" === clickedItemId ? "clicked" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "30%",
              }}
            >
              {clickedItemId === "6" ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="solar:cloud-storage-linear">
                    <g id="Group">
                      <path
                        id="Vector"
                        d="M6.286 18C3.919 18 2 16.104 2 13.765C2 11.425 3.919 9.529 6.286 9.529C6.57 9.529 6.848 9.557 7.116 9.609M14.381 7.027C14.9934 6.81361 15.6375 6.70509 16.286 6.706C16.94 6.706 17.569 6.815 18.156 7.015M18.156 7.015C17.837 4.194 15.416 2 12.476 2C9.32 2 6.762 4.528 6.761 7.647C6.76017 8.31696 6.88009 8.98158 7.115 9.609C7.6683 9.71646 8.195 9.9319 8.665 10.243M18.156 7.015C20.393 7.78 22 9.881 22 12.353C22 15.06 20.073 17.323 17.5 17.873"
                        stroke="#3052D0"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        id="Vector_2"
                        d="M8.5 17C8.5 15.586 8.5 14.879 8.94 14.44C9.379 14 10.086 14 11.5 14H12.5C13.914 14 14.621 14 15.06 14.44C15.5 14.879 15.5 15.586 15.5 17V19C15.5 20.414 15.5 21.121 15.06 21.56C14.621 22 13.914 22 12.5 22H11.5C10.086 22 9.379 22 8.94 21.56C8.5 21.121 8.5 20.414 8.5 19V17Z"
                        stroke="#3052D0"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector_3"
                        d="M11 18H13"
                        stroke="#3052D0"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="solar:cloud-storage-linear">
                    <g id="Group">
                      <path
                        id="Vector"
                        d="M6.286 18C3.919 18 2 16.104 2 13.765C2 11.425 3.919 9.529 6.286 9.529C6.57 9.529 6.848 9.557 7.116 9.609M14.381 7.027C14.9934 6.81361 15.6375 6.70509 16.286 6.706C16.94 6.706 17.569 6.815 18.156 7.015M18.156 7.015C17.837 4.194 15.416 2 12.476 2C9.32 2 6.762 4.528 6.761 7.647C6.76017 8.31696 6.88009 8.98158 7.115 9.609C7.6683 9.71646 8.195 9.9319 8.665 10.243M18.156 7.015C20.393 7.78 22 9.881 22 12.353C22 15.06 20.073 17.323 17.5 17.873"
                        stroke="#2A2A2E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        id="Vector_2"
                        d="M8.5 17C8.5 15.586 8.5 14.879 8.94 14.44C9.379 14 10.086 14 11.5 14H12.5C13.914 14 14.621 14 15.06 14.44C15.5 14.879 15.5 15.586 15.5 17V19C15.5 20.414 15.5 21.121 15.06 21.56C14.621 22 13.914 22 12.5 22H11.5C10.086 22 9.379 22 8.94 21.56C8.5 21.121 8.5 20.414 8.5 19V17Z"
                        stroke="#2A2A2E"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector_3"
                        d="M11 18H13"
                        stroke="#2A2A2E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              )}
            </li>
          </ul>
        ) : (
          <ul className="menu">
            <li
              id="1"
              onClick={() => {
                handleItemClick("1");
                userType == 2
                  ? navigate("/dashboard")
                  : navigate("/dashboard-m");
              }}
              className={"1" === clickedItemId ? "clicked" : ""}
            >
              {clickedItemId === "1" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10 21H5C3.89543 21 3 20.1046 3 19V12.2969C3 11.7852 3.19615 11.2929 3.54809 10.9215L10.5481 3.53257C11.3369 2.69989 12.663 2.69989 13.4519 3.53257L20.4519 10.9215C20.8038 11.2929 21 11.7852 21 12.2969V19C21 20.1046 20.1046 21 19 21H14M10 21V15.5C10 15.2239 10.2239 15 10.5 15H13.5C13.7761 15 14 15.2239 14 15.5V21M10 21H14"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10 21H5C3.89543 21 3 20.1046 3 19V12.2969C3 11.7852 3.19615 11.2929 3.54809 10.9215L10.5481 3.53257C11.3369 2.69989 12.663 2.69989 13.4519 3.53257L20.4519 10.9215C20.8038 11.2929 21 11.7852 21 12.2969V19C21 20.1046 20.1046 21 19 21H14M10 21V15.5C10 15.2239 10.2239 15 10.5 15H13.5C13.7761 15 14 15.2239 14 15.5V21M10 21H14"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                  />
                </svg>
              )}
              {!collapsed && (
                <span
                  id="1"
                  onClick={() => handleItemClick("1")}
                  className={"1" === clickedItemId ? "clicked" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Dashboard
                </span>
              )}
            </li>
            {
              restrictedFlag?.globalFlag?.insight === 1 &&
              <li
              id="2"
              onClick={() => {
                handleItemClick("2");
                navigate("/insight");
              }}
              className={"2" === clickedItemId ? "clicked" : ""}
            >
              {clickedItemId === "2" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="2"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M8 16V12"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12 16V10"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16 16V8"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="2"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M8 16V12"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12 16V10"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M16 16V8"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              )}
              {!collapsed && restrictedFlag?.globalFlag?.insight === 1 && (
                
                  <span
                  id="2"
                  onClick={() => {
                    handleItemClick("2");
                    navigate("/insight");
                  }}
                  className={"2" === clickedItemId ? "clicked" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Insights
                </span>
                
              
              )}
            </li>
            }
          
            <li
              id="3"
              onClick={() => {
                handleItemClick("3");
                setClickedItemId("3");
                navigate("/notification");
              }}
              className={"3" === clickedItemId ? "clicked" : ""}
            >
              {clickedItemId === "3" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.2798 10.0666C7.48379 7.58099 9.58399 5.60569 12.0779 5.59042C14.5491 5.57528 16.5667 7.53275 16.8167 9.99132C16.9708 11.5062 17.3972 12.9016 18.2536 14.387C19.1227 15.8946 18.1938 18 16.4536 18H7.70451C5.95987 18 5.05178 15.842 5.93027 14.3347C6.77156 12.8912 7.15964 11.5309 7.2798 10.0666Z"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M12 3V5"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18"
                    stroke="#3052D0"
                    strokeWidth="1.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7.2798 10.0666C7.48379 7.58099 9.58399 5.60569 12.0779 5.59042C14.5491 5.57528 16.5667 7.53275 16.8167 9.99132C16.9708 11.5062 17.3972 12.9016 18.2536 14.387C19.1227 15.8946 18.1938 18 16.4536 18H7.70451C5.95987 18 5.05178 15.842 5.93027 14.3347C6.77156 12.8912 7.15964 11.5309 7.2798 10.0666Z"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M12 3V5"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18"
                    stroke="#1F1F22"
                    strokeWidth="1.5"
                  />
                </svg>
              )}
              {!collapsed && (
                <span
                  id="3"
                  onClick={() => {
                    handleItemClick("3");
                    setClickedItemId("3");
                    navigate("/notification");
                  }}
                  className={"3" === clickedItemId ? "clicked" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Notifications
                </span>
              )}
            </li>
            <li
              id="5"
              onClick={() => {
                handleItemClick("5");
                navigate("/reports");
              }}
              className={"5" === clickedItemId ? "clicked" : ""}
            >
              {clickedItemId === "5" ? (
                <svg
                  width="26"
                  height="29"
                  viewBox="0 0 26 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.1 24.9V3.7C22.1 1.7 20.4 0 18.4 0H17.4H11.8H3.7C1.7 0 0 1.6 0 3.7V24.9C0 26.9 1.7 28.6 3.7 28.6H11.8H17.4H18.4C20.4 28.6 22.1 26.9 22.1 24.9ZM17.4 27.1H16H13.3H11.9H3.7C2.5 27.1 1.5 26.1 1.5 24.9V3.7C1.5 2.4 2.5 1.4 3.7 1.4H11.8H13.2H16H17.4H18.4C19.6 1.4 20.6 2.4 20.6 3.6V24.8C20.6 26 19.6 27 18.4 27H17.4V27.1ZM24.5 13.4L15.7 22.2L12.6 19.1C12.3 18.8 11.7 18.8 11.4 19.1C11.1 19.4 11.1 20 11.4 20.3L15.1 24C15.3 24.2 15.5 24.2 15.7 24.2C15.9 24.2 16.1 24.1 16.3 24L25.7 14.6C26 14.3 26 13.7 25.7 13.4C25.4 13.1 24.8 13.1 24.5 13.4Z"
                    fill="#3052D0"
                  />
                  <path
                    d="M4.3998 6.30001H17.4998C17.7998 6.30001 18.0998 6.10001 18.0998 5.70001C18.0998 5.40001 17.8998 5.10001 17.4998 5.10001H4.3998C3.9998 5.20001 3.7998 5.40001 3.7998 5.70001C3.7998 6.10001 3.9998 6.30001 4.3998 6.30001Z"
                    fill="#3052D0"
                  />
                  <path
                    d="M4.3998 11.7H17.4998C17.7998 11.7 18.0998 11.5 18.0998 11.1C18.0998 10.7 17.8998 10.5 17.4998 10.5H4.3998C4.0998 10.5 3.7998 10.7 3.7998 11.1C3.7998 11.5 3.9998 11.7 4.3998 11.7Z"
                    fill="#3052D0"
                  />
                  <path
                    d="M4.3998 17H17.4998C17.7998 17 18.0998 16.8 18.0998 16.4C18.0998 16.1 17.8998 15.8 17.4998 15.8H4.3998C4.0998 15.8 3.7998 16 3.7998 16.4C3.7998 16.8 3.9998 17 4.3998 17Z"
                    fill="#3052D0"
                  />
                  <path
                    d="M4.3998 22.3H10.1998C10.4998 22.3 10.7998 22.1 10.7998 21.7C10.7998 21.4 10.5998 21.1 10.1998 21.1H4.3998C4.0998 21.1 3.7998 21.3 3.7998 21.7C3.7998 22.1 3.9998 22.3 4.3998 22.3Z"
                    fill="#3052D0"
                  />
                </svg>
              ) : (
                <svg
                  width="26"
                  height="29"
                  viewBox="0 0 26 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.1 24.9V3.7C22.1 1.7 20.4 0 18.4 0H17.4H11.8H3.7C1.7 0 0 1.6 0 3.7V24.9C0 26.9 1.7 28.6 3.7 28.6H11.8H17.4H18.4C20.4 28.6 22.1 26.9 22.1 24.9ZM17.4 27.1H16H13.3H11.9H3.7C2.5 27.1 1.5 26.1 1.5 24.9V3.7C1.5 2.4 2.5 1.4 3.7 1.4H11.8H13.2H16H17.4H18.4C19.6 1.4 20.6 2.4 20.6 3.6V24.8C20.6 26 19.6 27 18.4 27H17.4V27.1ZM24.5 13.4L15.7 22.2L12.6 19.1C12.3 18.8 11.7 18.8 11.4 19.1C11.1 19.4 11.1 20 11.4 20.3L15.1 24C15.3 24.2 15.5 24.2 15.7 24.2C15.9 24.2 16.1 24.1 16.3 24L25.7 14.6C26 14.3 26 13.7 25.7 13.4C25.4 13.1 24.8 13.1 24.5 13.4Z"
                    fill="#010101"
                  />
                  <path
                    d="M4.3998 6.30001H17.4998C17.7998 6.30001 18.0998 6.10001 18.0998 5.70001C18.0998 5.40001 17.8998 5.10001 17.4998 5.10001H4.3998C3.9998 5.20001 3.7998 5.40001 3.7998 5.70001C3.7998 6.10001 3.9998 6.30001 4.3998 6.30001Z"
                    fill="#010101"
                  />
                  <path
                    d="M4.3998 11.7H17.4998C17.7998 11.7 18.0998 11.5 18.0998 11.1C18.0998 10.7 17.8998 10.5 17.4998 10.5H4.3998C4.0998 10.5 3.7998 10.7 3.7998 11.1C3.7998 11.5 3.9998 11.7 4.3998 11.7Z"
                    fill="#010101"
                  />
                  <path
                    d="M4.3998 17H17.4998C17.7998 17 18.0998 16.8 18.0998 16.4C18.0998 16.1 17.8998 15.8 17.4998 15.8H4.3998C4.0998 15.8 3.7998 16 3.7998 16.4C3.7998 16.8 3.9998 17 4.3998 17Z"
                    fill="#010101"
                  />
                  <path
                    d="M4.3998 22.3H10.1998C10.4998 22.3 10.7998 22.1 10.7998 21.7C10.7998 21.4 10.5998 21.1 10.1998 21.1H4.3998C4.0998 21.1 3.7998 21.3 3.7998 21.7C3.7998 22.1 3.9998 22.3 4.3998 22.3Z"
                    fill="#010101"
                  />
                </svg>
              )}
              {!collapsed && (
                <span
                  id="5"
                  onClick={() => {
                    handleItemClick("5");
                    navigate("/reports");
                  }}
                  className={"5" === clickedItemId ? "clicked" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Reports
                </span>
              )}
            </li>
            <li
              id="6"
              onClick={() => {
                handleItemClick("6");
                navigate("/costen-drive");
              }}
              className={"6" === clickedItemId ? "clicked" : ""}
            >
              {clickedItemId === "6" ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="solar:cloud-storage-linear">
                    <g id="Group">
                      <path
                        id="Vector"
                        d="M6.286 18C3.919 18 2 16.104 2 13.765C2 11.425 3.919 9.529 6.286 9.529C6.57 9.529 6.848 9.557 7.116 9.609M14.381 7.027C14.9934 6.81361 15.6375 6.70509 16.286 6.706C16.94 6.706 17.569 6.815 18.156 7.015M18.156 7.015C17.837 4.194 15.416 2 12.476 2C9.32 2 6.762 4.528 6.761 7.647C6.76017 8.31696 6.88009 8.98158 7.115 9.609C7.6683 9.71646 8.195 9.9319 8.665 10.243M18.156 7.015C20.393 7.78 22 9.881 22 12.353C22 15.06 20.073 17.323 17.5 17.873"
                        stroke="#3052D0"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        id="Vector_2"
                        d="M8.5 17C8.5 15.586 8.5 14.879 8.94 14.44C9.379 14 10.086 14 11.5 14H12.5C13.914 14 14.621 14 15.06 14.44C15.5 14.879 15.5 15.586 15.5 17V19C15.5 20.414 15.5 21.121 15.06 21.56C14.621 22 13.914 22 12.5 22H11.5C10.086 22 9.379 22 8.94 21.56C8.5 21.121 8.5 20.414 8.5 19V17Z"
                        stroke="#3052D0"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector_3"
                        d="M11 18H13"
                        stroke="#3052D0"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="solar:cloud-storage-linear">
                    <g id="Group">
                      <path
                        id="Vector"
                        d="M6.286 18C3.919 18 2 16.104 2 13.765C2 11.425 3.919 9.529 6.286 9.529C6.57 9.529 6.848 9.557 7.116 9.609M14.381 7.027C14.9934 6.81361 15.6375 6.70509 16.286 6.706C16.94 6.706 17.569 6.815 18.156 7.015M18.156 7.015C17.837 4.194 15.416 2 12.476 2C9.32 2 6.762 4.528 6.761 7.647C6.76017 8.31696 6.88009 8.98158 7.115 9.609C7.6683 9.71646 8.195 9.9319 8.665 10.243M18.156 7.015C20.393 7.78 22 9.881 22 12.353C22 15.06 20.073 17.323 17.5 17.873"
                        stroke="#2A2A2E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        id="Vector_2"
                        d="M8.5 17C8.5 15.586 8.5 14.879 8.94 14.44C9.379 14 10.086 14 11.5 14H12.5C13.914 14 14.621 14 15.06 14.44C15.5 14.879 15.5 15.586 15.5 17V19C15.5 20.414 15.5 21.121 15.06 21.56C14.621 22 13.914 22 12.5 22H11.5C10.086 22 9.379 22 8.94 21.56C8.5 21.121 8.5 20.414 8.5 19V17Z"
                        stroke="#2A2A2E"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector_3"
                        d="M11 18H13"
                        stroke="#2A2A2E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </g>
                </svg>
              )}
              {!collapsed && (
                <span
                  id="6"
                  onClick={() => {
                    handleItemClick("6");
                    navigate("/costen-drive");
                  }}
                  className={"6" === clickedItemId ? "clicked" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Costen Drive
                </span>
              )}
            </li>
          </ul>
        )}

        {!collapsed ? (
          <img
            style={{
              position: "absolute",
              bottom: "25px",
              left: "30%",
              transform: "translateX(-50%)",
              width: "5rem",
            }}
            src="sidebarcosten.png"
          />
        ) : (
          <img
            style={{
              position: "absolute",
              bottom: "25px",
              left: "40%",
              transform: "translateX(-50%)",
              width: "3rem",
            }}
            src="sidebarcosten.png"
          />
        )}

        {/* <div style={{ marginTop: "33%", cursor: "pointer" }}>

          {!collapsed ? (
            <div
              onClick={() => {
                navigate("/needhelp");
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                pointer: "cursor",
                gap: "3%",
                marginBottom: "10%",
              }}
            >
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="34" height="34" rx="17" fill="#3052D0" />
                <path
                  d="M15.1771 14.9185C15.3239 14.5011 15.6137 14.1492 15.9952 13.925C16.3767 13.7008 16.8252 13.6188 17.2613 13.6936C17.6974 13.7684 18.0929 13.9952 18.3779 14.3337C18.6628 14.6722 18.8188 15.1006 18.8181 15.5431C18.8181 16.7921 16.9445 17.4167 16.9445 17.4167M16.9674 19.9167H16.9758M24.5 17C24.5 21.1421 21.1421 24.5 17 24.5C12.8579 24.5 9.5 21.1421 9.5 17C9.5 12.8579 12.8579 9.5 17 9.5C21.1421 9.5 24.5 12.8579 24.5 17Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span
                id="4"
                onClick={() => handleItemClick("4")}
                className={"4" === clickedItemId ? "clicked" : ""}
                style={{ fontSize: "16px", fontWeight: "400" }}
              >
                Need Help
              </span>
            </div>
          ) : (
            <div
              onClick={() => {
                navigate("/needhelp");
              }}
              style={{
                marginTop: "60%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="34" height="34" rx="17" fill="#3052D0" />
                <path
                  d="M15.1771 14.9185C15.3239 14.5011 15.6137 14.1492 15.9952 13.925C16.3767 13.7008 16.8252 13.6188 17.2613 13.6936C17.6974 13.7684 18.0929 13.9952 18.3779 14.3337C18.6628 14.6722 18.8188 15.1006 18.8181 15.5431C18.8181 16.7921 16.9445 17.4167 16.9445 17.4167M16.9674 19.9167H16.9758M24.5 17C24.5 21.1421 21.1421 24.5 17 24.5C12.8579 24.5 9.5 21.1421 9.5 17C9.5 12.8579 12.8579 9.5 17 9.5C21.1421 9.5 24.5 12.8579 24.5 17Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
          {!collapsed ? (
            <div
              onClick={() => navigate("/logout")}
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                gap: "5%",
                marginTop: "10%",
                marginLeft: "25%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 12L19 12M19 12L17 10M19 12L17 14M15 16L15 19C15 20.1046 14.1046 21 13 21L7 21C5.89543 21 5 20.1046 5 19L5 5C5 3.89543 5.89543 3 7 3L13 3C14.1046 3 15 3.89543 15 5L15 8"
                  stroke="#E65F2B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
              <span
                style={{
                  color: "#E65F2B",
                  fontSize: "16px",
                  marginLeft: "4%",
                }}
              >
                Logout
              </span>
            </div>
          ) : (
            <div
              onClick={() => navigate("/logout")}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "17%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10 12L19 12M19 12L17 10M19 12L17 14M15 16L15 19C15 20.1046 14.1046 21 13 21L7 21C5.89543 21 5 20.1046 5 19L5 5C5 3.89543 5.89543 3 7 3L13 3C14.1046 3 15 3.89543 15 5L15 8"
                  stroke="#E65F2B"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          )}
        </div> */}
      </div>
    </>
  );
}

export default SideBar;
