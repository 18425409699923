import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
// import "./datepicker.css";

import {
  amount,
  bus,
  carRental,
  cashAdvance,
  claimno,
  flight,
  hoteldata,
  perdiem,
  perdiemextra,
  requesteid,
  taxi,
  train,
  travelHeader,
} from "../redux/actions";
import { useDispatch } from "react-redux";
import ExpenseAdminSideBar from "./ExpenseAdminSideBar";
import image from "./otherimageadmin.json";
import Lottie from "react-lottie";
import PageNotFound from "./PageNotFound";
function OtherExpenseAdmin() {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [dateOfJoining, setDateOfJoining] = useState();
  const adminRights=sessionStorage.getItem('aSF')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: image,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    dispatch(bus({}));
    dispatch(cashAdvance({}));
    dispatch(carRental({}));
    dispatch(flight({}));
    dispatch(hoteldata({}));
    dispatch(perdiem({}));
    dispatch(perdiemextra({}));
    dispatch(taxi({}));
    dispatch(train({}));
    dispatch(travelHeader({}));
    dispatch(requesteid(""));
    dispatch(claimno(""));
    dispatch(amount(0));

    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const handleDateChange = (date) => {
    setDateOfJoining(date);
  };
  return (
    <>
      {
        adminRights==='2' || adminRights === '3' ?  <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 4 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <ExpenseAdminSideBar collapsed={collapsed} id={"5"}/>
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 20 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={false} travel={true} />
          <Row>
            <Col lg={12} style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  // display: "flex",
                  width: "90%",
                  marginTop: "6.3rem",
                  height: "30vh",
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 6px 0px rgba(151, 172, 198, 0.25)",
                  // justifyContent: "center",
                }}
              >
                <h2 style={{ marginTop: "1.5rem",marginLeft:'1rem'}}>For addtional support</h2>
                <div style={{display:'flex',flexDirection:'row',marginLeft:'1rem'}}>
                <span style={{fontSize:'16px',marginRight:'4px'}}>
                Please do not hesitate to contact us at 
                </span>
                <a style={{fontSize:'16px',color:'blue',textDecorationLine:'underline'}} href="mailto:support@costen.tech">support@costen.tech</a>
               
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <Lottie options={defaultOptions} height={400} width={400} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Footer />
    </div>:<PageNotFound/>
      }
    </>
  
  );
}

export default OtherExpenseAdmin;
