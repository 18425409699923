import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import { Button, Col, Radio, Row, Spin, Table, message } from "antd";
import SideBar from "../../SideBar";
import "./mainpage.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ClaimcancelRequest,
  deleteClaims,
  submitClaimRequest,
  submitReceiptList,
} from "../../../services/ApiService";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
function CreditCardMain() {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [groupValue, setGroupValue] = useState(null);
  const [column, setColumn] = useState();
  const [apiCall, setApiCall] = useState();
  const navigate = useNavigate();
  const [cashExp, setCashExp] = useState();
  const [corpExp, setCorpExp] = useState();
  const [totalExp, setTotalExp] = useState();
  const reduxclaimno = useSelector((state) => state.claimno);
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  useEffect(() => {
    if (reduxclaimno) {
      submitReceiptList(reduxclaimno).then((res) => {
        if (res.responseCode === 200) {
          setCashExp(res.cashTotal);
          setCorpExp(res.corporateTotal);
          setTotalExp(res.totalAmount);
          if (res.data.length > 0) {
            let hasCreditCardStatement = res.data.some(
              (item) =>
                item.category ===
                "Credit Card-Import Corporate Credit Card Statement"
            );
            hasCreditCardStatement
              ? setButtonDisable(true)
              : setButtonDisable(false);
            setData(res.data);
            const columns = Object.keys(res.data[0])
              .filter((key) => key !== "id") // Exclude 'id' column
              .map((key) => ({
                title: key.charAt(0).toUpperCase() + key.slice(1),
                dataIndex: key,
                key,
              }));

            // Add additional columns for the "Edit" and "Delete" buttons
            columns.push(
              {
                title: "Edit",
                key: "edit",
                render: (text, record) => (
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    onClick={() => handleEditClick(record.id, record.category)}
                  >
                    Edit
                  </Button>
                ),
              }
           
            );

            setColumn(columns);
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    }

    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [apiCall]);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const handleRadioChange = (e, groupName) => {
    const { value } = e.target;
    setSelectedValue(value);
    setGroupValue(groupName);
  };
  const [selectedRows, setSelectedRows] = useState([]);

 
  const handleEditClick = (id, category) => {
    if (category === "Credit Card-Import Corporate Credit Card Statement" || category === "Import Corporate Credit Card Statement") {
      navigate("/creditcard", { state: { id: id, category: category } });
    } else {
      navigate("/create-expense", { state: { id: id, category: category } });
    }
  };

  const handleDeleteClick = () => {
    const selectedIds = selectedRows.map((row) => row.id);
    let body = {
      id: selectedIds,
    };
    if (selectedIds.length > 0) {
      deleteClaims(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Deleted");
          setApiCall(body);
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.info("Select or Add record to delete");
    }
  };
  const onSubmit = () => {
    let body = {
      claimNumber: reduxclaimno,
      status: "submitted",
    };
    setSpinner(true);
    submitClaimRequest(body)
      .then((res) => {
        if (res.responseCode === 200) {
          setSpinner(false);
          message.success("Submitted Successfully");
          navigate("/dashboard-expense");
        } else {
          setSpinner(false);
          message.error(res.responseMessage);
        }
      })
      .catch((err) => {
        setSpinner(false);
      });
  };
  const onCancel = () => {
    let body = {
      claimNumber: reduxclaimno,
    };
    ClaimcancelRequest(body).then((res) => {
      if (res.responseCode === 200) {
        message.success("Request Canceled Successfully");
        navigate("/dashboard-expense");
      } else {
        message.error(res.responseMessage);
      }
    });
  };
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 4 : 2}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={true}
            travel={false}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 20 : 22}
        >
          <div style={{ marginLeft: "1.5rem" }}>
            <Header expense={true} travel={false} />
            <Spin spinning={spinner} tip="Submitting..." size="large">
           

              
                  <div
                    style={{
                      marginTop: "0.6rem",
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: "0.5rem",
                      gap: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Claim No.:</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {reduxclaimno}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Cash Expense:</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {cashExp}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Corporate Card:</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {corpExp}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Total:</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {sessionStorage.getItem("currency")} {totalExp}
                      </span>
                    </div>
                    {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>Total:</span>
                    <span style={{ color: "#3052D0", fontWeight: "500" }}>
                      testing
                    </span>
                  </div> */}
                  </div>
                  <div
                    // className="scroll-container"
                    style={{
                      backgroundColor: "white",
                      height: "67vh",
                      marginTop: "0.5rem",
                      borderRadius: "17px",
                      padding: "0.5rem 0.5rem 0.5rem 0.5rem",
                      margin: "1rem 1rem 0 1rem",
                    }}
                  >
                    <div
                      className="scrollable-container"
                      style={{ height: "60vh" }}
                    >
                      <Table
                        dataSource={data}
                        columns={column}
                        rowKey="id"
                        rowSelection={{
                          onChange: (_, selectedRows) => {
                            setSelectedRows(selectedRows);
                          },
                        }}
                        sticky
                        pagination={false}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "6rem",
                      }}
                    >
                      <Link
                        style={{
                          color: "red",
                          textDecorationLine: "underline",
                        }}
                        type="primary"
                        onClick={handleDeleteClick}
                      >
                        <DeleteOutlined /> Delete Selected
                      </Link>
                      <Button
                        disabled={data.length > 0 ? false : true}
                        style={{ backgroundColor: "green", color: "white",marginLeft:"10vw" }}
                        onClick={() => {
                          onSubmit();
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ backgroundColor: "red", color: "white" }}
                        onClick={() => {
                          onCancel();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
          
            </Spin>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default CreditCardMain;
