import React, { useEffect, useState } from "react";
import { Button, Col,Input,Popover,Row, Select, Space, Table, message } from "antd";
import SideBar from "../SideBar";
import Header from "../Header";
import "../dashboard.css";
import Footer from "../Footer";
import "../tobeapproved.css";
import { ExpensewithdrawRequest, allExpenseTravelRequestDashboard, allTravelRequestDashboard } from "../../services/ApiService";
import moment from "moment";
import { WarningOutlined} from '@ant-design/icons';

const { Option } = Select;
function TotalRequestExpense() {
  const [collapsed, setCollapsed] = useState(false);
  const [details,setDetails]=useState(false)
  const[id,setId]=useState()
  const[totalRequest,setTotalRequest]= useState([])
  const columns = [];
  const today = new Date();


  const empId=sessionStorage.employeeId
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    // Note: Month is 0-indexed in JavaScript Date (0 for January, 11 for December)
    return new Date(year, month - 1, day);
  };
 useEffect(() => {
 
  allExpenseTravelRequestDashboard(empId).then((res)=>{
    if(res.responseCode===200){
        const dataSource = res.data.totalRequest.map(item => {
            if (item.start_date) {
              item.start_date = moment(item.start_date).format('DD-MM-YYYY');
            }
            if (item.to_be_approved) {
              item.to_be_approved = moment(item.to_be_approved).format("DD-MM-YYYY");
            }
            return item;
          });
          setTotalRequest(res.data.totalRequest)

    }
    else{
        message.error(res.responseMessage)
    }
})
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
 

  if (totalRequest.length > 0) {
    const firstDataItem = totalRequest[0];
    for (const key in firstDataItem) {
      if (firstDataItem.hasOwnProperty(key) && key !== "rejectedBy" && key !== "reasonReject") {
        let column = {
          title: key.toUpperCase().replaceAll("_", " "),
          dataIndex: key,
          key: key,
        };
  
        // Add custom rendering logic for the "status" column
        if (key === "status") {
          column.render = (text, record) => {
            // Assuming "text" contains the status value
            if (text === "rejected") {
              return (
                <Popover
                content={
                 <span>
                  {record.rejectedBy} : {record.reasonReject}
                 </span>
                }
              >
                <span>
                  {text} <WarningOutlined style={{ color: "red" }} />
                </span>
                </Popover>
              );
            } 
            else if(key==="to_be_approved"){
              return (
                <>
                  {record.to_be_approved !==null && parseDate(record.to_be_approved)<today && parseDate(record.to_be_approved).toDateString() !== today.toDateString()?     <span
                  style={{
                    color: "red",
                    border: "none",
                    backgroundColor: "#534ecc0c",
                    textAlign: "center",
                    borderRadius: "15px",
                    padding: "0.3rem 0.5rem 0.3rem 0.5rem",
                  }}
                >
                  {record.to_be_approved}
                </span>:     <span
                  style={{
                    color: "black",
                    border: "none",
                    backgroundColor: record.to_be_approved !==null? "#534ecc0c":"",
                    textAlign: "center",
                    borderRadius: "15px",
                    padding: "0.3rem 0.5rem 0.3rem 0.5rem",
                  }}
                >
                  {record.to_be_approved}
                </span>}
                </>
           
              );
            }
            else {
              return text;
            }
          };
        }
  
        columns.push(column);
      }
    }
  }

  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar collapsed={collapsed} id={"1"} expense={true} travel={false}/>
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
           <Header expense={true} travel={false} />
          <div style={{
              backgroundColor: "white",
              display: "block",
              padding: "1rem",
              marginRight: "1.5rem",
              borderRadius: "15px",
              marginTop: "2rem",
              overflow: "auto",
              height: "75vh",
            }}>
      
        {totalRequest?  <Table
         
            scroll={{ x: 1490, y: 290 }}
              
                dataSource={totalRequest}
                columns={columns}
                rowKey="id"
                pagination={true}
            />:<></>}
        
          </div>
        
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default TotalRequestExpense;
