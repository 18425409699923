import React, { useEffect, useState } from "react";
import { Col, Menu, Row } from "antd";
import SideBar from "./SideBar";
import Header from "./Header";
import "./dashboard.css";
import Footer from "./Footer";
import "./tobeapproved.css";
import AuditReports from "./AuditReports";
import BankFileReport from "./BankFileReport";
import NomialReports from "./NomialReports";
import { useSelector } from "react-redux";
import { decryptObject } from "./GlobalFunctions";

function Reports() {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("auditReports");
  const flags = useSelector((state) => state.flags);
  const [restrictedFlag, setRestrictedFlag] = useState();
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
useEffect(()=>{
  const flagsObject= decryptObject(flags)
  setRestrictedFlag(flagsObject)
},[])
  let items = [
    {
      label: "Audit Reports",
      key: "auditReports",
    },

    {
      label: "Bank File Reports",
      key: "bankFileReports",
    },
    // {
    //   label: "Nominal/GL/Accounts Reports",
    //   key: "nominal",
    
    // },
  ];
  if (
    sessionStorage.getItem("aSF") === "0"
  ) {
    items = items.filter((item) => item.key !== "bankFileReports");
    items = items.filter((item) => item.key !== "nominal");

  }
  if(restrictedFlag?.expenseAdminFlag?.bankReport=== 0  ){
    items = items.filter((item) => item.key !== "bankFileReports");

  }
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar collapsed={collapsed} id={"5"} expense={false} travel={true}/>
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={false} travel={true} />
          <div
            style={{ height: "85vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <div style={{ marginRight: "3rem", marginTop: "2rem" }}>
              <Menu
                style={{
                  width: "100%",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                defaultSelectedKeys={["auditReports"]}
                onClick={({ item, key }) => {
                  setSelectedKey(key);
                }}
              >
                {items.map((item) => (
                  <Menu.Item key={item.key} disabled={item.key==="nominal"}>{item.label}</Menu.Item>
                ))}
              </Menu>
            </div>
            {
              selectedKey==="auditReports"?<AuditReports/>:(selectedKey==="bankFileReports"?<BankFileReport/>:<NomialReports/>)
            }
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default Reports;
