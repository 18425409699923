import React, { useEffect, useState } from "react";
import {message, Row, Col } from "antd";
import SideBar from "./SideBar";
import Header from "./Header";
import "./dashboard.css";
import Footer from "./Footer";
import "./resetpassword.css";
import {
  amount,
  bus,
  carRental,
  cashAdvance,
  claimno,
  flight,
  hoteldata,
  perdiem,
  perdiemextra,
  requesteid,
  taxi,
  train,
  travelHeader,
} from "../redux/actions";
import PersonImage from "./PersonImage";
import { getprofileData } from "../services/ApiService";
import { useDispatch } from "react-redux";
function Profile() {
  const [collapsed, setCollapsed] = useState(false);

  const [objData, setObjData] = useState({});
  const dispatch=useDispatch()
  
    useEffect(() => {
      dispatch(bus({}));
      dispatch(cashAdvance({}));
      dispatch(carRental({}));
      dispatch(flight({}));
      dispatch(hoteldata({}));
      dispatch(perdiem({}));
      dispatch(perdiemextra({}));
      dispatch(taxi({}));
      dispatch(train({}));
      dispatch(travelHeader({}));
      dispatch(requesteid(""));
      dispatch(claimno(""));
      dispatch(amount(0));
    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  useEffect(() => {
    getprofileData().then((res) => {
      if (res.responseCode === 200) {
        const data = res.data;
        // delete data["cost_center"];
        setObjData(data);
      }
      else{
        message.error(res.responseMessage)
      }
    });
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
  function KeyValueGrid({ data }) {
    return (
      <Row>
        {Object.keys(data).map((key) => (
          <Col span={8} key={key}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // gap: "1rem",  
                marginBottom: "0.5rem",
              }}
            >
              <span style={{ fontWeight: "700" }}>
                {convertToTitleCase(key)}:
              </span>
              <span>{data[key]}</span>
            </div>
          </Col>
        ))}
      </Row>
    );
  }
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar collapsed={collapsed} id={"1"} expense={false} travel={true}/>
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
         <Header expense={false} travel={true} />
          <div>
            <div style={{ position: "relative" }}>
              <img
              alt="profile"
                src="changepassword.png"
                style={{
                  width: "97%",
                  marginRight: "1rem",
                  marginTop: "1rem",
                  height: "30vh",
                  borderRadius: "15px",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: "10rem",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  width: "85%",
                  display: "block",
                  marginRight: "1.5rem",
                  borderRadius: "20px",
                  padding: "1rem 1rem 1rem 1rem",
                  height: "65vh",
                  overflow: "hidden",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.7rem",
                      }}
                    >
                      <PersonImage />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {sessionStorage.getItem("username")}
                        </span>
                        <span style={{ color: "#DA1F63", fontSize: "13px" }}>
                          {sessionStorage.getItem("designation")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ marginTop: "1rem" }}>
                    <div style={{marginBottom:'1rem'}}>
                    <span style={{ fontSize: "16px", fontWeight: "700" }}>
                            Profile Information
                          </span>
                    </div>
                  
                    <KeyValueGrid data={objData} />

          
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default Profile;
