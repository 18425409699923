import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaFilePdf } from "react-icons/fa6";
import "../../items.css";
import {Button, Modal, message } from "antd";
import pic from "../CostenDemo/_Sticker.png";
import CommonHeader from "./CommonHeader";
import { costenDriveget, downloadFile } from "../../services/ApiService";
import { Spin } from "antd";
const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const ColData = () => {
  const [file, setfile] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [apiCall, setApiCall] = useState();
  const [data, setData] = useState();
  const [responseCode, setresponseCode] = useState();
  const [storageUsed, setStorageUsed] = useState();
  const [imageCount, setimageCount] = useState();
  const [previewlink, setPreviewLink] = useState();
  const [downloadLink, setdownloadLink] = useState();
  const [openpreview, setopenpreview] = useState(false);
  const [modalFileType, setmodalFileType] = useState();
  const [modalFileName, setmodalFileName] = useState();

  const [sortClicked, setSortClicked] = useState("date");
  const [spinning, setSpinning] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [searchD, setsearchD] = useState("");

  var search = (value) => {
  

    setsearchD(value);
  };

  const trigger = (value) => {
    setApiCall(value);
  };
  const dataSort = (value) => {
    setSortClicked(value);
  };
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const downloadImage = () => {
    let header={
      "url":previewlink
    }
    downloadFile(header).then((res)=>{
      console.log(res)
    })
    // const downloadLink =
    //   "https://proclozstorage.blob.core.windows.net/internaldata/Sample%20Car%20Rental%20Bill_20240501082149_cfca8d4d-0689-4945-95d2-0fae70ca3bdc.jpeg";

    // const anchor = document.createElement("a");
    // anchor.href = downloadLink;
    // anchor.target = "_blank";
    // anchor.rel = "noopener noreferrer";

    // document.body.appendChild(anchor);
    // anchor.click();
    // document.body.removeChild(anchor);
  };

  const handlechange = (item) => {
    setPreviewLink(item.previewLink);
    setdownloadLink(item.downloadLink);
    setmodalFileType(getFileExtension(item.previewLink));
    setmodalFileName(item.originalFileName);
    setopenpreview(true);
  };

  function getFileExtension(url) {
    const path = url.substring(url.lastIndexOf("/") + 1);
    const extension = path.substring(path.lastIndexOf(".") + 1);
    return extension;
  }
  const previewStyle = {
    maxWidth: "100%",
    maxHeight: "400px",
    margin: "20px 0",
  };

  const imageUrl = downloadLink;


  useEffect(() => {
    setSpinning(true);
    let headers
    if (sortClicked == "date") {
    headers = {
        organization: sessionStorage.getItem("organization"),
        employeeId: sessionStorage.getItem("employeeId"),
        sortBy: "date",
      };
    } else if (sortClicked == "name") {
      headers = {
        organization: sessionStorage.getItem("organization"),
        employeeId: sessionStorage.getItem("employeeId"),
        sortBy: "name",
      };
    } else if (sortClicked == "search") {
      headers = {
        organization: sessionStorage.getItem("organization"),
        employeeId: sessionStorage.getItem("employeeId"),
        searchName: searchD,
      };
    } else {
      headers = {
        organization: sessionStorage.getItem("organization"),
        employeeId: sessionStorage.getItem("employeeId"),
      };
    }
    costenDriveget(headers).then((responseData) => {
      if (responseData.responseCode === 200) {
        setSpinning(false);
        setData(responseData.data);
        setresponseCode(responseData.responseCode);
        setStorageUsed(responseData.response);
        setStorageUsed(responseData.storageUsed);
        setimageCount(responseData.count);
      } else {
        setSpinning(false);
        message.error(responseData.responseMessage);
      }
    });
  }, [apiCall, sortClicked, searchD]);
  return (
    <div className=" ">
      <CommonHeader
      toggle="grid"
        id="1"
        totalImage={imageCount}
        storage={storageUsed}
        trigger={trigger}
        dataSort={dataSort}
        selectedIds={selectedIds}
        search={search}
      />
      <Spin spinning={spinning}>
        {/* <div className="grid  grid-cols-4 xl:w-76 md:h-56 lg:h-32 xl:h-52 scroll-m-3 scrollable-container"> */}
        <div className="grid  grid-cols-4  scrollable-container" style={{height:"40vh"}}>
         
          {/* card code start */}
          {data ? (
            data.map((item, index) => {
              return (
                <div>
                  <div
                    className="  bg-slate-200 m-0 mt-2 ml-0 mr-0  p-8   w-40 h-38 "
                    onClick={() => {
                      handlechange(item);
                    }}
                    style={{ cursor: "pointer" }}
                    key={index}
                  >
                    {getFileExtension(item.previewLink) === "pdf" ? (
                     <FaFilePdf className="h-36 w-40 shadow-sm text-center  flex justify-center" />
                    ) : (
                      <img
                        src={item.previewLink}
                        alt="idk - Highvyn, Taylor Shin"
                        className=" w-40 h-36  ml-1 rounded-sm"
                      />
                    )}
                  </div>
                  <div className=" mb-4 bottom-0  rounded-lg flex w-56 justify-between bg-white ">
                    <div className="flex shadow-sm ">
                      <img
                        src={pic}
                        alt="img"
                        className="h-10 w-10 shadow-sm"
                      />
                      <h1 className="text-start text-sm  ml-2 text-black font-normal">
                        {item.originalFileName}
                      </h1>
                    </div>

                    <input
                      type="checkbox"
                      id={`checkbox-${item.id}`}
                      value={item.id}
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <> </>
          )}

          {/* card code end here */}
        </div>
      </Spin>
      {openpreview ? (
        <Modal
          title={modalFileName}
          centered={false}
          // style={{ top: "90px" }}
          open={openpreview}
          onCancel={() => setopenpreview(false)}
          // footer={<Button onClick={downloadImage} style={{ backgroundColor: "#7A73FF", color: "white" }}>Download Image</Button>}
         footer={false}
          width={800}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            {modalFileType === "pdf" ? (
              <iframe
                src={previewlink}
                title="PDF Preview"
                width="100%"
                height="450px"
              />
            ) : (
              <img src={previewlink} alt="Preview" style={previewStyle} />
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ColData;
