import React from 'react';
import Chart from 'react-apexcharts';

const LineChart =  ({data }) => {
  const categories = data.map(item => item.month);
  const values = data.map(item => item.count);
  const chartOptions = {
    chart: {
      id: 'basic-line',
      toolbar: {
        show: false,
      },
      offsetY: -5,
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: 'Expense Count By Month',
      align: 'centre'
    },


    stroke: {
      curve: 'smooth'
    },
    
    xaxis: {
      categories: categories,
      labels: {
        show: true, // Hide labels on the X-axis
      },
    },
    yaxis: {
      show: false, // Hide the Y-axis
    },
    grid: {
      show: false, // Hide grid lines
    },
    dataLabels: {
      enabled: true, // Set to true to display labels on data points
    },
    // dataLabels: {
    //   enabled: true,
    //   style: {
    //     colors: ['#000'], // Set the color to black
    //   },
    // },
  };

  const chartSeries = [
    {
      name: 'Expense',
      data: values,
    },
  ];

  return (
    <div>
      <Chart options={chartOptions} series={chartSeries} type="line" height={200} />
    </div>
  );
};

export default LineChart;
