import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Footer";
import DatePicker from "react-datepicker";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  message,
} from "antd";
import SideBar from "../../SideBar";
import Header from "../../Header";
import "../../datepicker.css";
import { submitexpenseadd } from "../../../redux/actions";
import { runes } from "runes2";
import { claimno } from "../../../redux/actions";
import "./submitreceipt.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FileTextOutlined, UploadOutlined } from "@ant-design/icons";
import {
  ClaimcancelRequest,
  PreviewFile,
  costenDriveget,
  currencyList,
  exchangeRate,
  ocr,
  submitReceiptGet,
  submitReceiptPost,
} from "../../../services/ApiService";
import pic from "../../CostenDemo/_Sticker.png";

const { TextArea } = Input;
function formatDate(date) {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return "";
}
function SubmitReceiptForm() {
  const { state } = useLocation();
  const id = state?.id;
  const categoryFromMain = state?.category ? state.category : "";
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const yourData = queryParams.get("data");
  const [claimNo, setClaimNo] = useState();
  const [modalFile, setModalFile] = useState(null);
  const [modalFileType, setModalFileType] = useState("");
  const [file, setFile] = useState(null);
  const [openpreview, setOpenpreview] = useState(false);
  const [declaration, setDeclaration] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [billDate, setBillDate] = useState();
  const allowedFormats = ["pdf", "jpeg", "jpg", "heic", "png"];
  const [spinner, setSpinner] = useState(false);
  const [spinText, setSpinText] = useState("");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isFirstRun = useRef(true);
  const { getFieldValue, setFieldsValue } = form;
  const categoryForTags = yourData ? yourData : categoryFromMain;
  const [currencyListData, setCurrencyListData] = useState();
  const [receiptUploaded, setReceiptUploaded] = useState(false);
  const navigate = useNavigate();
  const reduxclaimno = useSelector((state) => state.claimno);
  const [billFile, setBillFile] = useState();
  const [billFileOriginal, setBillFileOriginal] = useState();
  const costCenterapp = sessionStorage.getItem("costCenter");
  const [finalAmountOnset, setFinalAmountOnset] = useState();
  const [perEmployeeCostOnset, setPerEmployeeCostOnset] = useState();
  const [previewlink, setPreviewLink] = useState();
  const [billFileName, setBillFileName] = useState();
  const [costenModal, setCostenModal] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const formattedDateTime = `${day}${month}${year}${hours}${minutes}${seconds}`;
    const full = "CN" + formattedDateTime;
    if (!reduxclaimno) {
      dispatch(claimno(full));
    }
    setClaimNo(full);
    form.setFieldsValue({
      category: yourData ? yourData : categoryFromMain,
      department: sessionStorage.getItem("department"),
      costCenter: sessionStorage.getItem("costCenter"),
    });
  }, []);
  useEffect(() => {
    Modal.info({
      content: (
        <span style={{ fontWeight: "600" }}>
          Please upload your receipt before filling any data, and system will
          fill the data on your behalf. Later you can validate the same
        </span>
      ),
      centered: true,
    });
  }, []);
  useEffect(() => {
    if (id) {
      submitReceiptGet(id).then((res) => {
        if (res.responseCode === 200) {
          setTags(res.data?.teamName);
          form.setFieldsValue({
            category: res.data.category,
            billAmount: res.data.billAmount,
            billCurrency: res.data.billCurrency,
            expenseType: res.data.expenseType,
            billNumber: res.data.billNumber,
            billDate:
              res.data.billDate !== null ? new Date(res.data.billDate) : null,
            exchangeRate: res.data.exchangeRate,
            finalAmount: res.data.finalAmount,
            establishmentName: res.data.establishmentName,
            department: res.data.department,
            costCenter: res.data.costCenter
              ? res.data.costCenter
              : costCenterapp,
            remark: res.data.remark,
            perEmployeeCost: res.data.perEmployeeCost,
          });

          setBillDate(
            res.data.billDate !== null ? new Date(res.data.billDate) : null
          );
          setAgreed(res.data.selfDeclaration === 1 ? true : false);
          setBillFile(res.data.fileName);
          setBillFileOriginal(res.data.originalFileName);
          if (res.data.fileName) {
            setReceiptUploaded(true);
          }
        } else {
          // message.error(res.responseMessage);
        }
      });
    }
  }, [id]);
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const handleBillAmountChange = (value) => {
    const exchangeRate = getFieldValue("exchangeRate");
    const finalAmount = exchangeRate * value;
    setFieldsValue({ finalAmount });
    setFinalAmountOnset(finalAmount);
  };
  const handleExchangeRateChange = (value) => {
    const billAmount = getFieldValue("billAmount");
    const finalAmount = billAmount * value;
    setFieldsValue({ finalAmount });
    setFinalAmountOnset(finalAmount);
  };

  useEffect(() => {
    currencyList().then((res) => {
      if (res.responseCode === 200) {
        const arr = res.data;
        arr.push("Other");
        setCurrencyListData(arr);
      } else {
        message.error(res.responseMessage);
      }
    });
    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const handleDateChange = (date) => {
    setBillDate(date);
  };
  const onFileChange = (e) => {
    setReceiptUploaded(true);
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop().toLowerCase();

      if (allowedFormats.includes(fileType)) {
        const ocrform = new FormData();

        ocrform.append("file", selectedFile);
        setSpinText("Fetching Data ...");
        setSpinner(true);
        ocr(ocrform)
          .then((res) => {
            if (res.responseCode === 200) {
              setSpinner(false);
              form.setFieldsValue({
                billAmount: res.fileData.billAmount,
                billDate:
                  res.fileData.billDate !== null
                    ? new Date(res.fileData?.billDate)
                    : null,
                billNumber:
                  res?.fileData?.billNumber === null
                    ? ""
                    : res?.fileData?.billNumber,
                establishmentName: res.fileData.establishmentName,
              });

              setBillDate(
                res.fileData.billDate !== null
                  ? new Date(res.fileData?.billDate)
                  : null
              );
            } else {
              setSpinner(false);
              message.error(res.responseMessage);
            }
          })
          .catch((error) => {
            setSpinner(false);
            message.error(error);
          });

        form.setFieldValue({
          file: selectedFile,
        });
        setFile(selectedFile);
        setModalFile(selectedFile);
        setModalFileType(fileType);
        // setFileType(fileType);
      } else {
        message.info("Accepted files formats : JPEG, JPG, PDF, HEIC, PNG.")

        // alert(
        //   "Invalid file format. Please upload a PDF, JPEG, JPG, or HEIC file."
        // );
      }
    }
  };
  const previewStyle = {
    maxWidth: "100%",
    maxHeight: "400px",
    margin: "20px 0",
  };
  const onFinish = (value) => {
    setSpinText("Saving ...");
    value.billDate = formatDate(value.billDate);
    if(!value.expenseType){
      value.expenseType="cash"
    }
    if (file) {
      value["file"] = file;
    } else if (billFile) {
      value["fileName"] = billFile;
      value["originalFileName"] = billFileOriginal;
    }
    if (tags?.length > 0) {
      value["teamName"] = tags;
    }
    value["billAmount"] = parseFloat(value.billAmount);
    value["selfDeclaration"] = agreed === false ? 0 : 1;
    if (perEmployeeCostOnset) {
      value["perEmployeeCost"] = perEmployeeCostOnset;
    }
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        value[key] = value[key] !== undefined ? value[key] : "";
      }
    }

    setSpinner(true);
    
    if (
      Object.values(value).every(
        (field) =>
          field !== "" &&
          isNaN(value["billAmount"]) === false &&
          isNaN(value["exchangeRate"]) === false &&
          isNaN(value["finalAmount"]) === false
      )
    ) {
      const formDatatry = new FormData();
      Object.entries(value).forEach(([key, value]) => {
        formDatatry.append(`${key}`, value);
      });
      if (id) {
        formDatatry.append("id", id);
      }
      formDatatry.append("claimNumber", reduxclaimno ? reduxclaimno : claimNo);
      formDatatry.append("empId", sessionStorage.getItem("employeeId"));
      submitReceiptPost(formDatatry).then((res) => {
        if (res.responseCode === 200) {
          setSpinner(false);
          message.success("Saved");
          navigate("/main-page");
        } else {
          setSpinner(false);
          message.error(res.responseMessage);
        }
      });
    } else {
      setSpinner(false);
      message.error("All Fields are required");
    }

    dispatch(submitexpenseadd([]));
  };
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }

    setInputVisible(false);
    setInputValue("");
  };

  const onCancel = () => {
    let body = {
      claimNumber: reduxclaimno,
    };
    ClaimcancelRequest(body).then((res) => {
      if (res.responseCode === 200) {
        message.success("Request Canceled Successfully");
        navigate("/dashboard-expense");
      } else {
        message.error(res.responseMessage);
      }
    });
  };
  const handleTagClose = (removedTag) => {
    const updatedTags = tags.filter((tag) => tag !== removedTag);
    setTags(updatedTags);
  };
  const handleBillCurrency = (value) => {
    exchangeRate(value, sessionStorage.getItem("currency")).then((res) => {
      if (res.responseCode === 200) {
        form.setFieldsValue({
          exchangeRate: res.exchangeRate,
        });
        const billAmount = getFieldValue("billAmount");
        const finalAmount = res.exchangeRate * billAmount;
        setFieldsValue({ finalAmount });
        setFinalAmountOnset(finalAmount);
      } else {
        message.error(res.responseMessage);
      }
    });
  };
  const handleDownloadClick = (backendLink) => {
    window.open(backendLink, "_blank");
  };
  useEffect(() => {
    const finalAmount = getFieldValue("finalAmount");
    if (tags?.length > 0 && finalAmount) {
      const finalAmount = getFieldValue("finalAmount");
      const perEmployeeCost = finalAmount / tags.length;
      setFieldsValue({ perEmployeeCost });
      setPerEmployeeCostOnset(perEmployeeCost);
    }
  }, [tags, finalAmountOnset]);
  function getFileExtension(url) {
    const path = url.substring(url.lastIndexOf("/") + 1);
    const extension = path.substring(path.lastIndexOf(".") + 1);
    return extension;
  }
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);

  const handleCheckboxChange = (itemId) => {
    // When a checkbox is clicked, update the selectedItemId state
    setSelectedItemId(itemId);

    // Find the details of the selected item and update the state
    const selectedItem = data.find((item) => item.id === itemId);
    setSelectedItemDetails(selectedItem);
  };

  const handleUpload = () => {
    if (selectedItemDetails) {
      const ocrform = new FormData();

      ocrform.append("file", selectedItemDetails.previewLink);
      setCostenModal(false);
      setSpinner(true);
      setFile(null)
      setBillFileOriginal(selectedItemDetails.originalFileName);
      setBillFile(selectedItemDetails.fileName);
    if(selectedItemDetails.fileName){
      setReceiptUploaded(true)
    }
      ocr(ocrform)
      .then((res) => {
        if (res.responseCode === 200) {
          setSpinner(false);
          form.setFieldsValue({
            billAmount: res.fileData.billAmount,
            billDate:
              res.fileData.billDate !== null
                ? new Date(res.fileData?.billDate)
                : null,
            billNumber:
              res?.fileData?.billNumber === null
                ? ""
                : res?.fileData?.billNumber,
            establishmentName: res.fileData.establishmentName,
          });

          setBillDate(
            res.fileData.billDate !== null
              ? new Date(res.fileData?.billDate)
              : null
          );
        } else {
          setSpinner(false);
          message.error(res.responseMessage);
        }
      })
      .catch((error) => {
        setSpinner(false);
        message.error(error);
      });
    } else {
      message.info("Select before clicking Upload");
    }
  };

  const [data, setData] = useState([]);

  const handleCostenDriveModal = () => {
    setCostenModal(true);
    const headers = {
      organization: sessionStorage.getItem("organization"),
      employeeId: sessionStorage.getItem("employeeId"),
    };
    costenDriveget(headers).then((responseData) => {
      if (responseData.responseCode === 200) {
        setData(responseData.data);
      } else {
        message.error(responseData.responseMessage);
      }
    });
  };
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    // Filter data based on search query whenever searchQuery changes
    const filtered = data.filter(item =>
      item.originalFileName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, searchQuery]);
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 4 : 2}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={true}
            travel={false}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 20 : 22}
        >
          <div style={{ marginLeft: "1.5rem" }}>
            <Header expense={true} travel={false} />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5rem",
                marginBottom: "0.1rem",
                marginTop: "1.5rem",

                justifyContent: "space-between",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.5rem",
                    marginLeft: "1.4rem",
                    // marginTop:'1rem'
                    marginBottom: "0.3rem",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>Claim No:</span>
                  <span style={{ color: "#3052D0", fontWeight: "500" }}>
                    {reduxclaimno ? reduxclaimno : claimNo}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "0.3rem",
                    // marginRight: "2.7rem",
                    marginLeft: "1.4rem",
                    // marginBottom: "0.5rem",
                  }}
                >
                  <span style={{ fontWeight: "700" }}>Status : </span>
                  <div
                    style={{
                      color: "#4CAF50",
                      padding: "1px 3px 1px 3px",
                      borderRadius: "4px",
                      backgroundColor: " rgba(76, 175, 80, 0.2)",
                    }}
                  >
                    Initiated
                  </div>
                </div>
              </div>

              <div style={{ marginRight: "3rem" }}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <label
                          style={{
                            fontWeight: "600",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() => handleCostenDriveModal()}
                        >
                          Upload from Costen Drive
                        </label>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <label
                          style={{
                            fontWeight: "600",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <input
                            type="file"
                            accept=".pdf, .jpeg, .jpg, .heic, .png"
                            onChange={onFileChange}
                            style={{ display: "none" }}
                          />
                          Upload from PC
                        </label>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <label
                    style={{
                      width: "100%",
                      height: "33px",
                      backgroundColor: "#7A73FF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "5px",
                      cursor: "pointer",
                      padding: "0 0.5rem 0 0.5rem",
                    }}
                  >
                    <UploadOutlined
                      style={{
                        color: "white",
                        fontSize: "15px",
                        marginTop: "0.1rem",
                        marginRight: "0.2rem",
                      }}
                    />

                    <span style={{ color: "white" }}>Upload a Receipt</span>
                  </label>
                </Dropdown>
                {file ? (
                  <div
                    onClick={() => {
                      setModalFile(file);
                      setModalFileType(
                        file?.name.split(".").pop().toLowerCase()
                      );
                      setOpenpreview(true);
                    }}
                    style={{
                      // marginTop: "1.2rem",
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                      width: "15px",
                      marginTop: "0.1rem",
                    }}
                  >
                    <FileTextOutlined style={{ fontSize: "15px" }} />
                    <span> {file?.name.slice(0, 18)}...</span>
                  </div>
                ) : billFile ? (
                  <div
                    onClick={() => {
                      PreviewFile(billFile).then((res) => {
                        setPreviewLink(res.url);
                        setBillFileName(billFileOriginal);
                        setModalFileType(getFileExtension(res.url));
                        setModalFile(null)
                        setOpenpreview(true);
                      });
                    }}
                    style={{
                      marginTop: "0.1rem",
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer",
                    }}
                  >
                    <FileTextOutlined style={{ fontSize: "15px" }} />
                    <span> {billFileOriginal}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              style={{
                overflow: "auto",
                backgroundColor: "white",
                margin: "1rem 1.5rem 0 0",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "5px",
                padding: "0.4rem 1rem 1rem 1rem",
                flexDirection: "column",
                height: "65vh",
              }}
            >
              <Spin spinning={spinner} tip={spinText} size="large">
                <Form onFinish={onFinish} form={form} layout="horizontal">
                  <div
                    style={{
                      marginTop: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        height: "50vh",
                        borderRadius: "10px",
                        marginBottom: "1rem",
                      }}
                      className="scrollable-container"
                    >
                      <Row gutter={15}>
                        <Col lg={5}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Category:
                            </label>
                            <Form.Item name="category">
                              <Input disabled placeholder="Enter Category" />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Requested Currency:
                            </label>
                            {/* <Form.Item name="requestedCurrency"> */}
                            <Input
                              disabled
                              value={sessionStorage.getItem("currency")}
                              placeholder="Enter Requested Currency"
                            />
                            {/* </Form.Item> */}
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Expense Type:
                            </label>
                            <Form.Item name="expenseType">
                              <Select
                                defaultValue="cash"
                                placeholder="Select Expense Type"
                              >
                                <Select.Option key="cash" value="cash">
                                  Cash
                                </Select.Option>
                                <Select.Option
                                  key="corporateCard"
                                  value="corporateCard"
                                >
                                  Corporate Card
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Bill Number:
                            </label>
                            <Form.Item name="billNumber">
                              <Input placeholder="Enter Bill Number" />
                            </Form.Item>
                          </div>
                        </Col>{" "}
                        <Col lg={6}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Bill Date:
                            </label>
                            <Form.Item name="billDate">
                              <DatePicker
                                className="inputclassreceipt"
                                selected={billDate}
                                onChange={(date) => handleDateChange(date)}
                                // className="form-control"
                                placeholderText="Select Bill Date"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={15}>
                        <Col lg={5}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Bill Currency:
                            </label>
                            <Form.Item name="billCurrency">
                              <Select
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                onChange={(value) => handleBillCurrency(value)}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                placeholder="Select Bill Currency"
                                virtual={true}
                              >
                                {currencyListData?.map((option) => (
                                  <Select.Option key={option} value={option}>
                                    {option}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Bill Amount:
                            </label>
                            <Form.Item name="billAmount">
                              <input
                                style={{ width: "90%" }}
                                onChange={(e) =>
                                  handleBillAmountChange(e.target.value)
                                }
                                className="inputclass"
                                type="text"
                                placeholder="Enter Bill Amount"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Exchange Rate:
                            </label>
                            <Form.Item name="exchangeRate">
                              <Input
                                onChange={(e) =>
                                  handleExchangeRateChange(e.target.value)
                                }
                                placeholder="Enter Exchange Rate"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Final Amount:
                            </label>
                            <Form.Item name="finalAmount">
                              <Input
                                disabled
                                placeholder="Enter Final Amount"
                              />
                            </Form.Item>
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label style={{ fontWeight: "600" }}>
                                Establishment Name:
                              </label>
                              <Form.Item name="establishmentName">
                                <Input
                                  style={{ width: "93%" }}
                                  placeholder="Enter Establishment Name"
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={15}>
                        <Col lg={5}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label style={{ fontWeight: "600" }}>
                                Cost Center:
                              </label>
                              <Form.Item
                                className="custom-form-item"
                                name="costCenter"
                              >
                                <Input placeholder="Enter Cost Center" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginBottom: "1rem",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label style={{ fontWeight: "600" }}>
                                Department:
                              </label>
                              <Form.Item
                                className="custom-form-item"
                                name="department"
                              >
                                <Input
                                  disabled
                                  placeholder="Enter Department"
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        {categoryForTags?.split("-")[1] ==
                          "Team Lunch/Dinner" && (
                          <Col lg={5}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "1rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <label style={{ fontWeight: "600" }}>
                                  Per Employee Cost:
                                </label>
                                <Form.Item name="perEmployeeCost">
                                  <Input
                                    disabled
                                    placeholder="Enter Per Employee Cost"
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </Col>
                        )}
                        {categoryForTags?.split("-")[1] ==
                          "Team Lunch/Dinner" && (
                          <Col lg={9}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label style={{ fontWeight: "600" }}>
                                Team/Employee Name
                              </label>
                              <div
                                style={{
                                  marginTop: "0.2rem",
                                  height: "15vh",
                                  borderRadius: "0px",
                                }}
                                className="scrollable-container"
                              >
                                {tags?.map((tag, index) => (
                                  <Tag
                                    key={index}
                                    closable
                                    onClose={() => handleTagClose(tag)}
                                  >
                                    {tag}
                                  </Tag>
                                ))}
                                {inputVisible && (
                                  <Input
                                    type="text"
                                    size="middle"
                                    style={{ width: "150px" }}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onBlur={handleInputConfirm}
                                    onPressEnter={handleInputConfirm}
                                  />
                                )}
                                {!inputVisible && (
                                  <Button
                                    size="small"
                                    type="dashed"
                                    onClick={showInput}
                                  >
                                    + Add Employee Name
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col lg={12}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <label style={{ fontWeight: "600" }}>
                                Remark:
                              </label>
                              <Form.Item
                                className="custom-form-item"
                                name="remark"
                              >
                                <TextArea
                                  // count={{
                                  //   show: true,
                                  //   max: 1000,
                                  //   strategy: (txt) => runes(txt).length,
                                  //   exceedFormatter: (txt, { max }) =>
                                  //     runes(txt).slice(0, max).join(""),
                                  // }}
                                  className="custom-radio-group"
                                  rows={3}
                                  placeholder="Please give remark if any"
                                  autoSize={{ minRows: 3, maxRows: 3 }}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <Link
                      onClick={() => setDeclaration(true)}
                      style={{ color: "#E93B77", textDecoration: "underline" }}
                    >
                      *Click here in case of no bill
                    </Link>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      style={{
                        width: "8.5rem",
                        backgroundColor: "darkgray",
                        border: "none",
                        color: "white",
                        marginRight: "1.5rem",
                      }}
                      onClick={() => navigate("/main-page")}
                    >
                      Back
                    </Button>
                    <Button
                      disabled={agreed ? false : receiptUploaded ? false : true}
                      style={{
                        width: "8.5rem",
                        backgroundColor: "#3052D0",
                        border: "none",
                        color: "white",
                        marginRight: "1.5rem",
                      }}
                      htmlType="submit"
                    >
                      Save
                    </Button>

                    {/* <Button
                      style={{
                        width: "8.5rem",
                        backgroundColor: "transparent",
                        border: "1px solid red",
                        color: "red",
                      }}
                      onClick={()=>{onCancel()}}
                    >
                      Cancel
                    </Button> */}
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </Col>
      </Row>
      <Footer />
      <Modal
        styles={{ padding: "10px" }}
        centered={true}
        style={{ padding: "50px" }}
        open={declaration}
        onCancel={() => {
          setDeclaration(false);
          // setAgreed(false);
        }}
        footer={[
          <>
            <Checkbox
              style={{ marginRight: "1rem", fontWeight: "500" }}
              checked={agreed}
              onChange={(e) => setAgreed(e.target.checked)}
            >
              I Agree
            </Checkbox>
            <Button
              style={{ backgroundColor: "#3052D0" }}
              disabled={!agreed}
              key="submit"
              type="primary"
              onClick={() => setDeclaration(false)}
            >
              Submit Declaration
            </Button>
          </>,
        ]}
      >
        <div>
          <span style={{ fontWeight: "500" }}>
            I am formally seeking a reimbursement for services that I have
            availed of, although I lack an accompanying invoice.
            <br /> I affirm unequivocally that I have made use of the services
            in accordance with the agreed terms, and I am confident regarding
            the precise amount eligible for reimbursement. Regrettably, a bill
            or receipt cannot be submitted alongside this request, as the vendor
            did not provide one.
            <br /> I accept full responsibility for ensuring that the requested
            amount was utilized solely for business-related purposes.
          </span>
        </div>
      </Modal>
      {openpreview ? (
        <Modal
          title={modalFile ? modalFile.name : billFileName}
          centered={false}
          style={{ top: "0px" }}
          open={openpreview}
          onCancel={() => setOpenpreview(false)}
          footer={false}
          width={800}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            {modalFileType === "pdf" ? (
              <iframe
                src={modalFile ? URL.createObjectURL(modalFile) : previewlink}
                title="PDF Preview"
                width="100%"
                height="450px"
              />
            ) : (
              <img
                src={modalFile ? URL.createObjectURL(modalFile) : previewlink}
                alt="Preview"
                style={previewStyle}
              />
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
      <Modal
        // title={modalFile ? modalFile.name : billFileName}
        centered={false}
        style={{ top: "0px" }}
        open={costenModal}
        // title="Are you sure, want to Cancel the whole request"
        onCancel={() => {
          setCostenModal(false);
          setSelectedItemId(null);
          setSelectedItemDetails(null)
        }}
        footer={<Button className="bg-[#3052D0] text-white ml-5 mt-0 font-medium"  onClick={handleUpload}>Upload</Button>}

        width={1000}
      >
       <div>
      {/* Search input */}
      <input
      className="inputclass"
        type="text"
        placeholder="Search by filename..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ padding: '5px', borderRadius:"15px",height:"5vh" }}
      />

      {/* Display filtered data */}
      <div className="grid grid-cols-4 h-96 md:h-96 scroll-m-3 scrollable-container mt-4">
        {filteredData.map((item, index) => (
          <div key={index}>
            <div
              className="bg-slate-200 m-0 mt-2 ml-0 mr-0 p-8 w-40 h-38"
             
              style={{ cursor: 'pointer' }}
            >
              {getFileExtension(item.previewLink) === 'pdf' ? (
                <iframe
                  src={item.previewLink}
                  title="PDF Preview"
                  className="w-40 h-36 ml-1 rounded-sm"
                  style={{ overflow: 'hidden' }}
                />
              ) : (
                <img
                  src={item.previewLink}
                  alt="Preview"
                  className="w-40 h-36 ml-1 rounded-sm"
                />
              )}
            </div>
            <div className="mb-4 bottom-0 rounded-lg flex w-56 justify-between bg-white">
              <div className="flex shadow-sm">
                <img
                  src={pic}
                  alt="img"
                  className="h-10 w-10 shadow-sm"
                />
                <h1 className="text-start text-sm ml-2 text-black font-normal">
                  {item.originalFileName}
                </h1>
              </div>
              <input
                type="checkbox"
                id={`checkbox-${item.id}`}
                value={item.id}
                checked={selectedItemId === item.id}
                onChange={() => handleCheckboxChange(item.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
      </Modal>
    </div>
  );
}

export default SubmitReceiptForm;
