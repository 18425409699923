import { FloatButton } from "antd";
import React, { useEffect } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

function Footer() {
  const location = useLocation();

  useEffect(() => {
  }, [location]);
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {location.pathname === "/dashboard" ||
      location.pathname === "/dashboard-expense" ||
      location.pathname === "/dashboard-m" ? (
        <>
        <a href="https://apps.apple.com/in/app/costen/id6478499329" target="_blank">

     
          <img
            style={{
              position: "fixed",
              bottom: "0.3rem",
              right: "73%",
              width: "6.5rem",
              zIndex: "1",
              marginRight: "1%",
              marginBottom: "0.1rem",
            }}
            src="appstore.png"
          />
             </a>
            <a href="https://play.google.com/store/apps/details?id=com.travelExpense.costen" target="_blank">
          <img
            style={{
              position: "fixed",
              bottom: "0.3rem",
              right: "64%",
              width: "6.5rem",
              zIndex: "1",
              marginRight: "1%",
              marginBottom: "0.1rem",
            }}
            src="googleplay.png"
          />
          </a>
        </>
      ) : (
        <></>
      )}

      {/* <span
            style={{
              position:'fixed',
              color: "#8A8A8A",
              bottom: "0",
              right: "0",
              position: "absolute",
              padding: "1rem",
              fontSize:'12px'
            }}
          >
            © 2023 Costen All Rights Reserved
          </span> */}
      <FloatButton
        onClick={() => {
          navigate("/needhelp");
        }}
        tooltip={<div>Need Help</div>}
        icon={<QuestionCircleOutlined />}
        type="primary"
        style={{
          right: 24,
          bottom: 10,
        }}
      />
      {/* <img style={{position:'fixed',bottom:'0.3rem',right:'0',width:'5rem',zIndex:'1',marginRight:'1%',marginBottom:'2%'}} src='costen.png'/> */}
    </div>
  );
}

export default Footer;
