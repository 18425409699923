import React, { useState } from "react";
import { Col, Row } from "antd";
import SideBar from "./SideBar";
import Header from "./Header";
import "./dashboard.css";
import Footer from "./Footer";
import "./tobeapproved.css";
import { useNavigate } from "react-router-dom";

// const Card = ({ title }) => (
//   <div
//     style={{
//       marginRight: "10px",
//       width: "23vw",
//       height: "30vh",
//       borderRadius: "15px",
//       boxShadow:
//         "10px 10px 10px 0px rgba(174, 174, 192, 0.20) inset, -10px -10px 10px 0px rgba(255, 255, 255, 0.70) inset",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//     }}
//   >
//     <h3 style={{ textAlign: "center" }}>{title}</h3>
//   </div>
// );
function Insights() {
  const [collapsed, setCollapsed] = useState(false);
const navigate= useNavigate()
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const userType = sessionStorage.getItem("userType");
  const aSF = sessionStorage.getItem("aSF");
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar collapsed={collapsed}  id={"2"} expense={false} travel={true} />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={false} travel={true} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "7rem",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  marginRight: "10px",
                  width: "23vw",
                  height: "30vh",
                  borderRadius: "15px",
      
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor:'#65A8D6'
                }}
                onClick={()=>navigate('/insightgraph',{state:{
                  type:"self"
                }})}
              >
                <h3 style={{ textAlign: "center",color:'white',fontWeight:'500' }}>Self-Expense Analysis</h3>
              </div>

              {userType === "1" ? (
                <div
                  style={{
                    marginRight: "10px",
                    width: "23vw",
                    height: "30vh",
                    borderRadius: "15px",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor:'#F48161'
                  }}
                  onClick={()=>navigate('/insightgraph',{state:{
                  type:"direct-report"
                }})}
                >
                  <h3 style={{ textAlign: "center",color:'white',fontWeight:'500' }}>
                    Direct-Report Expense Analysis{" "}
                  </h3>
                </div>
              ) : (
                <></>
              )}
            </div>

            {aSF !== "0" ? (
              <div
                style={{
                  marginRight: "10px",
                  width: "23vw",
                  height: "30vh",
                  borderRadius: "15px",
               
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor:'#5C57C4'
                }}
                onClick={()=>navigate('/insightgraph',{state:{
                  type:"expense-admin"
                }})}
              >
                <h3 style={{ textAlign: "center",color:'white',fontWeight:'500' }}>
                  Expense Analysis for <br /> Expense Admin / Finance
                </h3>
              </div>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default Insights;
