import React, { useEffect, useState } from "react";
import {  Button, Col, Row } from "antd";

import Footer from "../Footer";
import Header from "../Header";
import SideBar from "../SideBar";
import ColData from "./ColData";
import { downloadRecipt } from "../../services/ApiService";

function CostenDrive() {


  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };



//code for download ----- yukta code
//let url = "https://source.unsplash.com/user/c_v_r/1900x800 ";
//let base64Url = btoa(url);



// const downloadImage = () => {

//   // const imageUrl = 'https://proclozstorage.blob.core.windows.net/internaldata/proclozicon.png';
//   const imageUrl="https://picsum.photos/200/300"
//   const fileName = 'downloaded_image.jpg';

//   fetch(imageUrl)
//     .then((response) => response.blob())
//     .then((blob) => {
//       const url = window.URL.createObjectURL(new Blob([blob]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', fileName);
//       document.body.appendChild(link);
//       link.click();
//       link.parentNode.removeChild(link);
//     })
//     .catch((error) => {
//       console.error('Error downloading the image:', error);
//     });
//   }
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar collapsed={collapsed}  id={"6"} expense={false} travel={true}/>
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
          
        >
         <Header expense={false} travel={true} /> 
         <div>
      {/* <button onClick={downloadImage}>Download Image</button> */}
    </div>
         
 <ColData/> 
        </Col>
      </Row>
      <Footer />
   

    </div>
  );
}

export default CostenDrive;
