import React, { useEffect, useState } from "react";
import "./adminsidebar.css";
import { useNavigate } from "react-router-dom";

function ExpenseAdminSideBar({ collapsed, id }) {
  const icon = sessionStorage.getItem("icon");
  const logo = sessionStorage.getItem("logo");
  const navigate = useNavigate();
  const [clickedItemId, setClickedItemId] = useState("1");
  const userType = sessionStorage.getItem("userType");
  const handleItemClick = (itemId) => {
    setClickedItemId(itemId);
  };
  useEffect(() => {
    setClickedItemId(id);
  }, []);
  return (
    <>
      <div className={`sidebaradmin ${collapsed ? "collapsedadmin" : ""}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10%",
          }}
        >
          {collapsed ? (
            <img
              alt="images"
              style={{ width: "48px", marginBottom: "1rem" }}
              src={icon}
            />
          ) : (
            <img
              alt="images"
              style={{ width: "160px" }}
              src={logo}
            />
          )}
        </div>

        {collapsed ? (
          <ul
            style={{ marginTop: "3.5rem" }}
            className="menuadmin-collapsedadmin"
          >
            <li
              id="1"
              onClick={() => {
                handleItemClick("1");

                navigate("/expenseAdmin");
              }}
              // className={"1" === clickedItemId ? "clickedadmin" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "40%",
              }}
            >
              {clickedItemId === "1" ? (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7391 25V12.4783M23.9565 22.913L13.5217 15.6087M23.9565 15.6087L13.5217 22.913M8.30435 11.4348C9.68809 11.4348 11.0151 10.8851 11.9936 9.90664C12.9721 8.92819 13.5217 7.60113 13.5217 6.21739C13.5217 4.83365 12.9721 3.50659 11.9936 2.52814C11.0151 1.54969 9.68809 1 8.30435 1C6.92061 1 5.59355 1.54969 4.6151 2.52814C3.63664 3.50659 3.08696 4.83365 3.08696 6.21739C3.08696 7.60113 3.63664 8.92819 4.6151 9.90664C5.59355 10.8851 6.92061 11.4348 8.30435 11.4348ZM8.30435 11.4348C10.4049 11.4348 12.2403 12.2278 13.5457 13.5457M8.30435 11.4348C4.13043 11.4348 1 14.5652 1 18.7391V23.9565H11.4348"
                    stroke="#3052D0"
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7391 25V12.4783M23.9565 22.913L13.5217 15.6087M23.9565 15.6087L13.5217 22.913M8.30435 11.4348C9.68809 11.4348 11.0151 10.8851 11.9936 9.90664C12.9721 8.92819 13.5217 7.60113 13.5217 6.21739C13.5217 4.83365 12.9721 3.50659 11.9936 2.52814C11.0151 1.54969 9.68809 1 8.30435 1C6.92061 1 5.59355 1.54969 4.6151 2.52814C3.63664 3.50659 3.08696 4.83365 3.08696 6.21739C3.08696 7.60113 3.63664 8.92819 4.6151 9.90664C5.59355 10.8851 6.92061 11.4348 8.30435 11.4348ZM8.30435 11.4348C10.4049 11.4348 12.2403 12.2278 13.5457 13.5457M8.30435 11.4348C4.13043 11.4348 1 14.5652 1 18.7391V23.9565H11.4348"
                    stroke="#1F1F22"
                    strokeWidth="2"
                  />
                </svg>
              )}
            </li>
            <li
              id="2"
              onClick={() => {
                handleItemClick("2");

                navigate("/offboard");
              }}
              // className={"2" === clickedItemId ? "clickedadmin" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "31%",
              }}
            >
              {clickedItemId === "2" ? (
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2857 22.2857H1V21.3565C1.01099 20.3201 1.23024 19.2965 1.64473 18.3466C2.05922 17.3966 2.66047 16.5397 3.41275 15.8268C4.16503 15.1138 5.05298 14.5594 6.02381 14.1965C6.99464 13.8336 8.02852 13.6696 9.064 13.7142C10.0898 13.7628 11.0956 14.0151 12.0227 14.4566C12.9499 14.8981 13.7798 15.5199 14.464 16.2857M14.7143 20.5714H23.2857M8.71429 10.2857C9.85093 10.2857 10.941 9.83413 11.7447 9.03041C12.5485 8.22668 13 7.13659 13 5.99995C13 4.86331 12.5485 3.77322 11.7447 2.96949C10.941 2.16576 9.85093 1.71423 8.71429 1.71423C7.57764 1.71423 6.48756 2.16576 5.68383 2.96949C4.8801 3.77322 4.42857 4.86331 4.42857 5.99995C4.42857 7.13659 4.8801 8.22668 5.68383 9.03041C6.48756 9.83413 7.57764 10.2857 8.71429 10.2857Z"
                    stroke="#3052D0"
                    strokeWidth="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2857 22.2857H1V21.3565C1.01099 20.3201 1.23024 19.2965 1.64473 18.3466C2.05922 17.3966 2.66047 16.5397 3.41275 15.8268C4.16503 15.1138 5.05298 14.5594 6.02381 14.1965C6.99464 13.8336 8.02852 13.6696 9.064 13.7142C10.0898 13.7628 11.0956 14.0151 12.0227 14.4566C12.9499 14.8981 13.7798 15.5199 14.464 16.2857M14.7143 20.5714H23.2857M8.71429 10.2857C9.85093 10.2857 10.941 9.83413 11.7447 9.03041C12.5485 8.22668 13 7.13659 13 5.99995C13 4.86331 12.5485 3.77322 11.7447 2.96949C10.941 2.16576 9.85093 1.71423 8.71429 1.71423C7.57764 1.71423 6.48756 2.16576 5.68383 2.96949C4.8801 3.77322 4.42857 4.86331 4.42857 5.99995C4.42857 7.13659 4.8801 8.22668 5.68383 9.03041C6.48756 9.83413 7.57764 10.2857 8.71429 10.2857Z"
                    stroke="#1F1F22"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </li>
            <li
              id="3"
              onClick={() => {
                handleItemClick("3");
                setClickedItemId("3");
                navigate("/update");
              }}
              // className={"3" === clickedItemId ? "clickedadmin" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "30%",
              }}
            >
              {clickedItemId === "3" ? (
                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3972 15.7233C22.1754 13.8737 22.3544 11.8267 21.9091 9.87013C21.4638 7.91353 20.4166 6.14565 18.9146 4.815C17.4127 3.48435 15.5314 2.65783 13.5354 2.45161C11.5394 2.24539 9.52898 2.66985 7.78668 3.66532L6.59528 1.58127C8.41602 0.540402 10.4779 -0.00481012 12.5751 3.19732e-05C14.6724 0.00487407 16.7317 0.559601 18.5476 1.60886C23.9347 4.71994 25.9984 11.3788 23.4884 16.9303L25.0985 17.8601L20.1002 20.5164L19.9022 14.8606L21.3972 15.7233ZM3.70017 8.27733C2.92168 10.1271 2.74247 12.1743 3.18776 14.1311C3.63304 16.0879 4.68042 17.856 6.18264 19.1868C7.68485 20.5175 9.56636 21.344 11.5626 21.55C13.5589 21.756 15.5695 21.3312 17.3119 20.3353L18.5021 22.4193C16.6812 23.46 14.6193 24.005 12.522 24C10.4248 23.9949 8.3655 23.44 6.54969 22.3905C1.1626 19.2795 -0.901047 12.6218 1.61013 7.06914L0 6.14169L4.99715 3.48415L5.19512 9.13999L3.70017 8.27733ZM14.2464 15.3933L10.8522 12.0003L7.45913 15.3933L5.76142 13.6968L10.8522 8.60607L14.2452 12.0003L17.6394 8.60607L19.3359 10.3038L14.2464 15.3933Z" fill="#3052D0"/>
</svg>

              ) : (
                <svg
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3972 15.7233C22.1754 13.8737 22.3544 11.8267 21.9091 9.87013C21.4638 7.91353 20.4166 6.14565 18.9146 4.815C17.4127 3.48435 15.5314 2.65783 13.5354 2.45161C11.5394 2.24539 9.52898 2.66985 7.78668 3.66532L6.59528 1.58127C8.41602 0.540402 10.4779 -0.00481012 12.5751 3.19732e-05C14.6724 0.00487407 16.7317 0.559601 18.5476 1.60886C23.9347 4.71994 25.9984 11.3788 23.4884 16.9303L25.0985 17.8601L20.1002 20.5164L19.9022 14.8606L21.3972 15.7233ZM3.70017 8.27733C2.92168 10.1271 2.74247 12.1743 3.18776 14.1311C3.63304 16.0879 4.68042 17.856 6.18264 19.1868C7.68485 20.5175 9.56636 21.344 11.5626 21.55C13.5589 21.756 15.5695 21.3312 17.3119 20.3353L18.5021 22.4193C16.6812 23.46 14.6193 24.005 12.522 24C10.4248 23.9949 8.3655 23.44 6.54969 22.3905C1.1626 19.2795 -0.901047 12.6218 1.61013 7.06914L0 6.14169L4.99715 3.48415L5.19512 9.13999L3.70017 8.27733ZM14.2464 15.3933L10.8522 12.0003L7.45913 15.3933L5.76142 13.6968L10.8522 8.60607L14.2452 12.0003L17.6394 8.60607L19.3359 10.3038L14.2464 15.3933Z"
                    fill="#1F1F22"
                  />
                </svg>
              )}
            </li>
            <li
              id="6"
              onClick={() => {
                handleItemClick("6");
                setClickedItemId("6");
                navigate("/abandonreq");
              }}
              // className={"6" === clickedItemId ? "clickedadmin" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "30%",
              }}
            >
              {clickedItemId === "6" ? (
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.1618 9.02075V8.92075H25.0618H8.29102H8.19102V9.02075V10.4791V10.5791H8.29102H25.0618H25.1618V10.4791V9.02075Z" fill="#3052D0" stroke="#3052D0" strokeWidth="0.2"/>
<path d="M25.1618 12.6667V12.5667H25.0618H8.29102H8.19102V12.6667V14.1251V14.2251H8.29102H25.0618H25.1618V14.1251V12.6667Z" fill="#3052D0" stroke="#3052D0" strokeWidth="0.2"/>
<path d="M14.1514 16.3125V16.2125H14.0514H8.29102H8.19102V16.3125V17.7708V17.8708H8.29102H14.0514H14.1514V17.7708V16.3125Z" fill="#3052D0" stroke="#3052D0" strokeWidth="0.2"/>
<path d="M14.1514 19.9583V19.8583H14.0514H8.29102H8.19102V19.9583V21.4166V21.5166H8.29102H14.0514H14.1514V21.4166V19.9583Z" fill="#3052D0" stroke="#3052D0" strokeWidth="0.2"/>
<path d="M25.5007 36.0999H25.6007V35.9999V34.5416V34.4416H25.5007C20.5986 34.4416 16.6319 30.4041 16.6319 25.4999C16.6319 20.5979 20.6694 16.6312 25.5736 16.6312C26.297 16.6312 27.0886 16.7036 27.8053 16.9186L27.8052 16.9187L27.8097 16.9198L28.6847 17.1386L28.809 17.1697V17.0416V4.64575V4.54575H28.709H4.64648H4.54648V4.64575V35.9999V36.0999H4.64648H25.5007ZM27.1507 15.1783C26.605 15.1104 26.0533 15.0458 25.5007 15.0458C19.6859 15.0458 14.9007 19.7563 14.9007 25.5728C14.9007 29.2768 16.8984 32.5654 19.8257 34.4416H6.20482V6.20409H27.1507V15.1783Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M5.44792 32.4542H5.54792V32.3542V30.8958V30.7958H5.44792H2.55833V2.55833H23.4312V5.30208V5.40208H23.5312H24.9896H25.0896V5.30208V1V0.9H24.9896H1H0.9V1V32.3542V32.4542H1H5.44792Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M28.214 35.807L28.2141 35.8075L28.2236 35.8049C32.8602 34.5538 36.0997 30.4315 36.0997 25.573C36.0997 20.716 32.8614 16.5184 28.2213 15.4133C27.3417 15.1934 26.4583 15.0459 25.5726 15.0459C19.7569 15.0459 15.0455 19.7573 15.0455 25.573C15.0455 31.3886 19.7569 36.1 25.5726 36.1C26.4559 36.1 27.3377 35.9531 28.2099 35.8077L28.214 35.807ZM27.8079 34.1532L27.8079 34.1532L27.8043 34.1543C27.0876 34.3693 26.296 34.4417 25.5726 34.4417C20.6705 34.4417 16.7038 30.4042 16.7038 25.5C16.7038 20.5969 20.6695 16.6313 25.5726 16.6313C25.9309 16.6313 26.2906 16.6671 26.6634 16.7126C26.7663 16.7251 26.8705 16.7385 26.9759 16.7519C27.2474 16.7867 27.5269 16.8224 27.8149 16.8488C31.7059 17.9336 34.4413 21.3932 34.4413 25.5C34.4413 29.6112 31.7008 33.144 27.8079 34.1532Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M21.5296 20.3153L21.4589 20.2446L21.3882 20.3153L20.357 21.3465L20.2863 21.4172L20.357 21.4879L29.5862 30.7171L29.6569 30.7878L29.7276 30.7171L30.7588 29.6859L30.8295 29.6152L30.7588 29.5445L21.5296 20.3153Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M29.7276 20.367L29.6569 20.2963L29.5862 20.367L20.357 29.5962L20.2863 29.6669L20.357 29.7376L21.3882 30.7688L21.4589 30.8395L21.5296 30.7688L30.7588 21.5396L30.8295 21.4689L30.7588 21.3982L29.7276 20.367Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
</svg>

              ) : (
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.0618 8.02075H7.29102V9.47909H24.0618V8.02075Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M24.0618 11.6667H7.29102V13.1251H24.0618V11.6667Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M13.0514 15.3125H7.29102V16.7708H13.0514V15.3125Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M13.0514 18.9583H7.29102V20.4166H13.0514V18.9583Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M24.5007 34.9999H3.64648V3.64575H27.709V16.0416L26.834 15.8228C26.1048 15.6041 25.3027 15.5312 24.5736 15.5312C19.6152 15.5312 15.5319 19.5416 15.5319 24.4999C15.5319 29.4583 19.5423 33.5416 24.5007 33.5416V34.9999ZM5.10482 33.5416H19.1777C16.1152 31.7187 14.0007 28.3645 14.0007 24.5728C14.0007 18.8124 18.7402 14.1458 24.5007 14.1458C25.084 14.1458 25.6673 14.2187 26.2507 14.2916V5.10409H5.10482V33.5416Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M4.44792 31.3542H0V0H23.9896V4.30208H22.5312V1.45833H1.45833V29.8958H4.44792V31.3542Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M24.5726 35C18.8122 35 14.1455 30.3334 14.1455 24.573C14.1455 18.8125 18.8122 14.1459 24.5726 14.1459C25.4476 14.1459 26.3226 14.2917 27.1976 14.5105C31.7913 15.6042 34.9997 19.7605 34.9997 24.573C34.9997 29.3855 31.7913 33.4688 27.1976 34.7084C26.3226 34.8542 25.4476 35 24.5726 35ZM24.5726 15.5313C19.6143 15.5313 15.6038 19.5417 15.6038 24.5C15.6038 29.4584 19.6143 33.5417 24.5726 33.5417C25.3018 33.5417 26.1038 33.4688 26.833 33.25C30.7705 32.2292 33.5413 28.6563 33.5413 24.5C33.5413 20.3438 30.7705 16.8438 26.833 15.75C26.0309 15.6771 25.3018 15.5313 24.5726 15.5313Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M20.4589 19.386L19.4277 20.4172L28.6569 29.6464L29.688 28.6152L20.4589 19.386Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M28.6569 19.4377L19.4277 28.6669L20.4589 29.6981L29.688 20.4689L28.6569 19.4377Z"
                    fill="#1F1F22"
                  />
                </svg>
              )}
            </li>
            <li
              id="5"
              onClick={() => {
                handleItemClick("5");
                setClickedItemId("5");
                navigate("/otherexp");
              }}
              // className={"5" === clickedItemId ? "clickedadmin" : ""}
              style={{
                marginLeft: "12%",
                marginBottom: "15%",
                marginTop: "30%",
              }}
            >
              {clickedItemId === "5" ? (
                <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3484_2692)">
<path d="M3 12.5C4.10457 12.5 5 11.6046 5 10.5C5 9.39543 4.10457 8.5 3 8.5C1.89543 8.5 1 9.39543 1 10.5C1 11.6046 1.89543 12.5 3 12.5Z" fill="#3052D0"/>
<path d="M10 12.5C11.1046 12.5 12 11.6046 12 10.5C12 9.39543 11.1046 8.5 10 8.5C8.89543 8.5 8 9.39543 8 10.5C8 11.6046 8.89543 12.5 10 12.5Z" fill="#3052D0"/>
<path d="M17 12.5C18.1046 12.5 19 11.6046 19 10.5C19 9.39543 18.1046 8.5 17 8.5C15.8954 8.5 15 9.39543 15 10.5C15 11.6046 15.8954 12.5 17 12.5Z" fill="#3052D0"/>
</g>
<defs>
<clipPath id="clip0_3484_2692">
<rect width="20" height="25" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>

              ) : (
                <svg
                  width="20"
                  height="26"
                  viewBox="0 0 20 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3494_2619)">
                    <path
                      d="M3 12.5C4.10457 12.5 5 11.6046 5 10.5C5 9.39543 4.10457 8.5 3 8.5C1.89543 8.5 1 9.39543 1 10.5C1 11.6046 1.89543 12.5 3 12.5Z"
                      fill="#09090A"
                    />
                    <path
                      d="M10 12.5C11.1046 12.5 12 11.6046 12 10.5C12 9.39543 11.1046 8.5 10 8.5C8.89543 8.5 8 9.39543 8 10.5C8 11.6046 8.89543 12.5 10 12.5Z"
                      fill="#09090A"
                    />
                    <path
                      d="M17 12.5C18.1046 12.5 19 11.6046 19 10.5C19 9.39543 18.1046 8.5 17 8.5C15.8954 8.5 15 9.39543 15 10.5C15 11.6046 15.8954 12.5 17 12.5Z"
                      fill="#09090A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3494_2619">
                      <rect
                        width="20"
                        height="25"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </li>
          </ul>
        ) : (
          <ul style={{ marginTop: "3.5rem" }} className="menuadmin">
            <li
              id="1"
              onClick={() => {
                handleItemClick("1");
                navigate("/expenseAdmin");
              }}
              className={"1" === clickedItemId ? "clickedadmin" : ""}
            >
              {clickedItemId === "1" ? (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7391 25V12.4783M23.9565 22.913L13.5217 15.6087M23.9565 15.6087L13.5217 22.913M8.30435 11.4348C9.68809 11.4348 11.0151 10.8851 11.9936 9.90664C12.9721 8.92819 13.5217 7.60113 13.5217 6.21739C13.5217 4.83365 12.9721 3.50659 11.9936 2.52814C11.0151 1.54969 9.68809 1 8.30435 1C6.92061 1 5.59355 1.54969 4.6151 2.52814C3.63664 3.50659 3.08696 4.83365 3.08696 6.21739C3.08696 7.60113 3.63664 8.92819 4.6151 9.90664C5.59355 10.8851 6.92061 11.4348 8.30435 11.4348ZM8.30435 11.4348C10.4049 11.4348 12.2403 12.2278 13.5457 13.5457M8.30435 11.4348C4.13043 11.4348 1 14.5652 1 18.7391V23.9565H11.4348"
                    stroke="#3052D0"
                    stroke-width="2"
                  />
                </svg>
              ) : (
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7391 25V12.4783M23.9565 22.913L13.5217 15.6087M23.9565 15.6087L13.5217 22.913M8.30435 11.4348C9.68809 11.4348 11.0151 10.8851 11.9936 9.90664C12.9721 8.92819 13.5217 7.60113 13.5217 6.21739C13.5217 4.83365 12.9721 3.50659 11.9936 2.52814C11.0151 1.54969 9.68809 1 8.30435 1C6.92061 1 5.59355 1.54969 4.6151 2.52814C3.63664 3.50659 3.08696 4.83365 3.08696 6.21739C3.08696 7.60113 3.63664 8.92819 4.6151 9.90664C5.59355 10.8851 6.92061 11.4348 8.30435 11.4348ZM8.30435 11.4348C10.4049 11.4348 12.2403 12.2278 13.5457 13.5457M8.30435 11.4348C4.13043 11.4348 1 14.5652 1 18.7391V23.9565H11.4348"
                    stroke="#1F1F22"
                    strokeWidth="2"
                  />
                </svg>
              )}
              {!collapsed && (
                <span
                  id="1"
                  onClick={() => handleItemClick("1")}
                  className={"1" === clickedItemId ? "clickedadmin" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  On-Board
                </span>
              )}
            </li>
            <li
              id="2"
              onClick={() => {
                handleItemClick("2");
                navigate("/offboard");
              }}
              className={"2" === clickedItemId ? "clickedadmin" : ""}
            >
              {clickedItemId === "2" ? (
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2857 22.2857H1V21.3565C1.01099 20.3201 1.23024 19.2965 1.64473 18.3466C2.05922 17.3966 2.66047 16.5397 3.41275 15.8268C4.16503 15.1138 5.05298 14.5594 6.02381 14.1965C6.99464 13.8336 8.02852 13.6696 9.064 13.7142C10.0898 13.7628 11.0956 14.0151 12.0227 14.4566C12.9499 14.8981 13.7798 15.5199 14.464 16.2857M14.7143 20.5714H23.2857M8.71429 10.2857C9.85093 10.2857 10.941 9.83413 11.7447 9.03041C12.5485 8.22668 13 7.13659 13 5.99995C13 4.86331 12.5485 3.77322 11.7447 2.96949C10.941 2.16576 9.85093 1.71423 8.71429 1.71423C7.57764 1.71423 6.48756 2.16576 5.68383 2.96949C4.8801 3.77322 4.42857 4.86331 4.42857 5.99995C4.42857 7.13659 4.8801 8.22668 5.68383 9.03041C6.48756 9.83413 7.57764 10.2857 8.71429 10.2857Z"
                    stroke="#3052D0"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2857 22.2857H1V21.3565C1.01099 20.3201 1.23024 19.2965 1.64473 18.3466C2.05922 17.3966 2.66047 16.5397 3.41275 15.8268C4.16503 15.1138 5.05298 14.5594 6.02381 14.1965C6.99464 13.8336 8.02852 13.6696 9.064 13.7142C10.0898 13.7628 11.0956 14.0151 12.0227 14.4566C12.9499 14.8981 13.7798 15.5199 14.464 16.2857M14.7143 20.5714H23.2857M8.71429 10.2857C9.85093 10.2857 10.941 9.83413 11.7447 9.03041C12.5485 8.22668 13 7.13659 13 5.99995C13 4.86331 12.5485 3.77322 11.7447 2.96949C10.941 2.16576 9.85093 1.71423 8.71429 1.71423C7.57764 1.71423 6.48756 2.16576 5.68383 2.96949C4.8801 3.77322 4.42857 4.86331 4.42857 5.99995C4.42857 7.13659 4.8801 8.22668 5.68383 9.03041C6.48756 9.83413 7.57764 10.2857 8.71429 10.2857Z"
                    stroke="#1F1F22"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              {!collapsed && (
                <span
                  id="2"
                  onClick={() => {
                    handleItemClick("2");
                    navigate("/offboard");
                  }}
                  className={"2" === clickedItemId ? "clickedadmin" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Off-Board
                </span>
              )}
            </li>
            <li
              id="3"
              onClick={() => {
                handleItemClick("3");
                setClickedItemId("3");
                navigate("/update");
              }}
              className={"3" === clickedItemId ? "clickedadmin" : ""}
            >
              {clickedItemId === "3" ? (
                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.3972 15.7233C22.1754 13.8737 22.3544 11.8267 21.9091 9.87013C21.4638 7.91353 20.4166 6.14565 18.9146 4.815C17.4127 3.48435 15.5314 2.65783 13.5354 2.45161C11.5394 2.24539 9.52898 2.66985 7.78668 3.66532L6.59528 1.58127C8.41602 0.540402 10.4779 -0.00481012 12.5751 3.19732e-05C14.6724 0.00487407 16.7317 0.559601 18.5476 1.60886C23.9347 4.71994 25.9984 11.3788 23.4884 16.9303L25.0985 17.8601L20.1002 20.5164L19.9022 14.8606L21.3972 15.7233ZM3.70017 8.27733C2.92168 10.1271 2.74247 12.1743 3.18776 14.1311C3.63304 16.0879 4.68042 17.856 6.18264 19.1868C7.68485 20.5175 9.56636 21.344 11.5626 21.55C13.5589 21.756 15.5695 21.3312 17.3119 20.3353L18.5021 22.4193C16.6812 23.46 14.6193 24.005 12.522 24C10.4248 23.9949 8.3655 23.44 6.54969 22.3905C1.1626 19.2795 -0.901047 12.6218 1.61013 7.06914L0 6.14169L4.99715 3.48415L5.19512 9.13999L3.70017 8.27733ZM14.2464 15.3933L10.8522 12.0003L7.45913 15.3933L5.76142 13.6968L10.8522 8.60607L14.2452 12.0003L17.6394 8.60607L19.3359 10.3038L14.2464 15.3933Z" fill="#3052D0"/>
</svg>

              ) : (
                <svg
                  width="26"
                  height="24"
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3972 15.7233C22.1754 13.8737 22.3544 11.8267 21.9091 9.87013C21.4638 7.91353 20.4166 6.14565 18.9146 4.815C17.4127 3.48435 15.5314 2.65783 13.5354 2.45161C11.5394 2.24539 9.52898 2.66985 7.78668 3.66532L6.59528 1.58127C8.41602 0.540402 10.4779 -0.00481012 12.5751 3.19732e-05C14.6724 0.00487407 16.7317 0.559601 18.5476 1.60886C23.9347 4.71994 25.9984 11.3788 23.4884 16.9303L25.0985 17.8601L20.1002 20.5164L19.9022 14.8606L21.3972 15.7233ZM3.70017 8.27733C2.92168 10.1271 2.74247 12.1743 3.18776 14.1311C3.63304 16.0879 4.68042 17.856 6.18264 19.1868C7.68485 20.5175 9.56636 21.344 11.5626 21.55C13.5589 21.756 15.5695 21.3312 17.3119 20.3353L18.5021 22.4193C16.6812 23.46 14.6193 24.005 12.522 24C10.4248 23.9949 8.3655 23.44 6.54969 22.3905C1.1626 19.2795 -0.901047 12.6218 1.61013 7.06914L0 6.14169L4.99715 3.48415L5.19512 9.13999L3.70017 8.27733ZM14.2464 15.3933L10.8522 12.0003L7.45913 15.3933L5.76142 13.6968L10.8522 8.60607L14.2452 12.0003L17.6394 8.60607L19.3359 10.3038L14.2464 15.3933Z"
                    fill="#1F1F22"
                  />
                </svg>
              )}
              {!collapsed && (
                <span
                  id="3"
                  onClick={() => {
                    handleItemClick("3");
                    setClickedItemId("3");
                    navigate("/update");
                  }}
                  className={"3" === clickedItemId ? "clickedadmin" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Update
                </span>
              )}
            </li>
            <li
              id="6"
              onClick={() => {
                handleItemClick("6");
                setClickedItemId("6");
                navigate("/abandonreq");
              }}
              className={"6" === clickedItemId ? "clickedadmin" : ""}
            >
              {clickedItemId === "6" ? (
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.1618 9.02075V8.92075H25.0618H8.29102H8.19102V9.02075V10.4791V10.5791H8.29102H25.0618H25.1618V10.4791V9.02075Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M25.1618 12.6667V12.5667H25.0618H8.29102H8.19102V12.6667V14.1251V14.2251H8.29102H25.0618H25.1618V14.1251V12.6667Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M14.1514 16.3125V16.2125H14.0514H8.29102H8.19102V16.3125V17.7708V17.8708H8.29102H14.0514H14.1514V17.7708V16.3125Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M14.1514 19.9583V19.8583H14.0514H8.29102H8.19102V19.9583V21.4166V21.5166H8.29102H14.0514H14.1514V21.4166V19.9583Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M25.5007 36.0999H25.6007V35.9999V34.5416V34.4416H25.5007C20.5986 34.4416 16.6319 30.4041 16.6319 25.4999C16.6319 20.5979 20.6694 16.6312 25.5736 16.6312C26.297 16.6312 27.0886 16.7036 27.8053 16.9186L27.8052 16.9187L27.8097 16.9198L28.6847 17.1386L28.809 17.1697V17.0416V4.64575V4.54575H28.709H4.64648H4.54648V4.64575V35.9999V36.0999H4.64648H25.5007ZM27.1507 15.1783C26.605 15.1104 26.0533 15.0458 25.5007 15.0458C19.6859 15.0458 14.9007 19.7563 14.9007 25.5728C14.9007 29.2768 16.8984 32.5654 19.8257 34.4416H6.20482V6.20409H27.1507V15.1783Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M5.44792 32.4542H5.54792V32.3542V30.8958V30.7958H5.44792H2.55833V2.55833H23.4312V5.30208V5.40208H23.5312H24.9896H25.0896V5.30208V1V0.9H24.9896H1H0.9V1V32.3542V32.4542H1H5.44792Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M28.214 35.807L28.2141 35.8075L28.2236 35.8049C32.8602 34.5538 36.0997 30.4315 36.0997 25.573C36.0997 20.716 32.8614 16.5184 28.2213 15.4133C27.3417 15.1934 26.4583 15.0459 25.5726 15.0459C19.7569 15.0459 15.0455 19.7573 15.0455 25.573C15.0455 31.3886 19.7569 36.1 25.5726 36.1C26.4559 36.1 27.3377 35.9531 28.2099 35.8077L28.214 35.807ZM27.8079 34.1532L27.8079 34.1532L27.8043 34.1543C27.0876 34.3693 26.296 34.4417 25.5726 34.4417C20.6705 34.4417 16.7038 30.4042 16.7038 25.5C16.7038 20.5969 20.6695 16.6313 25.5726 16.6313C25.9309 16.6313 26.2906 16.6671 26.6634 16.7126C26.7663 16.7251 26.8705 16.7385 26.9759 16.7519C27.2474 16.7867 27.5269 16.8224 27.8149 16.8488C31.7059 17.9336 34.4413 21.3932 34.4413 25.5C34.4413 29.6112 31.7008 33.144 27.8079 34.1532Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M21.5296 20.3153L21.4589 20.2446L21.3882 20.3153L20.357 21.3465L20.2863 21.4172L20.357 21.4879L29.5862 30.7171L29.6569 30.7878L29.7276 30.7171L30.7588 29.6859L30.8295 29.6152L30.7588 29.5445L21.5296 20.3153Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
<path d="M29.7276 20.367L29.6569 20.2963L29.5862 20.367L20.357 29.5962L20.2863 29.6669L20.357 29.7376L21.3882 30.7688L21.4589 30.8395L21.5296 30.7688L30.7588 21.5396L30.8295 21.4689L30.7588 21.3982L29.7276 20.367Z" fill="#3052D0" stroke="#3052D0" stroke-width="0.2"/>
</svg>

              ) : (
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.0618 8.02075H7.29102V9.47909H24.0618V8.02075Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M24.0618 11.6667H7.29102V13.1251H24.0618V11.6667Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M13.0514 15.3125H7.29102V16.7708H13.0514V15.3125Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M13.0514 18.9583H7.29102V20.4166H13.0514V18.9583Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M24.5007 34.9999H3.64648V3.64575H27.709V16.0416L26.834 15.8228C26.1048 15.6041 25.3027 15.5312 24.5736 15.5312C19.6152 15.5312 15.5319 19.5416 15.5319 24.4999C15.5319 29.4583 19.5423 33.5416 24.5007 33.5416V34.9999ZM5.10482 33.5416H19.1777C16.1152 31.7187 14.0007 28.3645 14.0007 24.5728C14.0007 18.8124 18.7402 14.1458 24.5007 14.1458C25.084 14.1458 25.6673 14.2187 26.2507 14.2916V5.10409H5.10482V33.5416Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M4.44792 31.3542H0V0H23.9896V4.30208H22.5312V1.45833H1.45833V29.8958H4.44792V31.3542Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M24.5726 35C18.8122 35 14.1455 30.3334 14.1455 24.573C14.1455 18.8125 18.8122 14.1459 24.5726 14.1459C25.4476 14.1459 26.3226 14.2917 27.1976 14.5105C31.7913 15.6042 34.9997 19.7605 34.9997 24.573C34.9997 29.3855 31.7913 33.4688 27.1976 34.7084C26.3226 34.8542 25.4476 35 24.5726 35ZM24.5726 15.5313C19.6143 15.5313 15.6038 19.5417 15.6038 24.5C15.6038 29.4584 19.6143 33.5417 24.5726 33.5417C25.3018 33.5417 26.1038 33.4688 26.833 33.25C30.7705 32.2292 33.5413 28.6563 33.5413 24.5C33.5413 20.3438 30.7705 16.8438 26.833 15.75C26.0309 15.6771 25.3018 15.5313 24.5726 15.5313Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M20.4589 19.386L19.4277 20.4172L28.6569 29.6464L29.688 28.6152L20.4589 19.386Z"
                    fill="#1F1F22"
                  />
                  <path
                    d="M28.6569 19.4377L19.4277 28.6669L20.4589 29.6981L29.688 20.4689L28.6569 19.4377Z"
                    fill="#1F1F22"
                  />
                </svg>
              )}
              {!collapsed && (
                <span
                  id="6"
                  onClick={() => {
                    handleItemClick("6");
                    setClickedItemId("6");
                    navigate("/abandonreq");
                  }}
                  className={"6" === clickedItemId ? "clickedadmin" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Abandon Req.
                </span>
              )}
            </li>
            <li
              id="5"
              onClick={() => {
                handleItemClick("5");
                setClickedItemId("5");
                navigate("/otherexp");
              }}
              className={"5" === clickedItemId ? "clickedadmin" : ""}
            >
              {clickedItemId === "5" ? (
                <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3484_2692)">
<path d="M3 12.5C4.10457 12.5 5 11.6046 5 10.5C5 9.39543 4.10457 8.5 3 8.5C1.89543 8.5 1 9.39543 1 10.5C1 11.6046 1.89543 12.5 3 12.5Z" fill="#3052D0"/>
<path d="M10 12.5C11.1046 12.5 12 11.6046 12 10.5C12 9.39543 11.1046 8.5 10 8.5C8.89543 8.5 8 9.39543 8 10.5C8 11.6046 8.89543 12.5 10 12.5Z" fill="#3052D0"/>
<path d="M17 12.5C18.1046 12.5 19 11.6046 19 10.5C19 9.39543 18.1046 8.5 17 8.5C15.8954 8.5 15 9.39543 15 10.5C15 11.6046 15.8954 12.5 17 12.5Z" fill="#3052D0"/>
</g>
<defs>
<clipPath id="clip0_3484_2692">
<rect width="20" height="25" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>

              ) : (
                <svg
                  width="20"
                  height="26"
                  viewBox="0 0 20 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3494_2619)">
                    <path
                      d="M3 12.5C4.10457 12.5 5 11.6046 5 10.5C5 9.39543 4.10457 8.5 3 8.5C1.89543 8.5 1 9.39543 1 10.5C1 11.6046 1.89543 12.5 3 12.5Z"
                      fill="#09090A"
                    />
                    <path
                      d="M10 12.5C11.1046 12.5 12 11.6046 12 10.5C12 9.39543 11.1046 8.5 10 8.5C8.89543 8.5 8 9.39543 8 10.5C8 11.6046 8.89543 12.5 10 12.5Z"
                      fill="#09090A"
                    />
                    <path
                      d="M17 12.5C18.1046 12.5 19 11.6046 19 10.5C19 9.39543 18.1046 8.5 17 8.5C15.8954 8.5 15 9.39543 15 10.5C15 11.6046 15.8954 12.5 17 12.5Z"
                      fill="#09090A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3494_2619">
                      <rect
                        width="20"
                        height="25"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
              {!collapsed && (
                <span
                  id="5"
                  onClick={() => {
                    handleItemClick("5");
                    setClickedItemId("5");
                    navigate("/otherexp");
                  }}
                  className={"5" === clickedItemId ? "clickedadmin" : ""}
                  style={{ fontSize: "16px", fontWeight: "400" }}
                >
                  Other
                </span>
              )}
            </li>
          </ul>
        )}
        <div style={{ marginTop: "37%", cursor: "pointer" }}>
          {/* <div style={{ marginTop: "80%", cursor: "pointer" }}> */}
          {!collapsed ? (
          <img
            style={{
              position: "absolute",
              bottom: "25px",
              left: "30%",
              transform: "translateX(-50%)",
              width: "5rem",
            }}
            src="sidebarcosten.png"
          />
        ) : (
          <img
            style={{
              position: "absolute",
              bottom: "25px",
              left: "40%",
              transform: "translateX(-50%)",
              width: "3rem",
            }}
            src="sidebarcosten.png"
          />
        )}

        </div>
      </div>
    </>
  );
}

export default ExpenseAdminSideBar;
