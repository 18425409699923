
export function handleResponse(response) {
if(response.data.responseCode===1){
  window.location.href = '/logout';
}
    return response.data;
  }

export function handleError(error) {
  return error;
}
