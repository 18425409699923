import React, { useState } from "react";
import { Button, Col, Form, Input, Row, message } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import ExpenseAdminSideBar from "./ExpenseAdminSideBar";
import PageNotFound from "./PageNotFound";
import { runes } from "runes2";
import { abandonedReq } from "../services/ApiService";

const { TextArea } = Input;

function AbandonReq() {
  const [collapsed, setCollapsed] = useState(false);
  const [form] = Form.useForm();
  const adminRights = sessionStorage.getItem("aSF");

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const onFinish = (value) => {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        value[key] = value[key] !== undefined ? value[key] : "";
      }
    }
    if (Object.values(value).every((field) => field !== "")) {
      let body = {
        requestId: value.requestId,
        status: "abandoned",
        comment: value.comment,
      };
      abandonedReq(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Request Abandoned Successfully");
          form.resetFields();
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.error("All Fields are required");
    }
  };

  return (
    <>
      {adminRights === "2" || adminRights === "3" ? (
        <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
          <Row>
            <Col
              xs={!collapsed ? 19 : 11}
              sm={!collapsed ? 10 : 6}
              md={!collapsed ? 7 : 4}
              lg={!collapsed ? 4 : 5}
              xl={!collapsed ? 5 : 3}
            >
              <div
                style={{ position: "fixed", zIndex: "1" }}
                className={`toggle ${collapsed ? "collapsed" : ""}`}
                onClick={toggleSidebar}
              >
                <svg
                  className={`arrow ${collapsed ? "collapsed" : ""}`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                    fill="black"
                  />
                </svg>
              </div>
              <ExpenseAdminSideBar collapsed={collapsed} id={"6"}/>
            </Col>

            <Col
              xs={!collapsed ? 5 : 13}
              sm={!collapsed ? 14 : 18}
              md={!collapsed ? 17 : 20}
              lg={!collapsed ? 20 : 19}
              xl={!collapsed ? 19 : 21}
            >
              <Header expense={false} travel={true} />
              {/* <div style={{fontSize:'19px',fontWeight:'600',marginTop:'1rem'}}>
                Abandon Request 
              </div> */}
              <Form form={form} onFinish={onFinish} layout="horizontal">
                <div
                  style={{
                    marginTop: "2rem",
                    height: "60vh",
                    overflowX: "hidden",
                    overflowY: "auto",
                    marginRight: "0.2rem",
                  }}
                >
                  <Row gutter={6}>
                    <Col>
                      <Form.Item name="requestId">
                        <Input placeholder="Enter Request ID" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="comment">
                        <TextArea
                          // count={{
                          //   show: true,
                          //   max: 1000,
                          //   strategy: (txt) => runes(txt).length,
                          //   exceedFormatter: (txt, { max }) =>
                          //     runes(txt).slice(0, max).join(""),
                          // }}
                          className="custom-radio-group"
                          rows={3}
                          placeholder="Enter Comments"
                          autoSize={{ minRows: 1, maxRows: 3 }}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        style={{
                          //   width: "9rem",
                          backgroundColor: "#3052D0",
                          marginLeft:'1rem',
                          border: "none",
                          color: "white",
                        }}
                        htmlType="submit"
                      >
                        Request Abandoned
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>

          <Footer />
        </div>
      ) : (
        <PageNotFound />
      )}
    </>
  );
}

export default AbandonReq;
