import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Space,
  Button,
  message,
  Input,
} from "antd";
import SideBar from "./SideBar";
import Header from "./Header";
import "./dashboard.css";
import Footer from "./Footer";
import "./tobeapproved.css";
import { useLocation, useNavigate } from "react-router-dom";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import BarChartHorizontal from "./BarChartHorizontal";
import BarChartExpenseSub from "./BarChartExpenseSub";
import {
  adminInsight,
  reporteDropdown,
  reporteeInsight,
  selfInsight,
} from "../services/ApiService";
import CreditCardGraph from "./CreditCardGraph";

function SelfInsights() {
  const [collapsed, setCollapsed] = useState(false);
  const { state } = useLocation();
  const currency = sessionStorage.getItem("currency");
  const text1 = `Credit Card Expense - Monthly Trend(${currency})`;
  const text2 = `Expense By Expense Category(${currency})`;
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  let months = [];
  let selectedCategory = [];
  let categories = [
    "Transportations",
    "Travel",
    "Institutional",
    "Client/Partner Entertainment",
    "Employee Welfare",
    "Marketing",
    "Technology",
    "Office",
    "Communication",
    "Credit Card",
    "Others",
  ];

  const [year, setYear] = useState();
  const userType = sessionStorage.getItem("userType");
  const aSF = sessionStorage.getItem("aSF");
  const [monthCount, setMonthCount] = useState([]);
  const [monthAmount, setMonthAmount] = useState([]);
  const [expenseCategory, setExpenseCategory] = useState([]);
  const [expenseSubCategory, setExpenseSubCategory] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [adminStatus, setAdminStatus] = useState();
  const [reporte, setReporte] = useState([]);
  const [month, setMonth] = useState([]);
  const [category, setCategory] = useState();
  const [creditCard, setCreditCard] = useState();
  useEffect(() => {
    if (state.type === "direct-report") {
      let header = {
        organization: sessionStorage.getItem("organization"),
        managerId: sessionStorage.getItem("employeeId"),
      };

      reporteDropdown(header).then((res) => {
        if (res.responseCode === 200) {
          setEmployeeId(res.data);
        } else {
          message.error(res.responseMessage);
        }
      });
    }
  }, []);
  // const handleDateChange = (date, dateString) => {
  //   console.log("Selected date:", date);

  //   const selectedYear = date.year();
  //   const selectedMonth = date.month() + 1;
  //   console.log("Selected year:", selectedYear);
  //   console.log("Selected month:", selectedMonth);
  //   setSelectedDate(date);
  // };
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const disabledDate = (current) => {
    const year = current.year();
    const month = current.month() + 1;

    return (
      year > currentYear ||
      (year === currentYear && month > currentMonth) ||
      year < 2024
    );
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      const selectedYear = date.year();
      const selectedMonth = date.month() + 1;
      setYear(selectedYear);
    }
  };
  const options = [
    {
      label: "January",
      value: 1,
    },
    {
      label: "February",
      value: 2,
    },
    {
      label: "March",
      value: 3,
    },
    {
      label: "April",
      value: 4,
    },
    {
      label: "May",
      value: 5,
    },

    {
      label: "June",
      value: 6,
    },
    {
      label: "July",
      value: 7,
    },
    {
      label: "August",
      value: 8,
    },
    {
      label: "September",
      value: 9,
    },
    {
      label: "October",
      value: 10,
    },
    {
      label: "November",
      value: 11,
    },
    {
      label: "December",
      value: 12,
    },
  ];
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };
  const applyFilter = () => {
    const selectedMonths = month.map((monthName) => {
      const selectedOption = options.find(
        (option) => option.label === monthName
      );
      return selectedOption ? selectedOption.value : null;
    });
    const nummonth = months.map((str) => parseInt(str, 10));

    if (state.type === "self") {
      let body = {
        userId: sessionStorage.getItem("employeeId"),
        year: year,
        month: selectedMonths,
        category: category,
      };

      if (
        body.year === undefined ||
        body.category === undefined ||
        body.category?.length <= 0 ||
        body.month?.length <= 0
      ) {
        message.error("All Fields are required to apply Filter");
      } else {
        selfInsight(body).then((res) => {
          if (res.responseCode === 200) {
            setMonthCount(res?.data?.monthCounts);
            setMonthAmount(res?.data?.monthAmountSums);
            setExpenseCategory(res?.data?.categoryAmountSums);
            setExpenseSubCategory(res?.data?.subcategoryAmountSums);
            setCreditCard(res?.data?.creditCardAmountSums);
          } else {
            message.error(res.responseMessage);
          }
        });
      }
    } else if (state.type === "direct-report") {
      let body = {
        userId: sessionStorage.getItem("employeeId"),
        year: year,
        month: selectedMonths,
        category: category,
        reportees: reporte,
      };
      if (
        body.year === undefined ||
        body.category === undefined ||
        body.category?.length <= 0 ||
        body.month?.length <= 0 ||
        body.reportees?.length <= 0
      ) {
        message.error("All Fields are required to apply Filter");
      } else {
        reporteeInsight(body).then((res) => {
          if (res.responseCode === 200) {
            setMonthCount(res?.data?.monthCounts);
            setMonthAmount(res?.data?.monthAmountSums);
            setExpenseCategory(res?.data?.categoryAmountSums);
            setExpenseSubCategory(res?.data?.subcategoryAmountSums);
            setCreditCard(res?.data?.creditCardAmountSums);
          } else {
            message.error(res.responseMessage);
          }
        });
      }
    } else if (state.type === "expense-admin") {
      let body = {
        userId: sessionStorage.getItem("employeeId"),
        year: year,
        month: selectedMonths,
        category: category,
        adminStatus: adminStatus,
      };
      if (
        body.year === undefined ||
        body.category === undefined ||
        body.category?.length <= 0 ||
        body.month?.length <= 0 ||
        body.adminStatus === undefined
      ) {
        message.error("All Fields are required to apply Filter");
      } else {
        adminInsight(body).then((res) => {
          if (res.responseCode === 200) {
            setMonthCount(res?.data?.monthCounts);
            setMonthAmount(res?.data?.monthAmountSums);
            setExpenseCategory(res?.data?.categoryAmountSums);
            setExpenseSubCategory(res?.data?.subcategoryAmountSums);
            setCreditCard(res?.data?.creditCardAmountSums);
          } else {
            message.error(res.responseMessage);
          }
        });
      }
    }
  };
  const onChangeEmployeeId = (selectedKey, selectedOption) => {
    setReporte(selectedKey);
  };
  const onChangeMonth = (selectedKey, selectedOption) => {
    setMonth(selectedKey);
  };
  const onChangeAdminStatus = (selectedKey, selectedOption) => {
    let value =
      selectedKey === "Expense Admin" ? 2 : selectedKey === "Finance" ? 1 : 3;
    setAdminStatus(value);
  };
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar collapsed={collapsed} id={"2"} expense={false} travel={true}/>
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={false} travel={true} />
          <div
            style={{
              height: "80vh",
              overflowY: "scroll",
              overflowX: "hidden",
              marginTop: "2rem",
            }}
          >
            <Row>
              {userType === "1" || userType === "2" ? (
                <>
                  <Col style={{ marginRight: "0.5rem" }}>
                    <DatePicker
                      allowClear={false}
                      placeholder="Select Year"
                      picker="year"
                      // disabledDate={disabledDate}
                      onChange={handleDateChange}
                    />
                  </Col>
                  <Col style={{ marginRight: "0.5rem" }}>
                    <Select
                      mode="multiple"
                      style={{ width: "15vw" }}
                      maxTagCount={2}
                      maxTagTextLength={10}
                      showSearch
                      onChange={onChangeMonth}
                      filterOption={filterOption}
                      placeholder="Month"
                      dropdownStyle={{ maxWidth: "20vw" }}
                      virtual={true}
                    >
                      {options?.map((option) => (
                        <Select.Option key={option.value} value={option.label}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                    {/* <Select
                      style={{ width: "15vw" }}
                      mode="multiple"
                      filterOption={filterOption}
                      placeholder="Month"
                      optionLabelProp="label"
                      maxTagCount={2}
                      maxTagTextLength={10}
                      dropdownStyle={{ maxWidth: "20vw" }}
                      onSelect={handleSelect}
                    >
                      {options?.map((option) => (
                        <Select.Option key={option.value} value={option.label}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select> */}
                  </Col>
                  <Col style={{ marginRight: "0.5rem" }}>
                    <Select
                      mode="multiple"
                      style={{ width: "200px" }}
                      placeholder="Category"
                      optionLabelProp="label"
                      maxTagCount={2}
                      maxTagTextLength={10}
                      dropdownStyle={{ maxWidth: "20vw" }}
                      onChange={handleCategoryChange}
                    >
                      {categories?.map((option) => (
                        <Select.Option key={option} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {userType === "1" && state.type === "direct-report" ? (
                <>
                  <Col style={{ marginRight: "0.5rem" }}>
                    <Select
                      mode="multiple"
                      style={{ width: "200px" }}
                      maxTagCount={2}
                      maxTagTextLength={10}
                      showSearch
                      onChange={onChangeEmployeeId}
                      filterOption={filterOption}
                      placeholder="Direct-Report Name"
                      dropdownStyle={{ maxWidth: "20vw" }}
                      virtual={true}
                    >
                      {employeeId?.map((option) => (
                        <Select.Option
                          key={option.employeeId}
                          value={option.employeeId}
                        >
                          {option.employeeName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {(aSF === "2" && state.type === "expense-admin") ||
              (aSF === "3" && state.type === "expense-admin") ? (
                <Col style={{ marginRight: "0.5rem" }}>
                  <Select
                    filterOption={filterOption}
                    placeholder="Expend Admin / Finance"
                    onChange={onChangeAdminStatus}
                    optionLabelProp="label"
                  >
                    {aSF === "2" ? (
                      <Select.Option key="2" value="Expense Admin">
                        Expense Admin
                      </Select.Option>
                    ) : aSF === "1" ? (
                      <Select.Option key="1" value="Finance">
                        Finance
                      </Select.Option>
                    ) : aSF === "3" ? (
                      <>
                        <Select.Option key="2" value="Expense Admin">
                          Expense Admin
                        </Select.Option>
                        <Select.Option key="1" value="Finance">
                          Finance
                        </Select.Option>
                        <Select.Option key="3" value="Both">
                          Both
                        </Select.Option>
                      </>
                    ) : (
                      <></>
                    )}
                  </Select>
                </Col>
              ) : (
                <></>
              )}

              <Col>
                <Button
                  onClick={() => {
                    applyFilter();
                  }}
                  style={{ width: "8vw" }}
                  type="primary"
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
            {monthCount.length > 0 && monthAmount.length > 0 ? (
              <Row style={{ marginTop: "2rem" }}>
                <Col lg={12}>
                  <div
                    style={{
                      borderRadius: "15px",
                      padding: "1rem 1rem 0 1rem",
                      boxShadow: "0px 2px 6px 0px rgba(151, 172, 198, 0.25)",
                      marginRight: "0.5rem",
                    }}
                  >
                    <LineChart data={monthCount} />
                  </div>
                </Col>
                <Col lg={12}>
                  <div
                    style={{
                      borderRadius: "15px",
                      padding: "1rem 1rem 0 1rem",
                      boxShadow: "0px 2px 6px 0px rgba(151, 172, 198, 0.25)",
                      marginRight: "0.5rem",
                    }}
                  >
                    <BarChart data={monthAmount} />
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {expenseCategory.length > 0 && expenseSubCategory.length > 0 ? (
              <Row style={{ marginTop: "1rem" }}>
                <Col lg={12}>
                  <div
                    style={{
                      borderRadius: "15px",
                      padding: "1rem 1rem 0 1rem",
                      boxShadow: "0px 2px 6px 0px rgba(151, 172, 198, 0.25)",
                      marginRight: "0.5rem",
                    }}
                  >
                    <BarChartHorizontal data={expenseCategory} texts={text2} />
                  </div>
                  {creditCard?.length > 0 ? (
                    <div
                      style={{
                        borderRadius: "15px",
                        padding: "1rem 1rem 0 1rem",
                        boxShadow: "0px 2px 6px 0px rgba(151, 172, 198, 0.25)",
                        marginRight: "0.5rem",
                      }}
                    >
                      <CreditCardGraph data={creditCard} texts={text1} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col lg={12}>
                  <div
                    style={{
                      borderRadius: "15px",
                      padding: "1rem 1rem 0 1rem",
                      boxShadow: "0px 2px 6px 0px rgba(151, 172, 198, 0.25)",
                      marginRight: "0.5rem",
                    }}
                  >
                    <BarChartExpenseSub data={expenseSubCategory} />
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default SelfInsights;
