import React, { useEffect, useState } from "react";
//import "../../../src/items.css"
import "../../items.css";
import { CiSearch, CiTurnL1 } from "react-icons/ci";
import img from "./costen.png";
import { IoSearch } from "react-icons/io5";
import { Checkbox, message } from "antd";
import { CheckboxProps } from "antd";
import pic from "../CostenDemo/_Sticker.png";
import bill from "../CostenDemo/TeamBill.jpeg";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { MdDelete } from "react-icons/md";
import { Dropdown, Space, Menu } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { Spin } from "antd";
import { costenDriveDelete, costenDriveUpload } from "../../services/ApiService";

const CommonHeader = (props) => {
  const [file, setfile] = useState("");
  const [toggle, setToggle] = useState("grid");
  const [datasort, setdatasort] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    setToggle(props.toggle);
  });
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      props.search(searchQuery);
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    setdatasort("search");
    props.search(event.target.value);
  };
  const handleMenuClick = (e) => {
    setdatasort(e.key);
  };
  props.dataSort(datasort);

  //Delete API CALL OF THE UPLOAD ----Api service need to implement --Yukta
  const postData = () => {
    // const headers = {
    //   Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    //   organization: sessionStorage.getItem("organization"),
    //   employeeId: sessionStorage.getItem("employeeId"),
    // };

    const body = {
      fileName: props.selectedIds,
    };
    costenDriveDelete(body)  .then((response) => {
      if(response.responseCode===200){
        message.info("File deleted successfully")
        props.trigger(props.selectedIds);

      }
      else{
        message.error(response.responseMessage)
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
    // const apiUrl = "https://vyay-test-stage.azurewebsites.net/costen-drive-ops";

    // axios
    //   .post(apiUrl, body, { headers })
    //   .then((response) => {
    //     props.trigger(props.selectedIds);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };
  //delete api calls end here

  //Upload Api call --Api service need to implement --Yuktaa
  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setfile(URL.createObjectURL(event.target.files[0]));

    sessionStorage.setItem("fileName", selectedImage.name);
    if (selectedImage && ['image/jpeg', 'image/jpg', 'image/png', 'image/heic', 'application/pdf'].includes(selectedImage.type)) {
      const formData = new FormData();
      formData.append("organization", sessionStorage.getItem("organization"));
      formData.append("employeeId", sessionStorage.getItem("employeeId"));
      formData.append("file", selectedImage);
      costenDriveUpload(formData)
        .then((response) => {
          if(response.responseCode===200){
            props.trigger(selectedImage.name);
            message.info("File uploaded successfully")
          }
          else{
            message.error(response.responseMessage)
          }
      
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
      // axios
      //   .post(
      //     "https://vyay-test-stage.azurewebsites.net/costen-drive-upload",
      //     formData,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",

      //         Authorization: "Bearer " + sessionStorage.getItem("accessToken"),
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     props.trigger(selectedImage.name);
      //     console.log("Image uploaded successfully:", response.data);
      //   })
      //   .catch((error) => {
      //     console.error("Error uploading image:", error);
      //   });
    } else {
      message.info("Accepted files formats : JPEG, JPG, PDF, HEIC, PNG.")
    }
  };
  //upload api call ends here

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="name">Sort by Name</Menu.Item>
      <Menu.Item key="date">Sort by Date</Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="main">
        <h1 className=" font-normal text-sm text-end mr-32 mt-2 rounded-xl overflow-auto text-gray-500">
          Total Image {props.totalImage ? props.totalImage : "0"}
        </h1>
        <div className=" bg-white   mr-28 flex justify-between   rounded-xl ">
          <h1 className="text-start font-semibold  text-slate-700  text-2xl pt-0 pl-3">
            Costen Drive
          </h1>
          <div className="pd-8 font-sm font-thin h-16">
            {/* upload button  */}
            <label
              for="uploadFile1"
              class=" bg-cyan-500 text-white text-sm px-4 py-1 mt-3 mr-2 outline-none rounded w-max cursor-pointer mx-auto block font-[sans-serif]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 mr-2 fill-white inline"
                viewBox="0 0 32 32"
              >
                <path
                  d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                  data-original="#000000"
                />
                <path
                  d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                  data-original="#000000"
                />
              </svg>
              Upload a Recepit
              <input
                type="file"
                id="uploadFile1"
                class="hidden"
                onChange={handleImageChange}
              />
            </label>

            <h1 className="text-gray-400  font-normal text-start mt-0 pt-0 text-sm ml-2">
              {file.name}{" "}
            </h1>
          </div>
        </div>

        <h1 className=" font-normal text-sm text-end mr-32 mt-2 text-gray-500">
          {props.storage} storage is used
        </h1>
        <div className="searchBar mr-32 mt-5 ">
          <div class="flex items-center justify-center h-10">
            <div class="flex w-full  rounded-md bg-gray-100">
              <input
                class=" w-full border-none bg-transparent ml-2  font-medium   text-gray-400 outline-none focus:outline-none "
                placeholder="Search"
                value={searchQuery}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
              <button
                type="submit"
                class="m-2 rounded bg-gray-100 decoration-none border-0 px-4 py-2 text-white"
              >
                {/* <CiSearch  /> */}
                <IoSearch className="text-black text-xl font-bold" />
              </button>
            </div>
          </div>
        </div>
        {/* sorting header */}

        <div className=" flex  justify-between items-center w-88">
          <div className=" sort mt-6">
            <Dropdown overlay={menu}>
              <a onClick={(e) => e.preventDefault()}>
                <Space className="  text-base font-semibold text-gray-700">
                  Files
                  <DownOutlined className="text-base text-gray-700 font-semibold" />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="icon mr-32 mt-6">
            <div className="flex justify-center items-center">
              <div
                className=" flex"
                onClick={postData}
                style={{ cursor: "pointer" }}
              >
                <MdDelete className=" mt-2  text-red-600 text-xl" />
                <h1 className="font-normal text-sm text-gray-600 ml-2 mr-3">
                  Delete Selected
                </h1>
              </div>
              <div className=" ml-5">
                <label className="themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md  bg-white p-1">
                  <input
                    type="checkbox"
                    className="sr-only"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div onClick={() => setToggle("grid")}>
                    <Link to="/costen-drive">
                      {" "}
                      <span
                        className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${
                          toggle === "grid" || toggle === ""
                            ? "text-white bg-[#0C2769] "
                            : " bg-white"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="fill-current w-4 h-4 mr-2"
                        >
                          <rect x="3" y="3" width="7" height="7"></rect>
                          <rect x="14" y="3" width="7" height="7"></rect>
                          <rect x="14" y="14" width="7" height="7"></rect>
                          <rect x="3" y="14" width="7" height="7"></rect>
                        </svg>
                        Grid
                      </span>
                    </Link>
                  </div>
                  <div onClick={() => setToggle("list")}>
                    <Link to="/list-view">
                      {" "}
                      <span
                        className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${
                          toggle === "list"
                            ? "text-white bg-[#0C2769]"
                            : "bg-white"
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className=" font-bold text-white"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="fill-current w-4 h-4 mr-2"
                        >
                          <line x1="8" y1="6" x2="21" y2="6"></line>
                          <line x1="8" y1="12" x2="21" y2="12"></line>
                          <line x1="8" y1="18" x2="21" y2="18"></line>
                          <line x1="3" y1="6" x2="3.01" y2="6"></line>
                          <line x1="3" y1="12" x2="3.01" y2="12"></line>
                          <line x1="3" y1="18" x2="3.01" y2="18"></line>
                        </svg>
                        List
                      </span>
                    </Link>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CommonHeader id="1"  /> */}
    </div>
  );
};

export default CommonHeader;
