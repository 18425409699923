import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import SideBar from "../SideBar";
import Header from "../Header";
import "../dashboard.css";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import {
  allExpenseTravelRequestDashboard,
  allTravelRequestDashboard,
  getOpenRequest,
  getToBeApproved,
  getTotalRequest,
} from "../../services/ApiService";
import moment from "moment";
import { useDispatch } from "react-redux";
const formatColumnName = (columnName) => {
  if (columnName === "to_be_approved") {
    return <span style={{ color: "#7B809A" }}>To Be Approved</span>;
  }
  const words = columnName.split("_");
  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return formattedWords.join(" ");
};

const { Option } = Select;
function ToBeApprovedExpense() {
  const [collapsed, setCollapsed] = useState(false);
  const today = new Date();

  const [details, setDetails] = useState(false);
  const [id, setId] = useState();
  const [totalRequest, setTotalRequest] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const empId = sessionStorage.employeeId;
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    // Note: Month is 0-indexed in JavaScript Date (0 for January, 11 for December)
    return new Date(year, month - 1, day);
  };
  useEffect(() => {
    allExpenseTravelRequestDashboard(empId).then((res) => {
      if (res.responseCode === 200) {
        const dataSource = res.data.toBeApproved.map((item) => {
          if (item.bill_date) {
            item.bill_date = moment(item.bill_date).format("DD-MM-YYYY");
          }
          if (item.to_be_approved) {
            item.to_be_approved = moment(item.to_be_approved).format(
              "DD-MM-YYYY"
            );
          }
          return item;
        });

        if (res.data.toBeApproved.length > 0) {
          const filteredColumns = Object.keys(res.data.toBeApproved[0])
            .filter(
              (key) =>
                key !== "rejectedBy" &&
                key !== "reasonReject" &&
                key !== "employee_id"
            ) // Exclude "rejectedBy" and "reasonReject"
            .map((key) => ({
              title: formatColumnName(key),
              dataIndex: key,
              key,
              titleStyle: { color: "#7b809a" },

              render: (text, record) => {
                if (key === "status" && text === "rejected") {
                  return (
                    <Popover
                      content={
                        <span>
                          {record.rejectedBy} : {record.reasonReject}
                        </span>
                      }
                    >
                      <span>
                        {text} <WarningOutlined style={{ color: "red" }} />
                      </span>
                    </Popover>
                  );
                } else if (key === "request_id") {
                  return (
                    <span style={{ color: "#7B809A" }}>
                      {record.request_id}
                    </span>
                  );
                } else if (key === "total_amount") {
                  return (
                    <span style={{ color: "#4CAF50" }}>
                      {record.total_amount}
                    </span>
                  );
                } else if (key === "status") {
                  return (
                    <span
                      style={{
                        color: "#3052D0",
                        border: "none",
                        backgroundColor: "#534ecc0c",
                        textAlign: "center",
                        borderRadius: "15px",
                        padding: "0.3rem 0.5rem",
                        whiteSpace: "nowrap", // Prevent text from wrapping
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Show ellipsis for long text
                        width: "110px", // Adjust the width accordingly
                        display: "inline-block", // Needed for ellipsis to work
                      }}
                    >
                      {record.status}
                    </span>
                  );
                } else if (key === "employee_name") {
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontWeight: "600" }}>
                        {record.employee_name}
                      </span>
                      <span style={{ fontWeight: "400", color: "#4CAF50" }}>
                        {record.employee_id}
                      </span>
                    </div>
                  );
                } else if (key === "to_be_approved") {
                  return (
                    <>
                      {record.to_be_approved !== null &&
                      parseDate(record.to_be_approved) < today &&
                      parseDate(record.to_be_approved).toDateString() !==
                        today.toDateString() ? (
                        <span
                          style={{
                            color: "red",
                            border: "none",
                            backgroundColor: "#534ecc0c",
                            textAlign: "center",
                            borderRadius: "15px",
                            padding: "0.3rem 0.5rem 0.3rem 0.5rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "85px",
                            display: "inline-block",
                          }}
                        >
                          {record.to_be_approved}
                        </span>
                      ) : (
                        <span
                          style={{
                            whiteSpace: "nowrap", // Prevent text from wrapping
                            overflow: "hidden", // Hide overflow
                            textOverflow: "ellipsis", // Show ellipsis for long text
                            width: "85px", // Adjust the width accordingly
                            display: "inline-block", // Needed for ellipsis to work
                            color: "black",
                            border: "none",
                            backgroundColor:
                              record.to_be_approved !== null ? "#534ecc0c" : "",
                            textAlign: "center",
                            borderRadius: "15px",
                            padding: "0.3rem 0.5rem 0.3rem 0.5rem",
                          }}
                        >
                          {record.to_be_approved}
                        </span>
                      )}
                    </>
                  );
                }
                return text;
              },
            }));

          setColumns([
            ...filteredColumns,
            {
              // title: "Details",
              key: "details",
              render: (text, record) => (
                <Button
                  style={{
                    color: "#60C295",
                    border: "1px solid #60C295",
                    width: "100%",
                  }}
                  onClick={() => handleDetailClick(record)}
                >
                  Details
                </Button>
              ),
            },
          ]);
          setData(res.data.toBeApproved);
        } else {
          setData([]);
          setColumns([]);
        }
        setTotalRequest(res.data.toBeApproved);
      } else {
        message.error(res.responseMessage);
      }
    });
  }, []);
  const handleDetailClick = (record) => {
    navigate("/details-expense", {
      state: { id: record.request_id, status: record.status },
    });
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={true}
            travel={false}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={true} travel={false} />
          <div
            className="scrollable-container"
            style={{
              backgroundColor: "white",
              display: "block",
              padding: "1rem",
              marginRight: "1.5rem",
              borderRadius: "15px",
              marginTop: "2rem",
              // overflow: "auto",
              height: "74vh",
            }}
          >
            {data ? (
              <Table
                // scroll={{
                //   x: 1300,
                //   y: 260,
                // }}
                pagination={{ pageSize: 5 }}
                columns={columns.map((column) => ({
                  ...column,
                  title: (
                    <span style={{ color: "#7b809a" }}>{column.title}</span>
                  ),
                }))}
                dataSource={data}
                rowKey="request_id"
              />
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default ToBeApprovedExpense;
