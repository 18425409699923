import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Header";
import SideBar from "../../SideBar";
import Footer from "../../Footer";
import { useDispatch, useSelector } from "react-redux";
import {
    cancelRequest,
    ExpenseCancelRequest,
    getCashInAdvance,
  getCurrencyList,
  getExpenseCashInAdvance,
  postPullTravelAdvance,
  submitExpenseRequest,
} from "../../../services/ApiService";
const { TextArea } = Input;

function PullTravelAdvance() {
  const { state } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [form] = Form.useForm();
  const requestid = useSelector((state) => state.requestedid);
  const requestName = useSelector((state) => state.travelHeader.requestName);
  const dispatch = useDispatch();

  const requestedid = useSelector((state) => state.requestedid);
  const [currencyList, setCurrencyList] = useState([]);
  const userType = sessionStorage.getItem("userType");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  // const isFirstRun = useRef(true);
  const requestPolicy = useSelector(
    (state) => state.travelHeader.requestPolicy
  );

  useEffect(() => {
    console.log(requestedid, requestid);
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    getCurrencyList().then((res) => {
      if (res.responseCode === 200) {
        setCurrencyList(res.data);
      }
    });
    if (requestedid) {
      getExpenseCashInAdvance(requestid).then((res) => {
        if (res.responseCode === 200) {
          if (res.data) {
            form.setFieldsValue({

              reasonTravelAdvance: res.data?.reasonTravelAdvance,
              travelAdvance: res.data?.travelAdvance,
              exchangeRate: res.data?.exchangeRate,
              travelAdvanceOriginal: res.data?.travelAdvanceOriginal,
              currency: res.data?.currency,
            })

            // form.setFieldsValue({
            //   cashAdvance: res.data?.cashInAdvance,
            //   reason: res.data?.reasonCashInAdvance,
            // });
            let reduxobj = {
              reasonTravelAdvance: res.data?.reasonTravelAdvance,
              travelAdvance: res.data?.travelAdvance,
              exchangeRate: res.data?.exchangeRate,
              travelAdvanceOriginal: res.data?.travelAdvanceOriginal,
              currency: res.data?.currency,
            };
            // dispatch(cashAdvance(reduxobj));
          }
        }
      });
    } else {
      message.info("Please Save Travel Overview");
    }
  }, []);

  const onFinish = (value) => {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        value[key] = value[key] !== null ? value[key] : "";
      }
    }
    value["travelAdvanceOriginal"] = parseFloat(value["travelAdvanceOriginal"]);

    let body = {
      ...value,
      requestId: requestedid,
    };
    console.log(body);
    if (
      Object.entries(value).every(
        ([key, field]) => field !== "" && !isNaN(body.travelAdvanceOriginal)
      ) && body.reasonTravelAdvance
    ) {
      postPullTravelAdvance(body).then((res) => {
        if (res.responseCode === 200) {
          message.success(res.responseMessage);
        } else {
          message.error(res.responseMessage);
        }
      });

      //   dispatch(cashAdvance(value));
    } else {
      message.info("Add full data for travel advance to save");
    }
  };
  const onCancel = () => {
    setOpen(true);
  };
  const onSubmit = () => {
    setOpen(false);
    let body = {
      requestId: requestid,
    };
    if (requestid) {
        ExpenseCancelRequest(body).then((res) => {
            if (res.responseCode === 200) {
              message.success("Canceled the Request Successfully");
              navigate("/dashboard-expense");
            
            } else {
              message.error(res.responseMessage);
            }
          });
   
    } else {
      if (userType == "1") {
        navigate("/dashboard-m");
      } else {
        navigate("/dashboard");
      }
      message.error("Data is not Saved Yet");
    }
  };
  const onValuesChange = (changedValues, allValues) => {
    // Get the name of the field that was changed
    const fieldName = Object.keys(changedValues)[0];
    // If the 'firstField' value changed, get the value of 'secondField'
    if (fieldName === "exchangeRate") {
      const travelAdvanceOriginal = form.getFieldValue("travelAdvanceOriginal");
      const exchangeRate = form.getFieldValue("exchangeRate");
      form.setFieldsValue({
        travelAdvance: exchangeRate * travelAdvanceOriginal,
      });
    }

    // If the 'secondField' value changed, get the value of 'firstField'
    if (fieldName === "travelAdvanceOriginal") {
      const travelAdvanceOriginal = form.getFieldValue("travelAdvanceOriginal");
      const exchangeRate = form.getFieldValue("exchangeRate");
      form.setFieldsValue({
        travelAdvance: exchangeRate * travelAdvanceOriginal,
      });
    }
    if (fieldName === "currency") {
      const currency = form.getFieldValue("currency");
      const selectedCountryObject = currencyList.find(
        (item) => item.currencyCode === currency
      );
      form.setFieldsValue({
        exchangeRate: selectedCountryObject.exchangeRate,
      });
      const travelAdvanceOriginal = form.getFieldValue("travelAdvanceOriginal");
      form.setFieldsValue({
        travelAdvance:
          travelAdvanceOriginal * selectedCountryObject.exchangeRate,
      });
    }
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  console.log(state);
  const onSubmitOfForm = () => {
    // setOpen(false);
    setSpinner(true)
    submitExpenseRequest(requestid).then((res) => {
      if (res.responseCode === 200) {
        setSpinner(false)
        message.success("Thank You for Submitting the request");
        navigate("/dashboard-expense");
      } else {
        setSpinner(false)
        message.error(res.responseMessage);
      }
    });
  };
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 4 : 2}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={true}
            travel={false}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 20 : 22}
        >
          <div style={{ marginLeft: "1.5rem" }}>
            <Header expense={true} travel={false} />
            <Spin spinning={spinner} tip="Submitting ..." size="large">

            <div
              style={{
                backgroundColor: "white",
                margin: "1.5rem 1.5rem 0 0",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "5px",
                padding: "1rem 1rem 1rem 2rem",
                flexDirection: "column",
              }}
            >
              <Form
                form={form}
                onFinish={onFinish}
                layout="horizontal"
                onValuesChange={onValuesChange}
              >
                <div
                  style={{
                    height: "53vh",
                    overflow: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Request ID :</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {requestid ? requestid : ""}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <span style={{ fontWeight: "600" }}>Request Name :</span>
                      <span style={{ color: "#3052D0", fontWeight: "500" }}>
                        {state?.request_name ? state?.request_name : ""}
                      </span>
                    </div>
                  </div>

                  <Row>
                    <Col lg={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                          Currency
                        </label>
                        <Form.Item
                          name="currency"
                          // rules={[
                          //   { required: true, message: "Please Select Currency" },
                          // ]}
                        >
                          <Select
                            listItemHeight={10}
                            listHeight={200}
                            showSearch
                            className="w-56 md:w-42"
                            style={{ width: "90%" }}
                            // onChange={(value) =>
                            //   handleInputChange(index, "billCurrency", value)
                            // }
                            // onSearch={onSearch}
                            // filterOption={filterOption}

                            placeholder="Select Currency"
                            // className="w-56 md:w-42"
                            virtual={true}
                          >
                            {currencyList?.map((option) => (
                              <Select.Option
                                key={option.currencyCode}
                                value={option.currencyCode}
                              >
                                {option.currencyCode}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                          Exchange Rate
                        </label>
                        <Form.Item name="exchangeRate">
                          <input
                            // disabled={disabledExchange}
                            className="inputclass w-64 md:w-52"
                            // name="exchangeRate"
                            type="number"
                            placeholder="Enter Exchange Rate"
                          />
                          {/* <InputNumber  style={{width:"80%"}}/> */}
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                          Travel Advance Amount
                        </label>
                        <Form.Item name="travelAdvanceOriginal">
                          <input
                            // disabled={disabledExchange}
                            className="inputclass w-64 md:w-52"
                            // name="travelAdvanceOriginal"
                            type="number"
                            placeholder="Enter Amount"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                          Local Currency Amount
                        </label>
                        <Form.Item name="travelAdvance">
                          <input
                            disabled
                            // disabled={disabledExchange}
                            className="inputclass w-64 md:w-52"
                            type="number"
                            placeholder="Enter Amount"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={22}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.5rem",
                        }}
                      >
                        <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                          Comment
                        </label>
                        <Form.Item
                          name="reasonTravelAdvance"
                          // rules={[
                          //   { required: true, message: "Please Enter the reason" },
                          // ]}
                        >
                          <TextArea
                            style={{ resize: "none" }}
                            rows={4}
                            placeholder="Describe the reason for travel advance"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "3rem",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1.5rem",
                  }}
                >
                  <Button
                    style={{
            width: "8.5rem",
            backgroundColor: "#3052D0",
            border: "none",
            color: "white",
          }}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <Button
                    style={{
                      width: "8.5rem",
                      backgroundColor: "green",
                      border: "none",
                      color: "white",
                    }}
                    onClick={onSubmitOfForm}
                  >
                    Submit
                  </Button>
                  <Popconfirm
            title="Are you sure, want to Cancel the whole request"
            okText="Yes"
            cancelText="No"
            // description="Open Popconfirm with Promise"
            onConfirm={onSubmit}
            // onCancel={() => setOpen(false)}
          >
            <Button
              style={{
                width: "8.5rem",
                backgroundColor: "transparent",
                border: "1px solid red",
                color: "red",
              }}
              // onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </Popconfirm>
                </div>
              </Form>
            </div>
            </Spin>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}

export default PullTravelAdvance;
