import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cashAdvance } from "../../../redux/actions";
import {
  cancelRequest,
  cashInAdvance,
  getCashInAdvance,
  getExpenseCashInAdvance,
} from "../../../services/ApiService";
import { useNavigate } from "react-router-dom";
const { TextArea } = Input;
function PullCashAdvance({ triggerParentEffect }) {
  const [form] = Form.useForm();
  const requestid = useSelector((state) => state.requestedid);
  const requestName = useSelector((state) => state.travelHeader.requestName);
  const dispatch = useDispatch();
  const travelHeader = useSelector((state) => state.travelHeader);
  const cashAdvanceData = useSelector((state) => state.cashAdvance);
  const general = useSelector((state) => state.general);
  const inputObject = useSelector((state) => state.hoteldata);
  const datesArray = useSelector((state) => state.travelHeader.dates);
  const keysObject = useSelector((state) => state.perdiem);
  const requestedid = useSelector((state) => state.requestedid);
  const flight = useSelector((state) => state.flight);
  const train = useSelector((state) => state.train);
  const bus = useSelector((state) => state.bus);
  const taxi = useSelector((state) => state.taxi);
  const carRental = useSelector((state) => state.carRental);
  const perdiemextra = useSelector((state) => state.perdiemextra);
  const transportation = [];
  const userType = sessionStorage.getItem("userType");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const isFirstRun = useRef(true);
  const requestPolicy = useSelector(
    (state) => state.travelHeader.requestPolicy
  );

  const arrayOfObjects = [];
  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    if (requestedid) {
      getExpenseCashInAdvance(requestid).then((res) => {
        if (res.responseCode === 200) {
          if (res.data) {
            form.setFieldsValue({

              reasonTravelAdvance: res.data?.reasonTravelAdvance,
              travelAdvance: res.data?.travelAdvance,
              exchangeRate: res.data?.exchangeRate,
              travelAdvanceOriginal: res.data?.travelAdvanceOriginal,
              currency: res.data?.currency,
            })
            let reduxobj = {
              cashAdvance: res.data?.cashInAdvance,
              reason: res.data?.reasonCashInAdvance,
            };
            dispatch(cashAdvance(reduxobj));
          }
        }
        else{
          message.error(res.responseMessage)
        }
      });
    } else {
      message.info("Please Save Travel Overview");
    }
  }, []);
 

  return (
    <div
      style={{
        backgroundColor: "white",
        margin: "1.5rem 1.5rem 0 0",
        display: "flex",
        justifyContent: "flex-start",
        borderRadius: "5px",
        padding: "1rem 1rem 1rem 2rem",
        flexDirection: "column",
      }}
    >
      <Form form={form} layout="horizontal">
        <div
          style={{
            height: "54vh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5rem",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>Request ID :</span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {requestid ? requestid : ""}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>Request Name :</span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {requestName ? requestName : ""}
              </span>
            </div>
          </div>
          <Row>
            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Currency
                </label>
                <Form.Item
                  name="currency"
                  // rules={[
                  //   { required: true, message: "Please Select Currency" },
                  // ]}
                >
                  <Select
                  disabled
                    listItemHeight={10}
                    listHeight={200}
                    showSearch
                    className="w-56 md:w-42"
                    style={{ width: "90%" }}
                    // onChange={(value) =>
                    //   handleInputChange(index, "billCurrency", value)
                    // }
                    // onSearch={onSearch}
                    // filterOption={filterOption}

                    placeholder="Select Currency"
                    // className="w-56 md:w-42"
                    virtual={true}
                  >
                    {/* {currencyList?.map((option) => (
                      <Select.Option
                        key={option.currencyCode}
                        value={option.currencyCode}
                      >
                        {option.currencyCode}
                      </Select.Option>
                    ))} */}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Exchange Rate
                </label>
                <Form.Item name="exchangeRate">
                  <input
                  disabled
                    // disabled={disabledExchange}
                    className="inputclass w-64 md:w-52"
                    // name="exchangeRate"
                    type="number"
                    placeholder="Enter Exchange Rate"
                  />
                  {/* <InputNumber  style={{width:"80%"}}/> */}
                </Form.Item>
              </div>
            </Col>
            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Travel Advance Amount
                </label>
                <Form.Item name="travelAdvanceOriginal">
                  <input
                  disabled
                    // disabled={disabledExchange}
                    className="inputclass w-64 md:w-52"
                    // name="travelAdvanceOriginal"
                    type="number"
                    placeholder="Enter Amount"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Local Currency Amount
                </label>
                <Form.Item name="travelAdvance">
                  <input
                    disabled
                    // disabled={disabledExchange}
                    className="inputclass w-64 md:w-52"
                    type="number"
                    placeholder="Enter Amount"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={22}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Comment
                </label>
                <Form.Item
                  name="reasonTravelAdvance"
                  // rules={[
                  //   { required: true, message: "Please Enter the reason" },
                  // ]}
                >
                  <TextArea
                  disabled
                    style={{ resize: "none" }}
                    rows={4}
                    placeholder="Describe the reason for travel advance"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex", flexDirection: "row", gap: "3rem" }}>
            {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Employee Country Code
                </label>
                <Form.Item name="employeeCountryCode">
                  <Input placeholder="Enter Country Code " />
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Currency
                </label>
                <Form.Item name="currency">
                  <Input placeholder="Enter Currency" />
                </Form.Item>
              </div> */}
          </div>
        </div>
        {/* <div
            style={{ display: "flex", justifyContent: "center", gap: "1.5rem" }}
          >
            <Button
              style={{
                width: "8.5rem",
                backgroundColor: "#3052D0",
                border: "none",
                color: "white",
              }}
              htmlType="submit"
            >
              Save
            </Button>
        
  
            <Button
              style={{
                width: "8.5rem",
                backgroundColor: "transparent",
                border: "1px solid red",
                color: "red",
              }}
            onClick={()=>onCancel()}
  
            >
              Cancel
            </Button>
          </div> */}
      </Form>
    </div>
  );
}

export default PullCashAdvance;
