import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Chart from 'react-apexcharts';

const BarChart = ({ data }) => {
  const categories = data.map(item => item.month);
  const values = data.map(item => item.amountSum);
const  currency=sessionStorage.getItem('currency')
  const chartOptions = {
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    title: {
      text: `Expense Amount By Month(${currency})`,
      align: 'centre'
    },
    grid: {
      show: false, // Hide grid lines
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
      }
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
    show:true
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000'], // Set the color to black
      },
    },
  };

  const chartSeries = [
    {
      name: 'Expense',
      data: values,
    },

  ];

  return (
    <div>
      <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={200} />
    </div>
  );
};

export default BarChart;
