import React from "react";
import { Button, DatePicker } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

function NomialReports() {
  const handleDateChange = (dates, dateStrings) => {
    const startDateString = dateStrings[0];
    const endDateString = dateStrings[1];

  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "2rem",
        marginTop: "1.8rem",
        marginLeft: "2rem",
      }}
    >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                Select Date
              </label>
      <RangePicker
        allowClear={false}
        onChange={handleDateChange}
        format="DD-MM-YYYY"
      />
      </div>
      <Button style={{ backgroundColor: "#534ECC", color: "white",marginTop:'1.6rem' }}>
        <DownloadOutlined />
        Download Report
      </Button>
    </div>
  );
}

export default NomialReports