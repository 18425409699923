import { Button, Col, Input, Menu, Modal, Row, Spin, message } from "antd";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { GrStatusInfo } from "react-icons/gr";
import { FaCircle } from "react-icons/fa";
import {
  approval,
  getApprovedetails,
  getCashInAdvance,
  getHotelData,
  getPerDiem,
  getTransport,
  sendBackByManager,
  updateEstimateCostHotel,
  updateEstimateCostTransport,
} from "../services/ApiService";
import "./travel.css";
import { decryptObject } from "./GlobalFunctions";
import { useSelector } from "react-redux";
const { TextArea } = Input;
function Details() {
  const [restrictedFlag, setRestrictedFlag] = useState();
  const flags = useSelector((state) => state.flags);
  const { state } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [data, setData] = useState();
  const [details, setDetails] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [comment, setComment] = useState();
  const [currency, setCurrency] = useState();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [categoryName, setCategoryName] = useState();
  const [submitstatus, setSubmitstatus] = useState();
  const [estimatedCost, setEstimatedCost] = useState();
  const [apiCall, setApiCall] = useState(1);
  const [requestPolicy, setRequestPolicy] = useState();
  const status =
    state.status === "submitted"
      ? "approved"
      : state.status === "approved"
      ? "sent for payment"
      : "paid";
  const urlLinkPart =
    state.status === "submitted"
      ? "manager-approve"
      : state.status === "approved"
      ? "expense-admin-approve"
      : "finance-approve";
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [total, setTotal] = useState();
  function camelToNormal(text) {
    let string = text.replace(/([a-z])([A-Z])/g, "$1 $2");
    let converted = string.charAt(0).toUpperCase() + string.slice(1);
    return converted;
  }
  const convertDateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const convertDatesInArray = (dataArray) => {
    return dataArray?.map((item) => {
      const convertedItem = { ...item };
      if (item.startDate) {
        convertedItem.startDate = convertDateFormat(item.startDate);
      }
      if (item.endDate) {
        convertedItem.endDate = convertDateFormat(item.endDate);
      }
      if (item.departureDate) {
        convertedItem.departureDate = convertDateFormat(item.departureDate);
      }
      if (item.checkIn) {
        convertedItem.checkIn = convertDateFormat(item.checkIn);
      }
      if (item.checkOut) {
        convertedItem.checkOut = convertDateFormat(item.checkOut);
      }
      if (item.returnDate) {
        convertedItem.returnDate = convertDateFormat(item.returnDate);
      }
      if (item.diemDate) {
        convertedItem.diemDate = convertDateFormat(item.diemDate);
      }
      return convertedItem;
    });
  };
  const removeRequestIdFromArray = (dataArray) => {
    return dataArray.map(({ requestId, ...rest }) => rest);
  };
  const [modalWidth, setModalWidth] = useState("80vw");
  const [modalHeight, setModalHeight] = useState("80vh");

  useEffect(() => {
    const flagsObject = decryptObject(flags);
    setRestrictedFlag(flagsObject);
    const handleResize = () => {
      const width = window.innerWidth * 0.8; // 80% of the window width
      const height = window.innerHeight * 0.8; // 80% of the window height
      setModalWidth(`${width}px`);
      setModalHeight(`${height}px`);
    };

    // Set initial dimensions
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabChange = (event, newTabIndex) => {
    setSelectedTabIndex(newTabIndex);
  };
  // const DynamicContentModal = ({
  //   visible,
  //   onCancel,
  //   dataArray,
  //   categoryName,
  // }) => {
  //   const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  //   const handleMenuClick = (e) => {
  //     setSelectedTabIndex(parseInt(e.key));
  //   };

  //   const selectedData = dataArray[selectedTabIndex];

  //   // const calculateModalWidth = () => {
  //   //   const multiplier = 300;
  //   //   const maxWidth = 760;
  //   //   const totalKeyValuePairs = dataArray?.reduce(
  //   //     (total, obj) => total + Object.keys(obj).length,
  //   //     0
  //   //   );
  //   //   return Math.min(totalKeyValuePairs * multiplier, maxWidth);
  //   // };

  //   return (
  //     <Modal
  //       visible={visible}
  //       onCancel={onCancel}
  //       footer={false}
  //       width={modalWidth}
  //       height={modalHeight}
  //       // width={calculateModalWidth()}
  //     >
  //       <div>
  //         {dataArray.length >= 2 ? (
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               margin: "1rem 2rem 0 0",
  //             }}
  //           >
  //             <Menu
  //               style={{
  //                 width: "100%",
  //                 borderRadius: "20px",
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //               }}
  //               selectedKeys={[selectedTabIndex.toString()]}
  //               // defaultSelectedKeys={["travelHeader"]}
  //               onClick={handleMenuClick}
  //             >
  //               {dataArray.map((item, index) => (
  //                 <>
  //                   <Menu.Item
  //                     style={{ textAlign: "center", color: "#9AA4B5" }}
  //                     key={index}
  //                   >
  //                     {categoryName} {index + 1}
  //                   </Menu.Item>
  //                   {categoryName !== "Travel Advance" ||
  //                   categoryName !== "Perdiem" ? (
  //                     <Button
  //                       style={{
  //                         width: "8.5rem",
  //                         backgroundColor: "#3052D0",
  //                         border: "none",
  //                         color: "white",
  //                       }}
  //                       onClick={() => {
  //                         console.log(dataArray);
  //                       }}
  //                     >
  //                       Save
  //                     </Button>
  //                   ) : (
  //                     <></>
  //                   )}
  //                 </>
  //               ))}
  //             </Menu>
  //           </div>
  //         ) : (
  //           <></>
  //         )}

  //         <div
  //           style={{

  //             fontSize: "20px",
  //             fontWeight: "800",
  //             color: "#344767",
  //             marginTop: "1.2rem",
  //             marginBottom: "1.2rem",
  //           }}
  //         >
  //           Detailed Information :
  //         </div>
  //         <div style={{ padding: "0.5rem 0 0 0" }}>
  //           <Row gutter={[16, 16]}>
  //             {selectedData &&
  //               Object.entries(selectedData).filter(([key]) => key !== "Id").map(([key, value]) => (
  //                 <Col span={8} key={key}>
  //                   <div style={{ display: "flex", flexDirection: "column" }}>
  //                     <span
  //                       style={{
  //                         fontWeight: "700",
  //                         color: "#344767",
  //                         fontSize: "14px",
  //                       }}
  //                     >
  //                       {camelToNormal(key)}
  //                     </span>
  //                     <span>{value}</span>
  //                   </div>
  //                 </Col>
  //               ))

  //               }
  //               {(categoryName !== "Travel Advance" &&
  // categoryName !== "Perdiem" &&
  // (sessionStorage.getItem("aSF") === "2" || sessionStorage.getItem("aSF") === "3") &&
  // restrictedFlag?.expenseAdminFlag?.estimatedCost === 1)&& (
  //           <Col span={8}>
  //             <div style={{ display: "flex", flexDirection: "column" }}>
  //               <span
  //                 style={{
  //                   fontWeight: "700",
  //                   color: "#344767",
  //                   fontSize: "14px",
  //                 }}
  //               >
  //                 Estimated Cost
  //               </span>
  //               <input
  //                 type="number"
  //                 style={{
  //                   padding: "0.5rem",
  //                   fontSize: "14px",
  //                   marginTop: "0.5rem",
  //                   borderRadius: "4px",
  //                   border: "1px solid #ccc",
  //                 }}
  //                 value={estimatedCost}
  //                 onChange={(e) => setEstimatedCost(e.target.value)}
  //               />
  //             </div>
  //           </Col>
  //         )}
  //           </Row>
  //           {(categoryName !== "Travel Advance" &&
  // categoryName !== "Perdiem" &&
  // (sessionStorage.getItem("aSF") === "2" || sessionStorage.getItem("aSF") === "3") &&
  // restrictedFlag?.expenseAdminFlag?.estimatedCost === 1) && (
  //               <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
  //               <Button
  //                 style={{
  //                   width: "8.5rem",
  //                   backgroundColor: "#3052D0",
  //                   border: "none",
  //                   color: "white",
  //                 }}
  //                 onClick={() => {
  //               // Add estimated cost to selectedData and log it
  //               const updatedData = { ...selectedData, estimatedCost, id: selectedData.id };
  //               console.log(updatedData);
  //             }}
  //               >
  //                 Save
  //               </Button>
  //               </div>
  //             ) }
  //         </div>
  //       </div>
  //     </Modal>
  //   );
  // };
  // const DynamicContentModal = ({ visible, onCancel, dataArray, categoryName, restrictedFlag }) => {
  //   const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  //   const [estimatedCost, setEstimatedCost] = useState('');

  //   const handleMenuClick = useCallback((e) => {
  //     setSelectedTabIndex(parseInt(e.key, 10));
  //   }, []);

  //   const selectedData = useMemo(() => dataArray[selectedTabIndex], [selectedTabIndex, dataArray]);

  //   const handleCostChange = (e) => {
  //     setEstimatedCost(e.target.value);
  //   };

  //   const shouldShowCostInputAndSaveButton = categoryName !== "Travel Advance" &&
  //     categoryName !== "Perdiem" &&
  //     (sessionStorage.getItem("aSF") === "2" || sessionStorage.getItem("aSF") === "3") &&
  //     restrictedFlag?.expenseAdminFlag?.estimatedCost === 1;

  //   console.log("Should Show Cost Input and Save Button:", shouldShowCostInputAndSaveButton);

  //   return (
  //     <Modal
  //       visible={visible}
  //       onCancel={onCancel}
  //       footer={false}
  //       width={760} // Adjust width if needed
  //     >
  //       <div>
  //         {dataArray.length >= 2 && (
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               margin: "1rem 2rem 0 0",
  //             }}
  //           >
  //             <Menu
  //               style={{
  //                 width: "100%",
  //                 borderRadius: "20px",
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //               }}
  //               selectedKeys={[selectedTabIndex.toString()]}
  //               onClick={handleMenuClick}
  //             >
  //               {dataArray.map((item, index) => (
  //                 <Menu.Item
  //                   style={{ textAlign: "center", color: "#9AA4B5" }}
  //                   key={index}
  //                 >
  //                   {categoryName} {index + 1}
  //                 </Menu.Item>
  //               ))}
  //             </Menu>
  //           </div>
  //         )}

  //         <div
  //           style={{
  //             fontSize: "20px",
  //             fontWeight: "800",
  //             color: "#344767",
  //             marginTop: "1.2rem",
  //             marginBottom: "1.2rem",
  //           }}
  //         >
  //           Detailed Information:
  //         </div>
  //         <div style={{ padding: "0.5rem 0 0 0" }}>
  //           <Row gutter={[16, 16]}>
  //             {selectedData &&
  //               Object.entries(selectedData)
  //                 .filter(([key]) => key !== "Id")
  //                 .map(([key, value]) => (
  //                   <Col span={8} key={key}>
  //                     <div style={{ display: "flex", flexDirection: "column" }}>
  //                       <span
  //                         style={{
  //                           fontWeight: "700",
  //                           color: "#344767",
  //                           fontSize: "14px",
  //                         }}
  //                       >
  //                         {camelToNormal(key)}
  //                       </span>
  //                       <span>{value}</span>
  //                     </div>
  //                   </Col>
  //                 ))}
  //             {true && ( // Change to `shouldShowCostInputAndSaveButton` after testing
  //               <Col span={8}>
  //                 <div style={{ display: "flex", flexDirection: "column" }}>
  //                   <span
  //                     style={{
  //                       fontWeight: "700",
  //                       color: "#344767",
  //                       fontSize: "14px",
  //                     }}
  //                   >
  //                     Estimated Cost
  //                   </span>
  //                   <input
  //                     type="number"
  //                     style={{
  //                       padding: "0.5rem",
  //                       fontSize: "14px",
  //                       marginTop: "0.5rem",
  //                       borderRadius: "4px",
  //                       border: "1px solid #ccc",
  //                     }}
  //                     value={estimatedCost}
  //                     onChange={handleCostChange}
  //                   />
  //                 </div>
  //               </Col>
  //             )}
  //           </Row>

  //           {true && ( // Change to `shouldShowCostInputAndSaveButton` after testing
  //             <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
  //               <Button
  //                 style={{
  //                   width: "8.5rem",
  //                   backgroundColor: "#3052D0",
  //                   border: "none",
  //                   color: "white",
  //                 }}
  //                 onClick={() => {
  //                   const updatedData = { ...selectedData, estimatedCost, id: selectedData.id };
  //                   console.log(updatedData);
  //                 }}
  //               >
  //                 Save
  //               </Button>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </Modal>
  //   );
  // };

  const DynamicContentModal = ({
    visible,
    onCancel,
    dataArray,
    categoryName,
    restrictedFlag,
  }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [estimatedCost, setEstimatedCost] = useState("");
    const [modalData, setModalData] = useState([]);

    // Initialize modalData from dataArray
    useEffect(() => {
      setModalData(dataArray);
    }, [dataArray]);

    // Update selectedData when modalData or selectedTabIndex changes
    const selectedData = useMemo(
      () => modalData[selectedTabIndex],
      [selectedTabIndex, modalData]
    );

    // Initialize estimated cost with a value from selectedData if available
    useEffect(() => {
      if (selectedData) {
        if (
          [
            "Air Fare",
            "Bus Fare",
            "Train Fare",
            "Car Rental",
            "Taxi Fare",
          ].includes(categoryName)
        ) {
          setEstimatedCost(selectedData.estimateCost || "");
        } else if (categoryName === "Hotel Fare") {
          setEstimatedCost(selectedData.totalCost || "");
        }
      }
    }, [selectedData, categoryName]);

    const handleSaveWithEstimateCost = useCallback(() => {
      const updatedData = {
        ...selectedData,
        estimatedCost,
        id: selectedData.id,
      };

      const saveData = () => {
        if (categoryName === "Hotel Fare") {
          let body = {
            employeeId: sessionStorage.getItem("employeeId"),
            requestId: data?.requestId || "",
            hotels: [
              {
                id: updatedData.id,
                estimateCost: parseFloat(updatedData.estimatedCost),
              },
            ],
          };
          return updateEstimateCostHotel(body);
        } else if (
          [
            "Air Fare",
            "Bus Fare",
            "Train Fare",
            "Car Rental",
            "Taxi Fare",
          ].includes(categoryName)
        ) {
          let body = {
            employeeId: sessionStorage.getItem("employeeId"),
            requestId: data?.requestId || "",
            transports: [
              {
                id: updatedData.id,
                estimateCost: parseFloat(updatedData.estimatedCost),
              },
            ],
          };
          return updateEstimateCostTransport(body);
        }
      };

      saveData()
        .then((res) => {
          if (res.responseCode === 200) {
            message.success(res.responseMessage);

            if (categoryName === "Hotel Fare") {
              return getHotelData(data.requestId, "travel", requestPolicy);
            } else if (
              [
                "Air Fare",
                "Bus Fare",
                "Train Fare",
                "Car Rental",
                "Taxi Fare",
              ].includes(categoryName)
            ) {
              return getTransport(data.requestId, "travel", requestPolicy);
            }
          } else {
            message.error(res.responseMessage);
            return Promise.reject("Failed to save data");
          }
        })
        .then((res) => {
          if (res.responseCode === 200) {
            if (categoryName === "Hotel Fare") {
              const updatedFormData = res.data.map((obj) => {
                const {
                  startDate: checkIn,
                  endDate: checkOut,
                  estimatedCost: totalCost,
                  ...rest
                } = obj;
                const checkInDate = new Date(checkIn);
                const checkOutDate = new Date(checkOut);
                const timeDifference =
                  checkOutDate.getTime() - checkInDate.getTime();
                const totalNights = Math.ceil(
                  timeDifference / (1000 * 3600 * 24)
                );
                let estimatedDailyCost =
                  totalNights > 0 ? totalCost / totalNights : totalCost;
                return {
                  checkIn,
                  checkOut,
                  totalNights,
                  totalCost,
                  estimatedDailyCost,
                  ...rest,
                };
              });
              const convertedArray = convertDatesInArray(updatedFormData);
              const newArrayWithoutRequestId =
                removeRequestIdFromArray(convertedArray);
              setModalData(newArrayWithoutRequestId);
            } else {
              let filteredData;
              switch (categoryName) {
                case "Air Fare":
                  filteredData = res.data.filter(
                    (item) => item.transportType === "flight"
                  );
                  break;
                case "Bus Fare":
                  filteredData = res.data.filter(
                    (item) => item.transportType === "bus"
                  );
                  break;
                case "Train Fare":
                  filteredData = res.data.filter(
                    (item) => item.transportType === "train"
                  );
                  break;
                case "Car Rental":
                  filteredData = res.data.filter(
                    (item) => item.transportType === "carRental"
                  );
                  break;
                case "Taxi Fare":
                  filteredData = res.data.filter(
                    (item) => item.transportType === "taxi"
                  );
                  break;
                default:
                  filteredData = [];
                  break;
              }
              if (filteredData.length > 0) {
                setModalOpen(true);
                const convertedArray = convertDatesInArray(
                  filteredData[0]?.trips || filteredData
                );
                setModalData(convertedArray);
              } else {
                message.info("No Data Found");
              }
            }
          } else {
            message.error("Failed to fetch updated data");
          }
        })
        .catch((error) => {
          message.error("An error occurred: " + error);
        });
    }, [selectedData, estimatedCost, categoryName, data?.requestId]);

    const handleMenuClick = useCallback((e) => {
      setSelectedTabIndex(parseInt(e.key, 10));
    }, []);

    const handleCostChange = (e) => {
      setEstimatedCost(e.target.value);
    };

    const shouldShowCostInputAndSaveButton =
      categoryName !== "Travel Advance" &&
      categoryName !== "Perdiem" &&
      (sessionStorage.getItem("aSF") === "2" ||
        sessionStorage.getItem("aSF") === "3") &&
      restrictedFlag?.expenseAdminFlag?.estimatedCost === 1;

    return (
      <Modal
        visible={visible}
        onCancel={onCancel}
        footer={false}
        width={760} // Adjust width if needed
        destroyOnClose // Ensure the modal is destroyed when closed
      >
        <div>
          {modalData.length >= 2 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1rem 2rem 0 0",
              }}
            >
              <Menu
                style={{
                  width: "100%",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                selectedKeys={[selectedTabIndex.toString()]}
                onClick={handleMenuClick}
              >
                {modalData.map((item, index) => (
                  <Menu.Item
                    style={{ textAlign: "center", color: "#9AA4B5" }}
                    key={index}
                  >
                    {categoryName} {index + 1}
                  </Menu.Item>
                ))}
              </Menu>
            </div>
          )}

          <div
            style={{
              fontSize: "20px",
              fontWeight: "800",
              color: "#344767",
              marginTop: "1.2rem",
              marginBottom: "1.2rem",
            }}
          >
            Detailed Information:
          </div>
          <div style={{ padding: "0.5rem 0 0 0" }}>
            <Row gutter={[16, 16]}>
              {selectedData &&
                Object.entries(selectedData)
                  .filter(([key]) => key !== "id")
                  .map(([key, value]) => (
                    <Col span={8} key={key}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "700",
                            color: "#344767",
                            fontSize: "14px",
                          }}
                        >
                          {camelToNormal(key)}
                        </span>
                        <span>{value}</span>
                      </div>
                    </Col>
                  ))}

              {shouldShowCostInputAndSaveButton && (
                <Col span={8}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span
                      style={{
                        fontWeight: "700",
                        color: "#344767",
                        fontSize: "14px",
                      }}
                    >
                      Estimated Cost
                    </span>
                    <input
                      type="number"
                      style={{
                        padding: "0.5rem",
                        fontSize: "14px",
                        marginTop: "0.5rem",
                        borderRadius: "4px",
                        border: "1px solid #ccc",
                      }}
                      value={estimatedCost}
                      onChange={handleCostChange}
                    />
                  </div>
                </Col>
              )}
            </Row>

            {shouldShowCostInputAndSaveButton && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  style={{
                    width: "8.5rem",
                    backgroundColor: "#3052D0",
                    border: "none",
                    color: "white",
                  }}
                  onClick={handleSaveWithEstimateCost}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    getApprovedetails(state?.id, "travel").then((res) => {
      if (res.responseCode === 200) {
        setData(res);
        setTotal(res?.totalCost);
        setCurrency(res?.currency);
        setDetails(res.data);
        setRequestPolicy(res.requestPolicy);
      }
    });
  }, [apiCall]);
  useEffect(() => {
    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleIconClick = (itemName) => {
    setCategoryName(itemName.expenseType);
    if (itemName.expenseType === "Travel Advance") {
      getCashInAdvance(data.requestId, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          let arr = [res.data];
          let obj = {
            "Cash In Advance Amount": res?.data?.cashInAdvance,
            "Cash Advance Reason": res?.data?.reasonCashInAdvance,
          };
          // let arr = [];
          // arr.push(obj);
          setModalData(arr);
          setModalOpen(true);
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Hotel Fare") {
      getHotelData(data.requestId, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          if (res.data.length > 0) {
            const updatedFormData = res.data.map((obj) => {
              // Rename keys using object destructuring
              const {
                startDate: checkIn,
                endDate: checkOut,
                estimatedCost: totalCost,
                ...rest
              } = obj;

              // Calculate total nights between checkIn and checkOut dates
              const checkInDate = new Date(checkIn);
              const checkOutDate = new Date(checkOut);
              const timeDifference =
                checkOutDate.getTime() - checkInDate.getTime();
              const totalNights = Math.ceil(
                timeDifference / (1000 * 3600 * 24)
              ); // Calculate total nights
              let estimatedDailyCost;
              if (totalNights > 0) {
                estimatedDailyCost = totalCost / totalNights;
              } else {
                estimatedDailyCost = totalCost;
              }
              // Create a new object with renamed keys, totalNights, and other properties
              const updatedObj = {
                checkIn,
                checkOut,
                totalNights,
                totalCost,
                estimatedDailyCost,
                ...rest, // Spread remaining properties from the original object
              };

              return updatedObj;
            });
            setModalOpen(true);
            const convertedArray = convertDatesInArray(updatedFormData);
            const newArrayWithoutRequestId =
              removeRequestIdFromArray(convertedArray);
            setModalData(newArrayWithoutRequestId);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Air Fare") {
      getTransport(data.requestId, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          let filterDataflight = res.data?.filter(
            (item) => item.transportType === "flight"
          );
          if (filterDataflight.length > 0) {
            setModalOpen(true);
            const convertedArray = convertDatesInArray(
              filterDataflight[0].trips
            );
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Bus Fare") {
      getTransport(data.requestId, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          let busData = res.data?.filter(
            (item) => item.transportType === "bus"
          );
          if (busData.length > 0) {
            setModalOpen(true);
            const convertedArray = convertDatesInArray(busData[0]?.trips);
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Train Fare") {
      getTransport(data.requestId, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          let trainData = res.data?.filter(
            (item) => item.transportType === "train"
          );
          if (trainData.length > 0) {
            setModalOpen(true);
            const convertedArray = convertDatesInArray(trainData[0].trips);
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Car Rental") {
      getTransport(data.requestId, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          let carRentalData = res.data?.filter(
            (item) => item.transportType === "carRental"
          );
          if (carRentalData.length > 0) {
            setModalOpen(true);
            delete carRentalData[0].transportType;
            const convertedArray = convertDatesInArray(carRentalData);
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Taxi Fare") {
      getTransport(data.requestId, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          let taxiData = res.data?.filter(
            (item) => item.transportType === "taxi"
          );
          if (taxiData.length > 0) {
            setModalOpen(true);
            delete taxiData[0].transportType;
            // const convertedArray = convertDatesInArray(taxiData);

            setModalData(taxiData);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Perdiem") {
      getPerDiem(data.requestId, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          // let filterDataflight = res.data?.filter(
          //   (item) => item.transportType === "flight"
          // );
          if (res.data.length > 0) {
            setModalOpen(true);
            const convertedArray = convertDatesInArray(res.data);
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    }
  };
  const handleModalCancel = () => {
    setApiCall((prev)=>prev+1)
    setModalOpen(false);
  };
  const toApprove = () => {
    setSubmitstatus("Approving...");

    setSpinner(true);
    if (data.requestId) {
      let obj = {
        requestId: data ? data.requestId : "",
        status: status,
        comment: comment,
      };

      approval(obj, urlLinkPart).then((res) => {
        if (res.responseCode === 200) {
          setSpinner(false);
          message.success("Approved Successfully");
          navigate("/tobeapprove");
        } else {
          setSpinner(false);
          message.error(res.responseMessage);
        }
      });
    } else {
      setSpinner(false);

      message.error("Request ID not found");
    }
  };
  const onSendBack = () => {
    setSubmitstatus("Send Back...");
    setSpinner(true);

    if (data.requestId && comment !== undefined && comment !== "") {
      let obj = {
        requestId: data ? data.requestId : "",
        status: "rejected",
        comment: comment,
        employeeId: sessionStorage.getItem("employeeId"),
      };
      sendBackByManager(obj).then((res) => {
        if (res.responseCode === 200) {
          setSpinner(false);

          message.success("Request has been Send Back Successfully");
          navigate("/tobeapprove");
        } else {
          setSpinner(false);

          message.error(res.responseMessage);
        }
      });
    } else {
      setSpinner(false);

      message.error("Please Add Comment ");
    }
  };
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={false}
            travel={true}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={false} travel={true} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between", // Aligns items at the start and end of the container
              gap: "1rem",
              marginLeft: "2rem",
              marginTop: "2rem",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "15px",
                  marginRight: "0.3rem",
                }}
              >
                Request ID :
              </span>
              <span style={{ fontSize: "15px", color: "#3052D0" }}>
                {data ? data.requestId : ""}
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "15px",
                  marginRight: "0.3rem",
                }}
              >
                Request Name :
              </span>
              <span style={{ fontSize: "15px", color: "#3052D0" }}>
                {data ? data.requestName : ""}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <Button
                style={{
                  width: "8.5rem",
                  backgroundColor: "#3052D0",
                  border: "none",
                  color: "white",
                }}
                onClick={() => toApprove()}
              >
                Approve
              </Button>
              <Button
                style={{
                  width: "8.5rem",
                  backgroundColor: "transparent",
                  border: "1px solid red",
                  color: "red",
                  marginRight: "0.5rem",
                }}
                onClick={() => onSendBack()}
              >
                Send back
              </Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "2rem",
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: "15px",
                marginRight: "0.3rem",
              }}
            >
              Total:
            </span>
            {total < 0 ? (
              <span style={{ color: "red", fontWeight: "600" }}>
                {currency}&nbsp;
                {total}
              </span>
            ) : (
              <span style={{ color: "#1A932E", fontWeight: "600" }}>
                {currency}&nbsp;
                {total}
              </span>
            )}
          </div>
          <div
            style={{
              backgroundColor: "white",
              display: "block",
              padding: "1rem",
              marginRight: "1.5rem",
              borderRadius: "15px",
              marginTop: "1rem",
              height: "61vh",
              overflow: "auto",
            }}
          >
            <Spin spinning={spinner} tip={submitstatus} size="large">
              <div>
                <div style={{ paddingLeft: "2rem", marginTop: "0.5rem" }}>
                  <Row>
                    <Col lg={12}>
                      <span style={{ fontSize: "15px", fontWeight: "700" }}>
                        Category
                      </span>
                    </Col>
                    <Col lg={12}>
                      <span style={{ fontSize: "15px", fontWeight: "700" }}>
                        Amount
                      </span>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginRight: "10rem",
                    }}
                  />
                  <div
                    style={{
                      // marginTop: "1rem",
                      height: "33vh",
                      // overflow: "auto",
                    }}
                    className="scrollable-container"
                  >
                    {details?.map((item, index) => (
                      <Row style={{ marginTop: "0.7rem" }}>
                        <Col lg={12}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <FaCircle
                              style={{
                                color: "#3052D0",
                                fontSize: "10px",
                                marginTop: "0.4rem",
                                marginRight: "0.7rem",
                              }}
                            />
                            <div
                              className=""
                              style={{
                                border:
                                  "0.596px solid rgba(123, 128, 154, 0.40)",
                                padding: "5px 0 5px 10px",
                                width: "70%",
                                borderRadius: "5px",
                              }}
                            >
                              <span
                                role="img"
                                aria-label="Icon"
                                style={{
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleIconClick(item)}
                              >
                                <GrStatusInfo style={{ color: "#3052D0" }} />
                              </span>
                              <span
                                style={{ color: "#344767", fontWeight: "700" }}
                              >
                                {item.expenseType}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <span style={{ color: "#1A932E", fontWeight: "600" }}>
                            {item.amount}
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    marginLeft: "1.7rem",
                    marginTop: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontWeight: "500", fontSize: "16px" }}>
                    Comment
                  </span>
                  <TextArea
                    style={{
                      width: "60vw",
                      backgroundColor: "#FAFAFC",
                      marginTop: "0.2rem",
                      resize: "none",
                    }}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                    placeholder="Please describe your comments here"
                  />
                </div>
              </div>
            </Spin>
          </div>
        </Col>
      </Row>
      <Footer />
      <DynamicContentModal
        visible={modalOpen}
        onCancel={handleModalCancel}
        dataArray={modalData}
        categoryName={categoryName}
        restrictedFlag={restrictedFlag}
      />
    </div>
  );
}

export default Details;
