import React, { useEffect, useRef, useState } from "react";
import Footer from "../../Footer";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import SideBar from "../../SideBar";
import Header from "../../Header";
import { claimno } from "../../../redux/actions";
import pic from "../../CostenDemo/_Sticker.png";

import {
  FileTextOutlined,
  CloudUploadOutlined,
  CloseCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./custom-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ClaimcancelRequest,
  PreviewFile,
  costenDriveget,
  creditCardInsert,
  creditCardOcr,
  getCategories,
  getCreditCard,
} from "../../../services/ApiService";
function formatDate(date) {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return "";
}
const initialFormData = {
  transactionDetail: "",
  date: null,
  amount: "",
  expenseType: "",
  selfDeclaration: 0,
  childCategory: "",
  childSubCategory: "",
};
function CreditCard() {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [modalFile, setModalFile] = useState(null);
  const [modalFileType, setModalFileType] = useState("");
  const [file, setFile] = useState(null);
  const id = state?.id;
  const categoryFromMain = state?.category ? state.category : "";
  const [collapsed, setCollapsed] = useState(false);
  const [declaration, setDeclaration] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const yourData = queryParams.get("data");
  const reduxclaimno = useSelector((state) => state.claimno);
  const [claimNo, setClaimNo] = useState();
  const allowedFormats = ["pdf", "jpeg", "jpg", "heic", "png"];
  const [openpreview, setOpenpreview] = useState(false);
  const [receiptUploaded, setReceiptUploaded] = useState(false);
  const [fileName, setFileName] = useState();
  const [originalFileName, setOriginalFileName] = useState();
  const [formData, setFormData] = useState([]);
  const [indexformulticosten, setIndexformulticosten] = useState();
  const [costenModal, setCostenModal] = useState(false);

  const [updatedData, setUpdatedData] = useState([
    { ...initialFormData, id: 0 },
  ]);
  const [password, setPassword] = useState();
  const [processFile, setProcessFile] = useState();
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();
  const idCounter = useRef(1);
  const [showAddRow, setShowAddRow] = useState(false);
  const [categoriesData, setCategoriesData] = useState();
  const [category, setCategory] = useState([]);
  const [previewlink, setPreviewLink] = useState();
  const [billFileName, setBillFileName] = useState();
  useEffect(() => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = String(currentDate.getFullYear()).slice(-2);
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const formattedDateTime = `${day}${month}${year}${hours}${minutes}${seconds}`;
    const full = "CN" + formattedDateTime;
    if (!reduxclaimno) {
      dispatch(claimno(full));
    }
    setClaimNo(full);
  }, []);
  useEffect(() => {
    let obj = {
      claimNumber: reduxclaimno ? reduxclaimno : claimNo,
    };
    getCreditCard(obj).then((res) => {
      if (res.responseCode === 200) {
        const initialFormDataArray = res.data.map((obj) => ({
          transactionDetail: obj.transactionDetail,
          fileName: obj.fileName,
          originalFileName: obj.originalFileName,
          amount: obj.amount,
          date: obj.date !== null ? new Date(obj?.date) : null,
          expenseType: obj.expenseType,
          selfDeclaration: obj.selfDeclaration,
          childCategory: obj.childCategory,
          childSubCategory: obj.childSubCategory,
        }));
        setFormData(initialFormDataArray);
        setUpdatedData(initialFormDataArray);
      } else {
        message.error(res.responseMessage);
      }
    });
    getCategories().then((res) => {
      if (res.responseCode === 200) {
        setCategoriesData(res.data);

        let arr = Object.keys(res.data);
        setCategory(arr);
        let selected = "Communication";
        // for (const key in data) {
        //   if (key === selected) {
        //     let value = data[key];
        //     console.log(value);
        //   }
        // }
      } else {
        message.error(res.responseMessage);
      }
    });
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const onFileChange = (e) => {
    setReceiptUploaded(true);
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop().toLowerCase();
      if (allowedFormats.includes(fileType)) {
        setProcessFile(selectedFile);
        setFile(selectedFile);
        setModalFile(selectedFile);
        setModalFileType(fileType);
        // setFileType(fileType);
      } else {
        message.info("Accepted files formats : JPEG, JPG, PDF, HEIC, PNG.");

        // alert(
        //   "Invalid file format. Please upload a PDF, JPEG, JPG, or HEIC file."
        // );
      }
    }
  };
  const handleDownloadClick = (backendLink) => {
    window.open(backendLink, "_blank");
  };
  const previewStyle = {
    maxWidth: "100%",
    maxHeight: "400px",
    margin: "20px 0",
  };
  const getNewForm = () => ({
    ...initialFormData,
    // id: idCounter.current++, // Generate a unique ID
  });

  const handleAddForm = () => {
    // if (formData.length < 5) {
    setFormData([...formData, getNewForm()]);
    setUpdatedData([...updatedData, getNewForm()]);
    // }
  };

  const handleRemoveForm = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);

    const newUpdatedData = [...updatedData];
    newUpdatedData.splice(index, 1);
    setUpdatedData(newUpdatedData);
  };

  const handleDateChange = (index, field, date) => {
    const newFormData = [...formData];
    newFormData[index][field] = date;
    setFormData(newFormData);

    const newUpdatedData = [...updatedData];
    newUpdatedData[index][field] = date;
    setUpdatedData(newUpdatedData);
  };

  const handleInputChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);

    const newUpdatedData = [...updatedData];
    newUpdatedData[index][field] = value;
    setUpdatedData(newUpdatedData);
  };
  const [errorLogged, setErrorLogged] = useState(false);
  function validateArrayObjects(array) {
    for (let obj of array) {
      if (
        !obj.hasOwnProperty("childCategory") ||
        !obj.hasOwnProperty("date") ||
        !obj.hasOwnProperty("expenseType") ||
        // !obj.hasOwnProperty('selfDeclaration') ||
        !obj.hasOwnProperty("childSubCategory") ||
        !obj.hasOwnProperty("transactionDetail") ||
        !obj.hasOwnProperty("amount") ||
        obj.childCategory === "" ||
        obj.date === "" ||
        obj.expenseType === "" ||
        obj.selfDeclaration === "" ||
        obj.childSubCategory === "" ||
        obj.transactionDetail === "" ||
        obj.amount === ""
      ) {
        return false; // if any required key is missing or has an empty value
      }
    }
    return true; // all objects have all required keys with non-empty values
  }
  const handleSave = () => {
    setErrorLogged(false);
    const formattedData = updatedData.map((data) => ({
      ...data,
      date: formatDate(data.date),
    }));
    formattedData.map((obj) => {
      // Copy non-empty values to the new object
      for (const key in obj) {
        if (
          obj["fileName"] === "" ||
          obj["originalFileName"] === "" ||
          obj["fileName"] === null ||
          obj["originalFileName"] === ""
        ) {
          delete obj["fileName"];
          delete obj["originalFileName"];
        } else if (obj["file"] === "" || obj["file"] === null) {
          delete obj["file"];
        }
      }
    });
    const missingBothKeysWithSelfDeclaration = formattedData.filter(
      (obj) => !obj.file && !obj.fileName && obj.selfDeclaration !== 1
    );
    if (
      missingBothKeysWithSelfDeclaration.length > 0 ||
      !validateArrayObjects(formattedData)
    ) {
      message.info("All Fields are required");
    } else {
      const formDatatry = new FormData();
      if (formattedData.length > 0) {
        formattedData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            formDatatry.append(`objects[${index}][${key}]`, value);
          });
        });
        formDatatry.append(
          "claimNumber",
          reduxclaimno ? reduxclaimno : claimNo
        );
        formDatatry.append(
          "category",
          "Credit Card-Import Corporate Credit Card Statement"
        );
        formDatatry.append("userId", sessionStorage.getItem("employeeId"));
        creditCardInsert(formDatatry).then((res) => {
          if (res.responseCode === 200) {
            message.success("Saved");
            navigate("/creditcard-mainpage");
          } else {
            message.error(res.responseMessage);
          }
        });
      } else {
        message.error("Add full data to save");
      }
    }
  };
  const onFileChangeMulti = (index, field, e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop().toLowerCase();

      if (allowedFormats.includes(fileType)) {
        const newFormData = [...formData];
        newFormData[index][field] = selectedFile;
        newFormData[index]["fileName"] = "";
        newFormData[index]["originalFileName"] = "";

        setFormData(newFormData);

        const newUpdatedData = [...updatedData];
        newUpdatedData[index][field] = selectedFile;
        newUpdatedData[index]["fileName"] = "";
        newUpdatedData[index]["originalFileName"] = "";
        setUpdatedData(newUpdatedData);
        formData["file"] = selectedFile;
        setModalFile(selectedFile);
      } else {
        message.info("Accepted files formats : JPEG, JPG, PDF, HEIC, PNG.");

        // alert(
        //   "Invalid file format. Please upload a PDF, JPEG, JPG, or HEIC file."
        // );
      }
    }
  };

  const StatementProcess = () => {
    setSpinner(true);
    const formDataprocess = new FormData();
    formDataprocess.append("password", password ? password : "");
    formDataprocess.append("file", processFile);
    creditCardOcr(formDataprocess).then((res) => {
      if (res.responseCode === 200) {
        setSpinner(false);
        if (res.data.length > 0) {
          const initialFormDataArray = res.data.map((obj) => ({
            transactionDetail: obj.transactionDetails || "",
            date: obj.date !== null ? new Date(obj?.date) : null,
            amount: obj.amount || "",
            expenseType: "corporateCard",
          }));
          setFormData(initialFormDataArray);
          setUpdatedData(initialFormDataArray);
        }
      } else {
        setSpinner(false);
        message.error(res.responseMessage);
      }
    });
    // let data=[
    //     {
    //         transactionDetail: "test1",
    //         transactionDate: "Mon, 08 Jan 2024 00:00:00 GMT",
    //         amount: 21,
    //         expenseType: "cash"

    //     },{
    //         transactionDetail: "test2",
    //         transactionDate: "Mon, 08 Jan 2024 00:00:00 GMT",
    //         amount: 22,
    //         expenseType: "cash"
    //     }

    // ]
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const onCancel = () => {
    if (reduxclaimno) {
      let body = {
        claimNumber: reduxclaimno,
      };
      ClaimcancelRequest(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Request Canceled Successfully");
          navigate("/dashboard-expense");
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      navigate("/dashboard-expense");
    }
  };
  function getFileExtension(url) {
    const path = url.substring(url.lastIndexOf("/") + 1);
    const extension = path.substring(path.lastIndexOf(".") + 1);
    return extension;
  }
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);

  const handleCheckboxChange = (itemId) => {
    // When a checkbox is clicked, update the selectedItemId state
    setSelectedItemId(itemId);

    // Find the details of the selected item and update the state
    const selectedItem = data.find((item) => item.id === itemId);
    setSelectedItemDetails(selectedItem);
  };

  const handleUpload = () => {
    if (selectedItemDetails) {
      // const ocrform = new FormData();

      // ocrform.append("file", selectedItemDetails.previewLink);
      setCostenModal(false);
      // setSpinner(true);

      formData[indexformulticosten]["originalFileName"] =
        selectedItemDetails.originalFileName;
      formData[indexformulticosten]["fileName"] = selectedItemDetails.fileName;
      updatedData[indexformulticosten]["originalFileName"] =
        selectedItemDetails.originalFileName;
      updatedData[indexformulticosten]["fileName"] =
        selectedItemDetails.fileName;
      updatedData[indexformulticosten]["file"] = "";
      formData[indexformulticosten]["file"] = "";
      // ocr(ocrform)
      //   .then((res) => {
      //     if (res.responseCode === 200) {
      //       setSpinner(false);
      //       const newFormData = [...formData];
      //       newFormData[indexformulticosten]["billAmount"] =
      //         res.fileData.billAmount;
      //       newFormData[indexformulticosten]["billDate"] =
      //         res.fileData.billDate !== null
      //           ? new Date(res.fileData?.billDate)
      //           : null;
      //       newFormData[indexformulticosten]["billNumber"] =
      //         res?.fileData?.billNumber === null
      //           ? ""
      //           : res?.fileData?.billNumber;
      //       newFormData[indexformulticosten]["establishmentName"] =
      //         res.fileData.establishmentName;

      //       setFormData(newFormData);

      //       const newUpdatedData = [...updatedData];
      //       newUpdatedData[indexformulticosten]["billAmount"] =
      //         res.fileData.billAmount;
      //       newUpdatedData[indexformulticosten]["billDate"] =
      //         res.fileData.billDate !== null
      //           ? new Date(res.fileData?.billDate)
      //           : null;
      //       newUpdatedData[indexformulticosten]["billNumber"] =
      //         res.fileData.billNumber;
      //       newUpdatedData[indexformulticosten]["establishmentName"] =
      //         res.fileData.establishmentName;
      //       setUpdatedData(newUpdatedData);
      //     } else {
      //       setSpinner(false);
      //       message.error(res.responseMessage);
      //     }
      //   })
      //   .catch((error) => {
      //     setSpinner(false);
      //     message.error(error);
      //   });
    } else {
      message.info("Select before clicking Upload");
    }
  };

  const [data, setData] = useState([]);
  const handleCostenDriveModal = (index) => {
    setIndexformulticosten(index);
    setCostenModal(true);
    const headers = {
      organization: sessionStorage.getItem("organization"),
      employeeId: sessionStorage.getItem("employeeId"),
    };
    costenDriveget(headers).then((responseData) => {
      if (responseData.responseCode === 200) {
        setData(responseData.data);
      } else {
        message.error(responseData.responseMessage);
      }
    });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    // Filter data based on search query whenever searchQuery changes
    const filtered = data.filter((item) =>
      item.originalFileName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [data, searchQuery]);
  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 4 : 2}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={true}
            travel={false}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 20 : 22}
        >
          <div style={{ marginLeft: "1.5rem" }}>
            <Header expense={true} travel={false} />
            <>
              <Spin spinning={spinner} tip="Processing..." size="large">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5rem",
                    marginBottom: "0.5rem",
                    marginTop: "1.3rem",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                      marginLeft: "1.4rem",
                    }}
                  >
                    <span style={{ fontWeight: "600" }}>Claim No:</span>
                    <span style={{ color: "#3052D0", fontWeight: "500" }}>
                      {reduxclaimno ? reduxclaimno : claimNo}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.3rem",
                      marginRight: "2.7rem",
                    }}
                  >
                    <span style={{ fontWeight: "700" }}>Status : </span>
                    <div
                      style={{
                        color: "#4CAF50",
                        padding: "1px 3px 1px 3px",
                        borderRadius: "4px",
                        backgroundColor: " rgba(76, 175, 80, 0.2)",
                      }}
                    >
                      Initiated
                    </div>
                  </div>
                </div>
                <Row style={{ marginTop: "1rem" }}>
                  <Col lg={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <label
                        style={{
                          width: "70%",
                          height: "33px",
                          backgroundColor: "transparent",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "15px",
                          cursor: "pointer",
                          border: "1px solid grey",
                        }}
                      >
                        <input
                          type="file"
                          accept=".pdf, .jpeg, .jpg, .heic, .png"
                          onChange={onFileChange}
                          style={{ display: "none" }}
                        />
                        <span style={{ color: "grey" }}>
                          Browse To Select File
                        </span>
                        <CloudUploadOutlined
                          style={{
                            color: "grey",
                            fontSize: "20px",
                            marginLeft: "0.2rem",
                          }}
                        />
                      </label>
                      {file ? (
                        <div
                          onClick={() => {
                            setModalFile(file);
                            setModalFileType(
                              file?.name.split(".").pop().toLowerCase()
                            );
                            setOpenpreview(true);
                          }}
                          style={{
                            width: "20vw",
                            overflow: "auto",
                            marginTop: "0.8rem",
                            display: "flex",
                            flexDirection: "row",
                            cursor: "pointer",
                          }}
                        >
                          <FileTextOutlined style={{ fontSize: "15px" }} />
                          <span> {file?.name.slice(0, 22)}...</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <Input
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ width: "70%" }}
                      placeholder="Enter File Password"
                    />
                  </Col>
                  <Col lg={6}>
                    <Button
                      style={{ backgroundColor: "#3052D0", color: "white" }}
                      onClick={() => {
                        StatementProcess();
                      }}
                      disabled={!processFile}
                    >
                      Statement Process
                    </Button>
                  </Col>
                </Row>
                {formData.length > 0 ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      height: "64vh",
                      borderRadius: "15px",
                      padding: "0.3rem 1rem 1rem 1rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    <div>
                      <Row style={{ marginBottom: "1rem" }}>
                        <Col lg={4}>
                          <span
                            style={{ fontWeight: "600", marginLeft: "0.7rem" }}
                          >
                            Transaction Details
                          </span>
                        </Col>
                        <Col lg={3}>
                          <span style={{ fontWeight: "600" }}>
                            Transaction Date
                          </span>
                        </Col>
                        <Col lg={2}>
                          <span style={{ fontWeight: "600" }}>Amount</span>
                        </Col>
                        <Col lg={3}>
                          <span style={{ fontWeight: "600" }}>
                            Expense Type
                          </span>
                        </Col>
                        <Col lg={3}>
                          <span style={{ fontWeight: "600" }}>Category</span>
                        </Col>
                        <Col lg={3}>
                          <span style={{ fontWeight: "600" }}>
                            Sub Category
                          </span>
                        </Col>
                        <Col lg={3}>
                          <span style={{ fontWeight: "600" }}>Upload</span>
                        </Col>
                        <Col lg={2}>
                          {" "}
                          <Link
                            onClick={() => setDeclaration(true)}
                            style={{
                              color: "#E93B77",
                              // textDecoration: "underline",
                            }}
                          >
                            *No bill
                          </Link>
                        </Col>
                      </Row>
                      <div>
                        <div style={{ height: "40vh", overflow: "auto" }}>
                          {formData.map((form, index) => (
                            <Row style={{ marginBottom: "1rem" }}>
                              <Col lg={4}>
                                {" "}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <span
                                    style={{
                                      marginTop: "0.5rem",
                                      marginRight: "0.1rem",
                                      color: "#3052D0",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {index + 1}.
                                  </span>
                                  <input
                                    style={{ width: "80%" }}
                                    className="inputclass"
                                    type="text"
                                    value={form.transactionDetail}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "transactionDetail",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter Transaction Detail"
                                  />
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div className="custom-datepicker">
                                  <DatePicker
                                    className="inputclasscredit"
                                    selected={form.date}
                                    onChange={(date) =>
                                      handleDateChange(index, "date", date)
                                    }
                                    placeholderText="Select Transaction Date"
                                  />
                                </div>
                              </Col>
                              <Col lg={2}>
                                {" "}
                                <input
                                  className="inputclass"
                                  type="text"
                                  value={form.amount}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "amount",
                                      e.target.value
                                    )
                                  }
                                  style={{ width: "70%" }}
                                  placeholder="Enter Amount"
                                />
                              </Col>
                              <Col lg={3}>
                                {" "}
                                <Select
                                  defaultValue="cash"
                                  value={
                                    !form.expenseType ? "" : form.expenseType
                                  }
                                  onChange={(e) => {
                                    handleInputChange(index, "expenseType", e);
                                  }}
                                  style={{ width: "9vw" }}
                                  placeholder="Select Expense Type"
                                >
                                  <Select.Option
                                    key="corporateCard"
                                    value="corporateCard"
                                  >
                                    Corporate Card
                                  </Select.Option>
                                  <Select.Option
                                    key="personalExpense"
                                    value="cash"
                                  >
                                    Personal Expense
                                  </Select.Option>
                                </Select>
                              </Col>
                              <Col lg={3}>
                                {" "}
                                <Select
                                  value={form.childCategory}
                                  listItemHeight={10}
                                  listHeight={200}
                                  showSearch
                                  onChange={(value) =>
                                    handleInputChange(
                                      index,
                                      "childCategory",
                                      value
                                    )
                                  }
                                  onSearch={onSearch}
                                  filterOption={filterOption}
                                  placeholder="Select Category"
                                  style={{ width: "9vw" }}
                                  virtual={true}
                                >
                                  {category.map((option) => (
                                    <Select.Option key={option} value={option}>
                                      {option}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Col>
                              <Col lg={3}>
                                {" "}
                                <Select
                                  value={form.childSubCategory}
                                  listItemHeight={10}
                                  listHeight={200}
                                  showSearch
                                  onChange={(value) =>
                                    handleInputChange(
                                      index,
                                      "childSubCategory",
                                      value
                                    )
                                  }
                                  onSearch={onSearch}
                                  filterOption={filterOption}
                                  placeholder="Select Sub Category"
                                  style={{ width: "11vw" }}
                                  virtual={true}
                                >
                                  {category.length > 0 &&
                                    categoriesData[form?.childCategory]?.map(
                                      (option) => (
                                        <Select.Option
                                          key={option}
                                          value={option}
                                        >
                                          {option}
                                        </Select.Option>
                                      )
                                    )}
                                </Select>
                              </Col>
                              <Col lg={3}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",

                                    gap: "0.2rem",
                                    marginLeft: "0.7rem",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {" "}
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          <Menu.Item
                                            key={index + 1}
                                            disabled={
                                              form.selfDeclaration === 1
                                                ? true
                                                : false
                                            }
                                          >
                                            <label
                                              style={{
                                                fontWeight: "600",
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                              onClick={() =>
                                                handleCostenDriveModal(index)
                                              }
                                            >
                                              Upload from Costen Drive
                                            </label>
                                          </Menu.Item>
                                          <Menu.Item
                                            key={index + 2}
                                            disabled={
                                              form.selfDeclaration === 1
                                                ? true
                                                : false
                                            }
                                          >
                                            <label
                                              style={{
                                                fontWeight: "600",
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <input
                                                type="file"
                                                accept=".pdf, .jpeg, .jpg, .heic, .png"
                                                onChange={(e) => {
                                                  onFileChangeMulti(
                                                    index,
                                                    "file",
                                                    e
                                                  );
                                                }}
                                                style={{ display: "none" }}
                                                disabled={
                                                  form.selfDeclaration === 1
                                                    ? true
                                                    : false
                                                }
                                              />
                                              Upload from PC
                                            </label>
                                          </Menu.Item>
                                        </Menu>
                                      }
                                      trigger={["click"]}
                                    >
                                      <label
                                        style={{
                                          fontWeight: "600",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <UploadOutlined
                                          style={{
                                            marginLeft: "1rem",
                                            marginTop: "0.4rem",
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            color: "#E93B77",
                                          }}
                                          disabled={
                                            form.selfDeclaration === 1
                                              ? true
                                              : false
                                          }
                                        />
                                      </label>
                                    </Dropdown>
                                    {form.file ? (
                                      <div
                                        onClick={() => {
                                          setModalFile(form.file);
                                          setModalFileType(
                                            form?.file?.name
                                              .split(".")
                                              .pop()
                                              .toLowerCase()
                                          );
                                          setOpenpreview(true);
                                        }}
                                        style={{
                                          // marginTop: "1.2rem",
                                          display: "flex",
                                          flexDirection: "row",
                                          cursor: "pointer",
                                          overflow: "auto",
                                        }}
                                      >
                                        <FileTextOutlined
                                          style={{ fontSize: "15px" }}
                                        />
                                        <span>
                                          {" "}
                                          {form.file?.name.slice(0, 5)}...
                                        </span>
                                      </div>
                                    ) : form.fileName ? (
                                      <div
                                        onClick={() => {
                                          PreviewFile(form.fileName).then(
                                            (res) => {
                                              setPreviewLink(res.url);
                                              setBillFileName(
                                                form?.originalFileName
                                              );
                                              setModalFileType(
                                                getFileExtension(res.url)
                                              );
                                              setOpenpreview(true);
                                            }
                                          );
                                        }}
                                        style={{
                                          // marginTop: "1.2rem",
                                          display: "flex",
                                          flexDirection: "row",
                                          cursor: "pointer",
                                          overflow: "auto",
                                        }}
                                      >
                                        <FileTextOutlined
                                          style={{ fontSize: "15px" }}
                                        />
                                        <span>
                                          {" "}
                                          {form?.originalFileName?.slice(0, 5)}
                                          ..
                                        </span>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </Col>

                              <Col lg={2}>
                                <Checkbox
                                  style={{
                                    marginLeft: "1.4rem",
                                    marginTop: "0.3rem",
                                  }}
                                  disabled={form.file || form.fileName}
                                  checked={
                                    form.selfDeclaration === 1 ? true : false
                                  }
                                  onChange={(e) =>
                                    // console.log(e)
                                    handleInputChange(
                                      index,
                                      "selfDeclaration",
                                      e.target.checked === true ? 1 : 0
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={1}>
                                {" "}
                                <CloseCircleOutlined
                                  style={{
                                    color: "red",
                                    fontSize: "1.5rem",
                                    marginTop: "0.1rem",
                                  }}
                                  onClick={() => handleRemoveForm(index)}
                                />
                              </Col>
                            </Row>
                          ))}
                        </div>
                        {formData.length > 0 ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                onClick={handleAddForm}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  gap: "0.5rem",
                                  boxShadow:
                                    "0px 2px 6px 0px rgba(151, 172, 198, 0.25)",
                                  padding: "0.5rem",
                                  width: "15%",
                                  borderRadius: "60px",
                                  marginTop: "0.5rem",
                                  cursor: "pointer",
                                }}
                              >
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="Group 44">
                                    <rect
                                      id="bg - icon"
                                      width="32"
                                      height="32"
                                      rx="16"
                                      fill="#534ECC"
                                    />
                                    <path
                                      id="Vector"
                                      d="M19.6539 15.1538H15.8463V11.3462C15.8463 11.2543 15.8098 11.1663 15.7449 11.1014C15.68 11.0365 15.5919 11 15.5001 11C15.4083 11 15.3203 11.0365 15.2554 11.1014C15.1904 11.1663 15.154 11.2543 15.154 11.3462V15.1538H11.3464C11.2546 15.1538 11.1665 15.1903 11.1016 15.2552C11.0367 15.3201 11.0002 15.4082 11.0002 15.5C10.9985 15.545 11.0065 15.5898 11.0236 15.6315C11.0406 15.6731 11.0664 15.7106 11.0993 15.7415C11.1321 15.7723 11.1711 15.7957 11.2137 15.8102C11.2563 15.8247 11.3016 15.8299 11.3464 15.8254H15.154V19.6538C15.154 19.7457 15.1904 19.8337 15.2554 19.8986C15.3203 19.9635 15.4083 20 15.5001 20C15.5919 20 15.68 19.9635 15.7449 19.8986C15.8098 19.8337 15.8463 19.7457 15.8463 19.6538V15.8462H19.6539C19.7457 15.8462 19.8337 15.8097 19.8986 15.7448C19.9635 15.6799 20 15.5918 20 15.5C20 15.4082 19.9635 15.3201 19.8986 15.2552C19.8337 15.1903 19.7457 15.1538 19.6539 15.1538Z"
                                      fill="white"
                                    />
                                  </g>
                                </svg>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.3rem",
                                  }}
                                >
                                  <button
                                    style={{
                                      border: "none",
                                      backgroundColor: "white",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      padding: "0",
                                      cursor: "pointer",
                                      marginTop: "8px",
                                    }}
                                  >
                                    Add Row
                                  </button>
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      color: "#7B809A",
                                      fontWeight: "500",
                                    }}
                                  ></span>
                                </div>
                              </div>
                            </div>
                            <span style={{ color: "grey" }}>
                              *We do not store your credit card statement
                            </span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "1.5rem",
                              }}
                            >
                              <Button
                                style={{
                                  width: "8.5rem",
                                  backgroundColor: "darkgray",
                                  border: "none",
                                  color: "white",
                                  // marginRight: "1rem",
                                }}
                                onClick={() => navigate("/creditcard-mainpage")}
                              >
                                Back
                              </Button>
                              <Button
                                style={{
                                  width: "8.5rem",
                                  backgroundColor: "#3052D0",
                                  border: "none",
                                  color: "white",
                                }}
                                onClick={handleSave}
                              >
                                Save
                              </Button>
                              <Button
                                style={{
                                  width: "8.5rem",
                                  backgroundColor: "red",
                                  border: "none",
                                  color: "white",
                                }}
                                onClick={() => {
                                  onCancel();
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </Spin>
            </>
          </div>
        </Col>
      </Row>
      <Footer />
      <Modal
        styles={{ padding: "10px" }}
        centered={true}
        style={{ padding: "50px" }}
        open={declaration}
        onCancel={() => {
          setDeclaration(false);
          // setAgreed(false);
        }}
        footer={false}
      >
        <div>
          <span style={{ fontWeight: "500" }}>
            <br />
            I am formally seeking a reimbursement for services that I have
            availed of, although I lack an accompanying invoice.
            <br /> I affirm unequivocally that I have made use of the services
            in accordance with the agreed terms, and I am confident regarding
            the precise amount eligible for reimbursement. Regrettably, a bill
            or receipt cannot be submitted alongside this request, as the vendor
            did not provide one.
            <br /> I accept full responsibility for ensuring that the requested
            amount was utilized solely for business-related purposes.
          </span>
        </div>
      </Modal>
      {openpreview ? (
        <Modal
          title={modalFile ? modalFile.name : billFileName}
          centered={false}
          style={{ top: "0px" }}
          open={openpreview}
          onCancel={() => setOpenpreview(false)}
          footer={false}
          width={800}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            {modalFileType === "pdf" ? (
              <iframe
                src={modalFile ? URL.createObjectURL(modalFile) : previewlink}
                title="PDF Preview"
                width="100%"
                height="450px"
              />
            ) : (
              <img
                src={modalFile ? URL.createObjectURL(modalFile) : previewlink}
                alt="Preview"
                style={previewStyle}
              />
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
      <Modal
        // title={modalFile ? modalFile.name : billFileName}
        centered={false}
        style={{ top: "0px" }}
        open={costenModal}
        // title="Are you sure, want to Cancel the whole request"
        onCancel={() => {
          setCostenModal(false);
          setSelectedItemId(null);
          setSelectedItemDetails(null);
        }}
        footer={
          <Button
            className="bg-[#3052D0] text-white ml-5 mt-0 font-medium"
            onClick={handleUpload}
          >
            Upload
          </Button>
        }
        width={1000}
      >
        <div>
          {/* Search input */}
          <input
            className="inputclass"
            type="text"
            placeholder="Search by filename..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ padding: "5px", borderRadius: "15px", height: "5vh" }}
          />

          {/* Display filtered data */}
          <div className="grid grid-cols-4 h-96 md:h-96 scroll-m-3 scrollable-container mt-4">
            {filteredData.map((item, index) => (
              <div key={index}>
                <div
                  className="bg-slate-200 m-0 mt-2 ml-0 mr-0 p-8 w-40 h-38"
                  style={{ cursor: "pointer" }}
                >
                  {getFileExtension(item.previewLink) === "pdf" ? (
                    <iframe
                      src={item.previewLink}
                      title="PDF Preview"
                      className="w-40 h-36 ml-1 rounded-sm"
                      style={{ overflow: "hidden" }}
                    />
                  ) : (
                    <img
                      src={item.previewLink}
                      alt="Preview"
                      className="w-40 h-36 ml-1 rounded-sm"
                    />
                  )}
                </div>
                <div className="mb-4 bottom-0 rounded-lg flex w-56 justify-between bg-white">
                  <div className="flex shadow-sm">
                    <img src={pic} alt="img" className="h-10 w-10 shadow-sm" />
                    <h1 className="text-start text-sm ml-2 text-black font-normal">
                      {item.originalFileName}
                    </h1>
                  </div>
                  <input
                    type="checkbox"
                    id={`checkbox-${item.id}`}
                    value={item.id}
                    checked={selectedItemId === item.id}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CreditCard;
