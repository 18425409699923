import React, { useEffect, useState } from "react";
import { Col, Row, message } from "antd";

import Footer from "../Footer";
import Header from "../Header";
import SideBar from "../SideBar";
import ColData from "./ColData";
import CommonHeader from "./CommonHeader";
import axios from "axios";
import pic from "../CostenDemo/_Sticker.png";
import { Checkbox, Spin } from "antd";
import { costenDriveget } from "../../services/ApiService";
const ListViewMain = () => {
  const [collapsed, setCollapsed] = useState(false);
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const [sortClicked, setSortClicked] = useState("date");
  const [apiCall, setApiCall] = useState();
  const [data, setData] = useState();
  const [response, setresponse] = useState();
  const [storageUsed, setStorageUsed] = useState();
  const [imageCount, setimageCount] = useState();
  const [records, setRecords] = useState();
  const [spinning, setSpinning] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const trigger = (value) => {
    setApiCall(value);
  };
  const dataSort = (value) => {
    setSortClicked(value);
  };

  const [searchD, setsearchD] = useState("");
  var search = (value) => {
    setsearchD(value);
  };

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };


  useEffect(() => {
    setSpinning(true);
    let headers
    if (sortClicked === "date") {
    headers = {
        organization: sessionStorage.getItem("organization"),
        employeeId: sessionStorage.getItem("employeeId"),
        sortBy: "date",
      };
    } else if (sortClicked === "name") {
       headers = {
        organization: sessionStorage.getItem("organization"),
        employeeId: sessionStorage.getItem("employeeId"),
        sortBy: "name",
      };
    } else if (sortClicked === "search") {
       headers = {
        organization: sessionStorage.getItem("organization"),
        employeeId: sessionStorage.getItem("employeeId"),
        searchName: searchD,
      };
    } else {
       headers = {
        organization: sessionStorage.getItem("organization"),
        employeeId: sessionStorage.getItem("employeeId"),
      };
    }
    costenDriveget(headers)
      .then((responseData) => {
        if (responseData.responseCode === 200) {
          setSpinning(false);
          setData(responseData.data);
          setresponse(responseData);
          setStorageUsed(responseData.storageUsed);
          setimageCount(responseData.count);
        } else {
          setSpinning(false);
          message.error(responseData.responseMessage);
        }
      })
      .catch((error) => {
        console.error("Error in useEffect:", error);
      });
  }, [apiCall, sortClicked, searchD]);

  return (
    <div>
      <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
        <Row>
          <Col
            xs={!collapsed ? 19 : 11}
            sm={!collapsed ? 10 : 6}
            md={!collapsed ? 7 : 4}
            lg={!collapsed ? 6 : 5}
            xl={!collapsed ? 5 : 3}
          >
            <div
              style={{ position: "fixed", zIndex: "1" }}
              className={`toggle ${collapsed ? "collapsed" : ""}`}
              onClick={toggleSidebar}
            >
              <svg
                className={`arrow ${collapsed ? "collapsed" : ""}`}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                  fill="black"
                />
              </svg>
            </div>
            <SideBar collapsed={collapsed} />
          </Col>

          <Col
            xs={!collapsed ? 5 : 13}
            sm={!collapsed ? 14 : 18}
            md={!collapsed ? 17 : 20}
            lg={!collapsed ? 18 : 19}
            xl={!collapsed ? 19 : 21}
          >
            <Header expense={false} travel={true} />

            <CommonHeader
              toggle="list"
              id="1"
              totalImage={imageCount}
              storage={storageUsed}
              trigger={trigger}
              dataSort={dataSort}
              selectedIds={selectedIds}
              search={search}
            />

            <div>
              <Spin spinning={spinning}>
                <div class=" ml-2 mt-6 mr-28 rounded-lg  h-96 md:h-48 scrollable-container  scrollbar-thin   ">
                  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"></thead>
                    <tbody>
                      {data ? (
                        data.map((item, index) => {
                          return (
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                              <div className="flex ">
                                <img
                                  src={pic}
                                  alt="img"
                                  className="h-10 w-10"
                                />
                                <h1 className="text-start text-sm  ml-2 text-black font-normal">
                                  {item.originalFileName}
                                </h1>
                              </div>
                              <td class="px-6 py-4"></td>
                              <td class="px-6 py-4"></td>
                              <td class="px-6 py-4">{item.uploadedAt}</td>
                              <td class="px-6 py-4">
                                {/* <Checkbox onChange={onChange} className="mr-2"></Checkbox> */}
                                <input
                                  type="checkbox"
                                  id={`checkbox-${item.id}`}
                                  value={item.id}
                                  checked={selectedIds.includes(item.id)}
                                  onChange={() => handleCheckboxChange(item.id)}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <> </>
                      )}
                    </tbody>
                  </table>
                </div>
              </Spin>
            </div>
          </Col>
        </Row>
        <Footer />
      </div>
    </div>
  );
};

export default ListViewMain;
