import React, { useState } from 'react';
import { Table, Select, Input, Button } from 'antd';

const { Option } = Select;

const DateRowsComponent = ({ dates, countryOptions }) => {
  const [rowData, setRowData] = useState([]);

  const handleCountryChange = (value, dateKey) => {
    // Update rowData with the selected country
    const updatedRowData = [...rowData];
    // updatedRowData[dateKey].country = value;
    // setRowData(updatedRowData);
  };

  const handleExchangeRateChange = (value, dateKey) => {
    // Update rowData with the entered exchange rate
    const updatedRowData = [...rowData];
    updatedRowData[dateKey].exchangeRate = value;
    setRowData(updatedRowData);
  };

  const handleSaveData = () => {
    // Collect all filled data and perform necessary actions (e.g., submit to server)
    console.log('All filled data:', rowData);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text, record) => (
        <Select
          style={{ width: 200 }}
          onChange={(value) => handleCountryChange(value, record.key)}
        >
          {countryOptions.map((country) => (
            <Option key={country.key} value={country.key}>
              {country.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Exchange Rate',
      dataIndex: 'exchangeRate',
      key: 'exchangeRate',
      render: (_, record) => (
        <Input
          type="number"
          onChange={(e) => handleExchangeRateChange(e.target.value, record.key)}
        />
      ),
    },
  ];

  const data = dates.map((date, index) => ({
    key: index,
    date: date,
    country: null,
    exchangeRate: null,
  }));
  return (
   <>
    {dates.map((date)=>(
        <>
        <h1>{date}</h1>
        <Select
            //   onChange={(e) => setAccNumber(e)}
              // style={{ width: "12vw" }}
              placeholder="Select Account Number"
            >
              {countryOptions?.map((option) => (
                <Select.Option key={option.name} value={option.name}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
    </>
    )

    )}
   </>
  );
};

export default DateRowsComponent;
