import React from 'react'
import ReactApexChart from 'react-apexcharts'

function BarChartHorizontal({data,texts}) {
    const categories = data.map(item => item.category);
    const values = data.map(item => item.amountSum);


    const chartOptions = {
      chart: {
        id: 'basic-bar',
        toolbar: {
          show: false,
        },
      },
      title: {
        text: texts,
        align: 'centre'
      },
      grid: {
        show: false, // Hide grid lines
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
        }
      },
      xaxis: {
        categories: categories,
        labels: {
            show: false, // Hide labels on the X-axis
          },
      },
      yaxis: {
      show:true
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#000'], // Set the color to black
        },
      },
    };
  
    const chartSeries = [
      {
        name: 'Expense',
        data: values,
      },
  
    ];
  return (
    <div>
    <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={200} />
  </div>
  )
}

export default BarChartHorizontal