import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cashAdvance } from "../redux/actions";
import {
  cancelRequest,
  cashInAdvance,
  exchangeRate,
  getCashInAdvance,
  getCurrencyList,
} from "../services/ApiService";
import { useNavigate } from "react-router-dom";
import { CgLayoutGrid } from "react-icons/cg";
const { TextArea } = Input;
function CashAdvance({ triggerParentEffect }) {
  const [form] = Form.useForm();
  const requestid = useSelector((state) => state.requestedid);
  const requestName = useSelector((state) => state.travelHeader.requestName);
  const dispatch = useDispatch();

  const requestedid = useSelector((state) => state.requestedid);
  const [currencyList, setCurrencyList] = useState([]);
  const userType = sessionStorage.getItem("userType");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const isFirstRun = useRef(true);
  const requestPolicy = useSelector(
    (state) => state.travelHeader.requestPolicy
  );

  useEffect(() => {
    // if (isFirstRun.current) {
    //   isFirstRun.current = false;
    //   return;
    // }
    getCurrencyList().then((res) => {
      if (res.responseCode === 200) {
        setCurrencyList(res.data);
      }
    });
    if (requestedid) {
      getCashInAdvance(requestid, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          if (res.data) {
            form.setFieldsValue({

              reasonTravelAdvance: res.data?.reasonTravelAdvance,
              travelAdvance: res.data?.travelAdvance,
              exchangeRate: res.data?.exchangeRate,
              travelAdvanceOriginal: res.data?.travelAdvanceOriginal,
              currency: res.data?.currency,
            })
          
            // form.setFieldsValue({
            //   cashAdvance: res.data?.cashInAdvance,
            //   reason: res.data?.reasonCashInAdvance,
            // });
            let reduxobj = {
              reasonTravelAdvance: res.data?.reasonTravelAdvance,
              travelAdvance: res.data?.travelAdvance,
              exchangeRate: res.data?.exchangeRate,
              travelAdvanceOriginal: res.data?.travelAdvanceOriginal,
              currency: res.data?.currency,
            };
            dispatch(cashAdvance(reduxobj));
          }
        }
      });
    } else {
      message.info("Please Save Travel Overview");
    }
  }, []);

  const onFinish = (value) => {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        value[key] = value[key] !== null ? value[key] : "";
      }
    }
    value["travelAdvanceOriginal"] = parseFloat(value["travelAdvanceOriginal"]);

    let body = {
      ...value,
      requestId: requestedid,
    };
    if (Object.entries(value).every(([key, field]) => field !== "" && !isNaN(body.travelAdvanceOriginal
    ))) {
      cashInAdvance(body, requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          message.success("Saved");
          triggerParentEffect(body);
        } else {
          message.error(res.responseMessage);
        }
      });
      dispatch(cashAdvance(value));
    } else {
      message.info("Add full data for travel advance to save");
    }
  };
  const onCancel = () => {
    setOpen(true);
  };
  const onSubmit = () => {
    setOpen(false);
    let body = {
      requestId: requestid,
    };
    if (requestid) {
      cancelRequest(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Canceled the Request Successfully");

          if (userType == "1") {
            navigate("/dashboard-m");
          } else {
            navigate("/dashboard");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      if (userType == "1") {
        navigate("/dashboard-m");
      } else {
        navigate("/dashboard");
      }
      message.error("Data is not Saved Yet");
    }
  };
  const onValuesChange = (changedValues, allValues) => {
    // Get the name of the field that was changed
    const fieldName = Object.keys(changedValues)[0];
    // If the 'firstField' value changed, get the value of 'secondField'
    if (fieldName === "exchangeRate") {
      const travelAdvanceOriginal = form.getFieldValue("travelAdvanceOriginal");
      const exchangeRate = form.getFieldValue("exchangeRate");
      form.setFieldsValue({
        travelAdvance: exchangeRate * travelAdvanceOriginal,
      });
    }

    // If the 'secondField' value changed, get the value of 'firstField'
    if (fieldName === "travelAdvanceOriginal") {
      const travelAdvanceOriginal = form.getFieldValue("travelAdvanceOriginal");
      const exchangeRate = form.getFieldValue("exchangeRate");
      form.setFieldsValue({
        travelAdvance: exchangeRate * travelAdvanceOriginal,
      });
    }
    if (fieldName === "currency") {
      const currency = form.getFieldValue("currency");
      const selectedCountryObject = currencyList.find(
        (item) => item.currencyCode === currency
      );
      form.setFieldsValue({
        exchangeRate: selectedCountryObject.exchangeRate,
      });
      const travelAdvanceOriginal = form.getFieldValue("travelAdvanceOriginal");
      form.setFieldsValue({
        travelAdvance:
          travelAdvanceOriginal * selectedCountryObject.exchangeRate,
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        margin: "1.5rem 1.5rem 0 0",
        display: "flex",
        justifyContent: "flex-start",
        borderRadius: "5px",
        padding: "1rem 1rem 1rem 2rem",
        flexDirection: "column",
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        onValuesChange={onValuesChange}
      >
        <div
          style={{
            height: "53vh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5rem",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>Request ID :</span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {requestid ? requestid : ""}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>Request Name :</span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {requestName ? requestName : ""}
              </span>
            </div>
          </div>

          <Row>
            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Currency
                </label>
                <Form.Item
                  name="currency"
                  // rules={[
                  //   { required: true, message: "Please Select Currency" },
                  // ]}
                >
                  <Select
                    listItemHeight={10}
                    listHeight={200}
                    showSearch
                    className="w-56 md:w-42"
                    style={{ width: "90%" }}
                    // onChange={(value) =>
                    //   handleInputChange(index, "billCurrency", value)
                    // }
                    // onSearch={onSearch}
                    // filterOption={filterOption}

                    placeholder="Select Currency"
                    // className="w-56 md:w-42"
                    virtual={true}
                  >
                    {currencyList?.map((option) => (
                      <Select.Option
                        key={option.currencyCode}
                        value={option.currencyCode}
                      >
                        {option.currencyCode}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Exchange Rate
                </label>
                <Form.Item name="exchangeRate">
                  <input
                    // disabled={disabledExchange}
                    className="inputclass w-64 md:w-52"
                    // name="exchangeRate"
                    type="number"
                    placeholder="Enter Exchange Rate"
                  />
                  {/* <InputNumber  style={{width:"80%"}}/> */}
                </Form.Item>
              </div>
            </Col>
            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Travel Advance Amount
                </label>
                <Form.Item name="travelAdvanceOriginal">
                  <input
                    // disabled={disabledExchange}
                    className="inputclass w-64 md:w-52"
                    // name="travelAdvanceOriginal"
                    type="number"
                    placeholder="Enter Amount"
                  />
                </Form.Item>
              </div>
            </Col>
            <Col lg={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Local Currency Amount
                </label>
                <Form.Item name="travelAdvance">
                  <input
                    disabled
                    // disabled={disabledExchange}
                    className="inputclass w-64 md:w-52"
                    type="number"
                    placeholder="Enter Amount"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={22}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
                  Comment
                </label>
                <Form.Item
                  name="reasonTravelAdvance"
                  // rules={[
                  //   { required: true, message: "Please Enter the reason" },
                  // ]}
                >
                  <TextArea
                    style={{ resize: "none" }}
                    rows={4}
                    placeholder="Describe the reason for travel advance"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div
            style={{ display: "flex", flexDirection: "row", gap: "3rem" }}
          ></div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", gap: "1.5rem" }}
        >
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "#3052D0",
              border: "none",
              color: "white",
            }}
            htmlType="submit"
          >
            Save
          </Button>

          <Popconfirm
            title="Are you sure, want to Cancel the whole request"
            okText="Yes"
            cancelText="No"
            // description="Open Popconfirm with Promise"
            onConfirm={onSubmit}
            // onCancel={() => setOpen(false)}
          >
            <Button
              style={{
                width: "8.5rem",
                backgroundColor: "transparent",
                border: "1px solid red",
                color: "red",
              }}
              // onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </Popconfirm>
        </div>
      </Form>
      {/* <Modal
        open={open}
        title="Are you sure, want to Cancel the whole request"
        onCancel={() => setOpen()}
        footer={[
          <Button key="submit" type="primary" onClick={onSubmit}>
            Yes
          </Button>,
          <Button onClick={() => setOpen(false)}>No</Button>,
        ]}
      ></Modal> */}
    </div>
  );
}

export default CashAdvance;
