import CryptoJS from 'crypto-js';

const secretKey=process.env.REACT_APP_KEY

export const decryptObject = (encryptedObj) => {
    try {
        if (!encryptedObj || !secretKey) {
            throw new Error('Invalid encrypted object or secret key');
        }

        const bytes = CryptoJS.AES.decrypt(encryptedObj, secretKey);
        const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedString);
    } catch (error) {
        console.error('Decryption error:', error);
        return null;
    }
};